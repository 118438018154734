import './navbar.css';
import brand from './../../assets/Brand.webp';
import logo from './../../assets/Logo.webp';
import logoHalloween from './../../assets/LogoHalloween.webp';
import logoChristmas from './../../assets/LogoChristmas.webp';
import { Link } from 'react-router-dom';

function Navbar({Toggle}) {

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1; // El mes actual (enero = 1, diciembre = 12)
  const currentDay = currentDate.getDate(); // El día actual

  const isHalloween = (currentMonth === 10 && currentDay >= 26) || (currentMonth === 11 && currentDay === 1);
  const isChristmas = currentMonth === 12 && currentDay >= 8 && currentDay <= 25;

  const logoSrc = isHalloween ? logoHalloween : (isChristmas ? logoChristmas : logo);


  return (
    <nav className="navbar navbarstyle">
      <div className="d-flex">
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" onClick={Toggle} aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <Link className="navbar-brand" to='/'>
          <img loading='lazy' src={logoSrc} className="img-fluid navbar-logo" width={32} height={32} alt={"Guide Mu Online Logo"}/>
          <img loading='eager' src={brand} className="img-fluid" width={140} height={34} alt={"Guide Mu Online"}/>
        </Link>
      </div>
    </nav>
  )
}

export default Navbar