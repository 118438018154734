import React, { useState } from 'react';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import { BadgeCharacter } from '../../elements/badges/badges';
import { CardWingCore } from '../../elements/cards/cards';
import wingcoreData from "../../data/wingcore.json";
import './modal.css';


function ModalWings({nombre, equipado, redireccion, imagen, imagenFirebase, requirelevel, wingcore, infoItemNormal}) {

    const [show, setShow] = useState(false);

    function handleShow(breakpoint) {
        setShow(true);
    }

    var crearTabla = (infoTabla) => {
        return  <div className="table-responsive-sm text-center">
                    <table className="table table-bordered table-dark table-hover">
                        <thead>
                            <tr>
                                <th scope="col"><small className="text-uppercase">Level</small></th>
                                <th scope="col"><small className="text-uppercase">Defense</small></th>
                                {infoTabla[0].hasOwnProperty('WizardryDamage') ? <th scope="col"><small className="text-uppercase">Wizardry Damage</small></th>:<></>}
                                {infoTabla[0].hasOwnProperty('AttackPower') ? <th scope="col"><small className="text-uppercase">Attack Power</small></th>:<></>}
                                {infoTabla[0].hasOwnProperty('WizardryCurseDamage') ? <th scope="col"><small className="text-uppercase">Wizardry/Curse Damage</small></th>:<></>}
                                {infoTabla[0].hasOwnProperty('AttackPowerWizardryDamage') ? <th scope="col"><small className="text-uppercase">Attack Power/Wizardry Damage</small></th>:<></>}
                                {infoTabla[0].hasOwnProperty('AttackWizardryCurse') ? <th scope="col"><small className="text-uppercase">Attack/Wizardry/Curse</small></th>:<></>}
                                <th scope="col"><small className="text-uppercase">Increase (%) of Damage</small></th>
                                <th scope="col"><small className="text-uppercase">Absorb (%) of Damage</small></th>
                                {infoTabla[0].hasOwnProperty('CorrectionDamage') ? <th scope="col"><small className="text-uppercase">Correction Damage</small></th>:<></>}
                            </tr>
                        </thead>
                        <tbody>
                            {infoTabla?.map((info, index) => {return(
                                <tr key={index}>
                                    <td className="align-middle p-1"><small>{info.Level}</small></td>
                                    <td className="align-middle p-1"><small>{info.Defense}</small></td>
                                    {infoTabla[index].hasOwnProperty('WizardryDamage') ? <td className="align-middle p-1"><small>{info.WizardryDamage}</small></td>:<></>}
                                    {infoTabla[index].hasOwnProperty('AttackPower') ? <td className="align-middle p-1"><small>{info.AttackPower}</small></td>:<></>}
                                    {infoTabla[index].hasOwnProperty('WizardryCurseDamage') ? <td className="align-middle p-1"><small>{info.WizardryCurseDamage}</small></td>:<></>}
                                    {infoTabla[index].hasOwnProperty('AttackPowerWizardryDamage') ? <td className="align-middle p-1"><small>{info.AttackPowerWizardryDamage}</small></td>:<></>}
                                    {infoTabla[index].hasOwnProperty('AttackWizardryCurse') ? <td className="align-middle p-1"><small>{info.AttackWizardryCurse}</small></td>:<></>}
                                    <td className="align-middle p-1"><small>{info.IncreaseOfDamage}%</small></td>
                                    <td className="align-middle p-1"><small>{info.AbsorbOfDamage}%</small></td>
                                    {infoTabla[index].hasOwnProperty('CorrectionDamage') ? <td className="align-middle p-1"><small>{info.CorrectionDamage}</small></td>:<></>}
                                </tr>
                            )})}
                        </tbody>
                    </table>
                </div>
    }
    return (
        <>
            <button type="button" className="btn btn-more-info" onClick={() => handleShow()}>More Info</button>
            <Modal size="xl" show={show} onHide={() => setShow(false)} className="modal-general">
                <Modal.Header closeVariant='white' closeButton>
                    <Modal.Title>{nombre}</Modal.Title>
                </Modal.Header>
                <Modal.Body scrollable="true">
                    <div className="row">
                        <div className="col-md-6 col-lg-4 col-xl-3 col-xxl-3 mb-4">
                            <div className="card card-bg-sinhover h-100">
                                <div className="row">
                                    <div className="col-12 d-flex align-items-center justify-content-center">
                                        <img
                                            loading="lazy"
                                            src={imagenFirebase || require('../../assets/items/wings/'+imagen+'.webp')}
                                            className="img-fluid"
                                            width={200}
                                            height={132}
                                            alt={nombre+" Mu Online"}
                                            onError={(e) => {
                                                console.error("Error loading image, please contact admin to fix it:", e);
                                        }}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-8 col-xl-9 col-xxl-9 mb-4">
                            <div className="card card-bg-sinhover h-100">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <p className="descripcion-modal text-uppercase text-blanco">Can be Equipped By:</p>
                                        </div>
                                        <div className="col-12 text-center">
                                            {equipado.map((clase, index) => {return (
                                                <BadgeCharacter key={index} redireccion={'/characters/'+redireccion[index]} clase={clase} />
                                            )})}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {wingcore === "" ? <></> : (
                        <div className="row">
                            <div className="col-12">
                                <Tabs defaultActiveKey="wingcore" transition={true} className="mb-3 justify-content-center tabs-page">
                                    <Tab eventKey={"wingcore"} title={"Wing Core"} className="tab-page">
                                        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-4 justify-content-center">
                                            {wingcore?.map((item,index) => {
                                                const wcore = wingcoreData.find((m) => m.id === item);

                                                if (wcore) {
                                                    return (
                                                        <CardWingCore key={index} nombre={wcore.nombre} imagen={wcore.imagen} imagenFirebase={wcore.imagenFirebase} wearable={wcore.wearable} tipo={wcore.tipo} stats={wcore.stats}/>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    )}
                    
                    <div className="row">
                        <div className="col-12">
                            <Tabs defaultActiveKey="stats" transition={true} className="mb-3 justify-content-center tabs-page">
                                {infoItemNormal?.length === 0 ? <></> : <Tab eventKey={"stats"} title={"Stats"} className="tab-page"><div className="row"><div className="col-12">{crearTabla(infoItemNormal)}</div></div></Tab>}
                            </Tabs>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalWings