import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
// Bootstrap Icons
import "bootstrap-icons/font/bootstrap-icons.css";
import './index.css';
import App from './App';

/*
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);*/

const handleContextMenu = (e) => {
    e.preventDefault();
  };
  
  const Root = () => {
    useEffect(() => {
      document.addEventListener('contextmenu', handleContextMenu);
  
      return () => {
        document.removeEventListener('contextmenu', handleContextMenu);
      };
    }, []);
  
    return <App />;
  };
  
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(<Root />);
