import { Link } from "react-router-dom"
import './footer.css'
function Footer() {
    return (
        <footer className="footer justify-content-center align-content-center d-flex">
            <div className="row justify-content-center align-content-center d-flex m-0">
                <div className="col-12">
                    <Link to={"/contact"} className='texto-links'>Contact</Link>{ <i className="bi bi-dot texto-links"></i> }<Link to={"/legal/privacy-policy"} className='texto-links'>Privacy Policy</Link>{ <i className="bi bi-dot texto-links"></i> }<Link to={"/legal/cookie-policy"} className='texto-links'>Cookie Policy</Link>
                </div>
                <div className="col-12">
                    <div className="text-footer">
                        {new Date().getFullYear()} © Copyright: <Link to={"/"} className='texto-links'> Guidemuonline.com </Link> Unofficial - Fansite
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default Footer