import React, { useState } from 'react';
import { Container, Modal, Tab, Tabs } from 'react-bootstrap';
import { BadgeCharacter } from '../../elements/badges/badges';
import './modal.css';

function ModalItemSet({nombre, equipado, redireccion, imagen, imagenFirebase, infoItemNormal, infoItemExcellent, tipo}) {

    const [show, setShow] = useState(false);

    function handleShow(breakpoint) {
        setShow(true);
    }

    var crearTabla = (infoTabla) => {
        if (tipo === "helm" || tipo === "armor" || tipo === "pants" || tipo === "boots") {
            return  <div className="table-responsive-sm text-center">
                        <table className="table table-bordered table-dark table-hover">
                            <thead>
                                <tr>
                                    <th scope="col" colSpan={2}><small className="text-uppercase">Item Stats</small></th>
                                    <th scope="col" colSpan={5}><small className="text-uppercase">Required Stats</small></th>
                                </tr>
                                <tr>
                                    <th scope="col"><small className="text-uppercase">Level</small></th>
                                    <th scope="col"><small className="text-uppercase">Defense</small></th>
                                    <th scope="col"><small className="text-uppercase">Strength</small></th>
                                    <th scope="col"><small className="text-uppercase">Agility</small></th>
                                    <th scope="col"><small className="text-uppercase">Stamina</small></th>
                                    <th scope="col"><small className="text-uppercase">Energy</small></th>
                                    <th scope="col"><small className="text-uppercase">Command</small></th>
                                </tr>
                            </thead>
                            <tbody>
                                { infoTabla?.map((info, index) => {return(
                                    <tr key={index}>
                                        <td className="align-middle p-1"><small>{info.Level}</small></td>
                                        <td className="align-middle p-1"><small>{info.Defense}</small></td>
                                        <td className="align-middle p-1"><small>{info.Strength}</small></td>
                                        <td className="align-middle p-1"><small>{info.Agility}</small></td>
                                        <td className="align-middle p-1"><small>{info.Stamina}</small></td>
                                        <td className="align-middle p-1"><small>{info.Energy}</small></td>
                                        <td className="align-middle p-1"><small>{info.Command}</small></td>
                                    </tr>
                                )})}
                            </tbody>
                        </table>
                    </div>
        } else if (tipo === "gloves") {
            return  <div className="table-responsive-sm text-center">
                        <table className="table table-bordered table-dark table-hover">
                            <thead>
                                <tr>
                                    <th scope="col" colSpan={3}><small className="text-uppercase">Item Stats</small></th>
                                    <th scope="col" colSpan={5}><small className="text-uppercase">Required Stats</small></th>
                                </tr>
                                <tr>
                                    <th scope="col"><small className="text-uppercase">Level</small></th>
                                    <th scope="col"><small className="text-uppercase">Defense</small></th>
                                    <th scope="col"><small className="text-uppercase">Attack Speed</small></th>
                                    <th scope="col"><small className="text-uppercase">Strength</small></th>
                                    <th scope="col"><small className="text-uppercase">Agility</small></th>
                                    <th scope="col"><small className="text-uppercase">Stamina</small></th>
                                    <th scope="col"><small className="text-uppercase">Energy</small></th>
                                    <th scope="col"><small className="text-uppercase">Command</small></th>
                                </tr>
                            </thead>
                            <tbody>
                                { infoTabla?.map((info, index) => {return(
                                    <tr key={index}>
                                        <td className="align-middle p-1"><small>{info.Level}</small></td>
                                        <td className="align-middle p-1"><small>{info.Defense}</small></td>
                                        <td className="align-middle p-1"><small>{info.AttackSpeed}</small></td>
                                        <td className="align-middle p-1"><small>{info.Strength}</small></td>
                                        <td className="align-middle p-1"><small>{info.Agility}</small></td>
                                        <td className="align-middle p-1"><small>{info.Stamina}</small></td>
                                        <td className="align-middle p-1"><small>{info.Energy}</small></td>
                                        <td className="align-middle p-1"><small>{info.Command}</small></td>
                                    </tr>
                                )})}
                            </tbody>
                        </table>
                    </div>
        }
    }

    return (
        <>
            <button type="button" className="btn btn-more-info" onClick={() => handleShow()}>More Info</button>
            <Modal size="xl" show={show} onHide={() => setShow(false)} className="modal-general">
                <Modal.Header closeVariant='white' closeButton>
                    <Modal.Title>{nombre}</Modal.Title>
                </Modal.Header>
                <Modal.Body scrollable="true"><Container>
                    <div className="row">
                        <div className="col-md-6 col-lg-4 col-xl-3 col-xxl-3 mb-4">
                            <div className="card card-bg-sinhover h-100">
                                <div className="row">
                                    <div className="col-12 d-flex align-items-center justify-content-center">
                                        <img
                                            loading="lazy"
                                            src={imagenFirebase || require('../../assets/items/set-items/'+tipo+'/'+imagen+'.webp')}
                                            className="img-fluid"
                                            width={150}
                                            height={99}
                                            alt={nombre+" Mu Online"}
                                            onError={(e) => {
                                                console.error("Error loading image, please contact admin to fix it:", e);
                                        }}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-8 col-xl-9 col-xxl-9 mb-4">
                            <div className="card card-bg-sinhover h-100">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <p className="descripcion-modal text-uppercase text-blanco">Can be Equipped By:</p>
                                        </div>
                                        <div className="col-12 text-center">
                                            {equipado.map((clase, index) => {return (
                                                <BadgeCharacter key={index} redireccion={'/characters/'+redireccion[index]} clase={clase} />
                                            )})}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Tabs defaultActiveKey="normal" transition={true} className="mb-3 justify-content-center tabs-page">
                                {infoItemNormal?.length === 0 ? <></> : <Tab eventKey={"normal"} title={"Normal"} className="tab-page"><div className="row"><div className="col-12">{crearTabla(infoItemNormal)}</div></div></Tab>}
                                {infoItemExcellent?.length === 0 ? <></> : <Tab eventKey={"excellent"} title={"Excellent"} className="tab-page"><div className="row"><div className="col-12">{crearTabla(infoItemExcellent)}</div></div></Tab>}
                            </Tabs>
                        </div>
                    </div></Container>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalItemSet