import React, { useState, useEffect } from 'react';
import { OverlayTrigger, Popover, Tab, Tabs } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BadgeAbilityBook, BadgeAddedInSeason, BadgeCharacter, BadgeCommunityBuildData, BadgeMasteryTierSinFondo, BadgePrice, BadgeSeason, BadgeTier } from '../badges/badges';
import ModalItemSet from '../../components/modal/ModalItemSet';
import ModalWeapon from '../../components/modal/ModalWeapon';
import ModalWings from '../../components/modal/ModalWings';
import ModalEarrings from '../../components/modal/ModalEarrings';
import ModalItemSetLucky from '../../components/modal/ModalItemSetLucky';
import ModalItemSetEquipment from '../../components/modal/ModalItemSetEquipment';
import './cards.css';
import ModalItemSetMastery from '../../components/modal/ModalItemSetMastery';
import { SocialLink } from '../botones/Botones';
import ModalAbilityCards from '../../components/modal/ModalAbilityCards';
import ModalGuardian from '../../components/modal/ModalGuardian';
import ModalGuardianGuide from '../../components/modal/ModalGuardianGuide';

function CardMuHome() {
    return (
        <div className="col-12">
            <div className="card card-basico-sinhover text-center">
                <div className="card-body">
                    <div className="row d-flex align-items-center justify-content-center">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8 mt-2">
                            <p className="text-descripcion-mu-home">MU Online, produced by Webzen Inc is a full 3D MMORPG which is one of the leading online games developed in Korea.</p>
                            <p className="text-descripcion-mu-home">MU is a highly involved fantasy RPG based on the legendary Continent of MU.</p>
                            <p className="text-descripcion-mu-home">MU established a basic frame of various online games and other following games and regarded as a pioneer of 3D MMORPG.</p>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 mt-2 mb-2 text-center">
                            <Link to="https://muonline.webzen.com/" target="_blank" rel="noreferrer" className="link-char-home">
                                <button type="button" className="btn btn-official-website w-100">Go to Official Website</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function CardContacto(props) {
    return (
      <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-7 mb-4 card-items">
          <div className="card card-bg-sinhover">
              <div className="card-body">
                  <div className="row">
                    <div className="col-12">
                        <h2 className="card-title-contact">Feel free to contact me</h2>
                    </div>
                    <div className="col-12">
                        <form onSubmit={props.enviarEmail} className="form-contact">
                            <div className="mb-3">
                                <label htmlFor="subjectform" className="form-label">Subject:</label>
                                <input type="text" name="subject" placeholder="Subject" id="subjectform" className="form-control" value={props.inputSubjectForm} onChange={props.handleSubjectForm}/>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="emailform" className="form-label">Email:</label>
                                <input type="email" name="useremail" placeholder="your@email.com" id="emailform" className="form-control" value={props.inputEmailForm} onChange={props.handleEmailForm}/>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="messageform" className="form-label">Message:</label>
                                <textarea name="message" id="messageform" className="form-control" rows="5" value={props.inputMessageForm} onChange={props.handleMessageForm}/>
                            </div>
                            <div className="d-grid gap-2">
                                <input type="submit" value="Send" className="btn btn-contact"/>
                            </div>
                        </form>
                    </div>
                  </div>
            </div>
        </div>
    </div>
  )
}

function CardCharactersHome(props) {
    return (
        <Link to={"/characters/"+props.redirection} className="link-char-home">
            <div className="col mb-2">
                <div className="card card-pj-home text-center">
                    <div className="row">
                        <div className="col-12">
                            <img
                                loading="lazy"
                                src={props.imagenSmallFirebase || require('../../assets/charactersinformation/charsSmall/'+props.imagen+'.webp')}
                                className="img-fluid"
                                width={244}
                                height={244}
                                alt={props.nombre + " Mu Online"}
                                onError={(e) => {
                                    console.error("Error loading image, please contact admin to fix it:", e);
                            }}/>
                        </div>
                        <div className="col-12">
                            <h2 className="nombre-char-home">{props.nombre}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    )
}

function CardShortcuts(props) {
    return (
        <div className="col mb-4 card-items">
            <div className="card card-bg-sinhover h-100">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 text-center"><span className="icon-shortcut-key">{props.tecla}</span></div>
                        <div className="col-12 text-center mt-1"><p className="text-descripcion text-center">{props.descripcion}</p></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function CardMap(props) {
  return (
        <div className="card-items mb-4 mt-2">
            <div className="card card-bg-sinhover">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="card-title-map">{props.nombre}</h2>
                        </div>
                        {props.descripcion === "" ? (
                            <div className="col-12 p-4 d-flex justify-content-center">
                                <img
                                    loading="eager"
                                    src={props.imagenFirebase || require('../../assets/maps/'+props.imagen+'.webp')}
                                    className="img-fluid"
                                    width={350}
                                    height={250}
                                    alt={props.nombre + " Mu Online"}
                                    onError={(e) => {
                                        console.error("Error loading image, please contact admin to fix it:", e);
                                }}/>
                            </div>
                        ):(
                        <>
                            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 p-4 d-flex justify-content-center m-auto">
                                <img
                                    loading="eager"
                                    src={props.imagenFirebase || require('../../assets/maps/'+props.imagen+'.webp')}
                                    className="img-fluid"
                                    width={350}
                                    height={250}
                                    alt={props.nombre + " Mu Online"}
                                    onError={(e) => {
                                        console.error("Error loading image, please contact admin to fix it:", e);
                                }}/>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 p-4">
                                <p className="card-mapa-descripcion">{props.descripcion}</p>
                            </div>
                        </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

function CardSubMap(props) {
    return (
        <div className="card-items mb-4 mt-2">
            <div className="card card-bg-sinhover">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="card-title-map">{props.nombre}</h2>
                        </div>
                        <div className="col-12 p-4 d-flex justify-content-center">
                            <img
                                loading="lazy"
                                src={props.imagenZonaFirebase || require('../../assets/maps/'+props.imagen+'.webp')}
                                className="img-fluid"
                                width={350}
                                height={250}
                                alt={props.nombre + " Mu Online"}
                                onError={(e) => {
                                    console.error("Error loading image, please contact admin to fix it:", e);
                            }}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function CardMapEx(props) {

    const [selectedSpotId, setSelectedSpotId] = useState(null);
    const [spots, setSpots] = useState(props.spots);
        
    const handleSpotClick = (spotId) => {
        // Aquí obtienes la información del spot según el spotId
        const spotInfo = spots.find((spot) => spot.id === spotId);

        setSelectedSpotId(spotId === selectedSpotId ? null : spotId);
        // Llama a la función onSpotClick para actualizar la información en el componente padre
        props.onSpotClick(spotInfo);
      };

    return (
        <div className="card-items mb-4 mt-2">
            <div className="card card-bg-sinhover">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center m-auto">
                            <div style={{ position: 'relative' }}>
                            <img
                                loading="lazy"
                                /*src={require('../../assets/maps/mapex/' + props.imagenSpot + '.webp')}*/
                                src={props.imagenSpotUrl || require('../../assets/maps/mapex/' + props.imagenSpot + '.webp')}
                                className="img-fluid"
                                width={512}
                                height={512}
                                alt={props.nombre + " Mu Online"}
                                onError={(e) => {
                                    console.error("Error loading image, please contact admin to fix it:", e);
                                }}/>
                                {spots?.map((spot, indexSpot) => (
                                    <i 
                                        className={`bi  
                                        ${spot.cantidadmobs === "3" ? 'bi-3-square-fill' : ''}
                                        ${spot.cantidadmobs === "4" ? 'bi-4-square-fill' : ''}
                                        ${spot.cantidadmobs === "5" ? 'bi-5-square-fill' : ''}
                                        ${spot.cantidadmobs === "6" ? 'bi-6-square-fill' : ''}
                                        ${spot.cantidadmobs === "7" ? 'bi-7-square-fill' : ''}
                                        ${spot.cantidadmobs === "8" ? 'bi-8-square-fill' : ''}
                                        ${spot.cantidadmobs === "9" ? 'bi-9-square-fill' : ''}
                                        ${parseInt(spot.cantidadmobs) >= 10 ? 'bi-star-fill' : ''}
                                        ${spot.invertircolor ? 'icon-spot-invertido' : 'icon-spot'} 
                                        ${selectedSpotId === spot.id ? 'active' : ''}`}
                                        key={indexSpot} 
                                        style={{top: spot.top, left: spot.left,}} 
                                        onClick={() => handleSpotClick(spot.id)}>
                                    </i>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function CardMonster(props) {
    return (
        <div className="col mb-4 card-items">
            <div className="card card-bg-sinhover h-100">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="card-monster-title">{props.nombre}</h2>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-5 text-center">
                            <div className="row">
                                <div className="col-12">
                                    <img
                                        loading="lazy"
                                        /*src={require('../../assets/maps/monsters/'+props.imagen+'.webp')}*/
                                        src={props.imagenMonsterFirebase || require('../../assets/maps/monsters/' + props.imagen + '.webp')}
                                        className="img-fluid"
                                        width={145}
                                        height={96}
                                        alt={props.nombre+" Mu Online"}
                                    />
                                </div>
                                <div className="col-12">
                                    <p className="card-monster-level text-warning">Level {props.level}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-7 m-auto">
                            <div className="row">
                                <div className="col-12">
                                    <p className="text-title-stats-monster">Health: <span className="text-stats-monster">{props.hp}</span></p>
                                </div>
                                <div className="col-12">
                                    <p className="text-title-stats-monster">Defense: <span className="text-stats-monster">{props.defense}</span></p>
                                </div>
                                <div className="col-12">
                                    <p className="text-title-stats-monster">Min/Max Attack: <span className="text-stats-monster">{props.minattack} ~ {props.maxattack}</span></p>
                                </div>
                                <div className="col-12">
                                    <p className="text-title-stats-monster">Defense Success Rate: <span className="text-stats-monster">{props.defensesuccessrate}</span></p>
                                </div>
                                <div className="col-12">
                                    <p className="text-title-stats-monster">Elemental DMG: <span className="text-stats-monster">{props.minattackpower} ~ {props.elementaldmg}</span></p>
                                </div>
                                <div className="col-12">
                                    <p className="text-title-stats-monster">Elemental DEF: <span className="text-stats-monster">{props.elementaldef}</span></p>
                                </div>
                            </div>
                        </div>
                        {props.descripcion && (
                            <div className="col-12">
                                <p className="text-stats-monster">{props.descripcion}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

function CardMonsterSpots(props) {
    return (
        <div className="col mb-4 card-items">
            <div className="card card-bg-sinhover">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="card-monster-title">{props.nombre}</h2>
                        </div>
                        <div className="col-12 text-center">
                            <img
                                loading="lazy"
                                src={props.imagenMonsterFirebase}
                                className="img-fluid"
                                width={120}
                                height={79}
                                alt={props.nombre+" Mu Online"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function CardSetItem(props) {
    return (
        <div className="col mb-4 card-items">
            <div className="card card-bg">
                <div className="card-body">
                    <div className="row h-100 align-middle">
                        <div className="col-12">
                            <h2 className="card-title">{props.nombre}</h2>
                        </div>
                        <div className="col-6 m-0 p-0">
                            <div className="d-flex justify-content-center">
                                <img
                                    loading="lazy"
                                    src={props.imagenFirebase || require('../../assets/items/set-items/'+props.tipo+'/'+props.imagen+'.webp')}
                                    className="img-fluid"
                                    width={100}
                                    height={66}
                                    alt={props.nombre+" Mu Online"}
                                    onError={(e) => {
                                        console.error("Error loading image, please contact admin to fix it:", e);
                                }}/>
                            </div>
                        </div>
                        <div className="col-6 m-0 p-0">
                            <div className="d-flex h-100">
                                <div className="row justify-content-center align-self-center">
                                    <div className="col-12">
                                        <span className="color-normal m-0 p-0 text-stats">NORMAL</span><span className="text-stats-items m-0 p-0 text-stats">{' '+props.defnormal}</span>
                                    </div>
                                    <div className="col-12">
                                        <span className="color-excellent m-0 p-0 text-stats">EXCELLENT</span><span className="text-stats-items m-0 p-0 text-stats">{' '+props.defexcellent}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <ModalItemSet nombre={props.nombre} equipado={props.equipado} redireccion={props.redireccion} imagen={props.imagen} imagenFirebase={props.imagenFirebase} infoItemNormal={props.infoItemNormal} infoItemExcellent={props.infoItemExcellent} tipo={props.tipo}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

function CardWeapons(props) {
    return (
        <div className="col mb-4 card-items">
            <div className="card card-bg-sinhover h-100">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="card-title">{props.nombre}</h2>
                        </div>
                        <div className="col-12">
                            <div className="d-flex justify-content-center">
                                <img
                                    loading="lazy"
                                    src={props.imagenFirebase || require('../../assets/items/weapons/'+props.tipo+'/'+props.imagen+'.webp')}
                                    className="img-fluid"
                                    width={180}
                                    height={119}
                                    alt={props.nombre+" Mu Online"}
                                    onError={(e) => {
                                        console.error("Error loading image, please contact admin to fix it:", e);
                                }}/>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="d-flex justify-content-center">
                                                <span className="color-normal text-stats">NORMAL</span>
                                            </div>
                                        </div>
                                        <div className="col-12 justify-content-center">
                                            <div className="d-flex justify-content-center">
                                                {
                                                    props.tipo === "shield" ? (
                                                    <span className="text-stats">{props.defnormal}</span>
                                                    ):(
                                                    <span className="text-stats">{props.dmgnormalmin} - {props.dmgnormalmax}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="d-flex justify-content-center">
                                                <span className="color-excellent text-stats">EXCELLENT</span>
                                            </div>
                                        </div>
                                        <div className="col-12 justify-content-center">
                                            <div className="d-flex justify-content-center">
                                                {
                                                    props.tipo === "shield" ? (
                                                    <span className="text-stats">{props.defexcellent}</span>
                                                    ):(
                                                    <span className="text-stats">{props.dmgexcellentmin} - {props.dmgexcellentmax}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <ModalWeapon nombre={props.nombre} equipado={props.equipado} redireccion={props.redireccion} imagen={props.imagen} imagenFirebase={props.imagenFirebase} infoItemNormal={props.infoItemNormal} infoItemExcellent={props.infoItemExcellent} tipo={props.tipo} />
                    </div>
                </div>
            </div>
        </div>
    )
}

function CardWings(props) {
    return (
        <div className="col mb-4 card-items">
            <div className="card card-bg-sinhover h-100">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="card-title">{props.nombre}</h2>
                        </div>
                        <div className="col-12">
                            <div className="d-flex justify-content-center">
                                <img
                                    loading="lazy"
                                    src={props.imagenFirebase || require('../../assets/items/'+props.tipo+'/'+props.imagen+'.webp')}
                                    className="img-fluid"
                                    width={200}
                                    height={132}
                                    alt={props.nombre+" Mu Online"}
                                    onError={(e) => {
                                        console.error("Error loading image, please contact admin to fix it:", e);
                                }}/>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="d-flex justify-content-center">
                                <p className="text-required-level"><span className="text-warning">Required Level:</span> {props.requirelevel}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <ModalWings nombre={props.nombre} equipado={props.equipado} redireccion={props.redireccion} imagen={props.imagen} imagenFirebase={props.imagenFirebase} requirelevel={props.requirelevel} infoItemNormal={props.infoItemNormal} wingcore={props.wingcore}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

function CardRingPendant(props) {
    return (
        <div className="col mb-4 card-items">
            <div className="card card-bg-sinhover h-100">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="card-title">{props.nombre}</h2>
                        </div>
                        <div className="col-12">
                            <div className="d-flex justify-content-center">
                                <img
                                    loading="lazy"
                                    src={props.imagenFirebase || require('../../assets/items/ringpendant/'+props.tipo+'/'+props.imagen+'.webp')}
                                    className="img-fluid"
                                    width={70}
                                    height={70}
                                    alt={props.nombre+" Mu Online"}
                                    onError={(e) => {
                                        console.error("Error loading image, please contact admin to fix it:", e);
                                }}/>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="d-flex justify-content-center">
                                <p className="text-required-level"><span className="text-warning">Required Level:</span> {props.reqLevel}</p>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                {
                                    props.info.map((info, index) => {return (
                                        <div key={index} className="col-12"><p className="text-descripcion-opcion">{info}</p></div>
                                    )})
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function CardPentagram(props) {
    return (
        <div className="col mb-4 card-items">
            <div className="card card-bg-sinhover h-100">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="card-title">{props.nombre}</h2>
                        </div>
                        <div className="col-12">
                            <div className="d-flex justify-content-center">
                                <img
                                    loading="lazy"
                                    src={props.imagenFirebase || require('../../assets/items/'+props.tipo+'/'+props.imagen+'.webp')}
                                    className="img-fluid"
                                    width={170}
                                    height={112}
                                    alt={props.nombre+" Mu Online"}
                                    onError={(e) => {
                                        console.error("Error loading image, please contact admin to fix it:", e);
                                }}/>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="d-flex justify-content-center">
                                <p className="text-required-level"><span className="text-warning">Required Level:</span> {props.requirelevel}</p>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-12 p-0">
                                            <div className="d-flex justify-content-center">
                                                <span className="color-normal text-stats-elemental">ELEMENTAL DMG</span>
                                            </div>
                                        </div>
                                        <div className="col-12 justify-content-center">
                                            <div className="d-flex justify-content-center">
                                                <span className="text-stats-elemental">{props.elementaldamage}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-12 p-0">
                                            <div className="d-flex justify-content-center">
                                                <span className="color-excellent text-stats-elemental">ELEMENTAL DEF</span>
                                            </div>
                                        </div>
                                        <div className="col-12 justify-content-center">
                                            <div className="d-flex justify-content-center">
                                                <span className="text-stats-elemental">{props.elementaldefense}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {props.options.map((opt, index) => {return (
                            <React.Fragment key={index}>
                                <div className="col-12 m-0 p-0"><hr className='p-0 mt-2 mb-2'/></div>
                                <div className="col-12 text-warning text-titulo-option">Option</div>
                                <div className="col-12 text-option"><small>{opt.option}</small></div>
                                <div className="col-12 text-warning text-titulo-option mt-2">Activation Requirement</div>
                                {opt.activation.map((requeriment, index) => {return (
                                    <div className="col-12 text-option"key={index}><small>{requeriment}</small></div>
                                )}
                                )}
                                
                            </React.Fragment>
                        )})}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-center">
                        <BadgeTier tier={props.tier}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

function CardErrtels(props) {
    return (
        <div className="col mb-4 card-items">
            <div className="card card-bg-sinhover h-100">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="card-title">{props.nombre}</h2>
                        </div>
                        <div className="col-12 mt-2">
                            <div className="d-flex justify-content-center">
                                <div className="row d-flex justify-content-center align-items-center m-auto">
                                    <div className="col col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                                        <img
                                            loading="lazy"
                                            src={props.imagenFirebaseUno || require('../../assets/guides/elementalsystem/'+props.imagenUno+'.webp')}
                                            className="img-fluid"
                                            width={60}
                                            height={60}
                                            alt={props.nombre+" Mu Online"}
                                            onError={(e) => {
                                                console.error("Error loading image, please contact admin to fix it:", e);
                                        }}/>
                                    </div>
                                    <div className="col col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                                        <img
                                            loading="lazy"
                                            src={props.imagenFirebaseDos || require('../../assets/guides/elementalsystem/'+props.imagenDos+'.webp')}
                                            className="img-fluid"
                                            width={60}
                                            height={60}
                                            alt={props.nombre+" Mu Online"}
                                            onError={(e) => {
                                                console.error("Error loading image, please contact admin to fix it:", e);
                                        }}/>
                                    </div>
                                    <div className="col col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                                        <img
                                            loading="lazy"
                                            src={props.imagenFirebaseTres || require('../../assets/guides/elementalsystem/'+props.imagenTres+'.webp')}
                                            className="img-fluid"
                                            width={60}
                                            height={60}
                                            alt={props.nombre+" Mu Online"}
                                            onError={(e) => {
                                                console.error("Error loading image, please contact admin to fix it:", e);
                                        }}/>
                                    </div>
                                    <div className="col col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                                        <img
                                            loading="lazy"
                                            src={props.imagenFirebaseCuatro || require('../../assets/guides/elementalsystem/'+props.imagenCuatro+'.webp')}
                                            className="img-fluid"
                                            width={60}
                                            height={60}
                                            alt={props.nombre+" Mu Online"}
                                            onError={(e) => {
                                                console.error("Error loading image, please contact admin to fix it:", e);
                                        }}/>
                                    </div>
                                    <div className="col col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                                        <img
                                            loading="lazy"
                                            src={props.imagenFirebaseCinco || require('../../assets/guides/elementalsystem/'+props.imagenCinco+'.webp')}
                                            className="img-fluid"
                                            width={60}
                                            height={60}
                                            alt={props.nombre+" Mu Online"}
                                            onError={(e) => {
                                                console.error("Error loading image, please contact admin to fix it:", e);
                                        }}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 m-0 p-0"><hr className='p-0 mt-2 mb-2'/></div>
                        <div className="col-12 text-warning text-titulo-option mb-2">Options</div>
                        <div className="col-12 text-danger text-titulo-option mt-2">Rank 1</div>
                        {props.rankUno?.map((opt, index) => {return (<React.Fragment key={index}><div className="col-12 text-option"><small>{opt}</small></div></React.Fragment>)})}
                        <div className="col-12 text-danger text-titulo-option mt-2">Rank 2</div>
                        {props.rankDos?.map((opt, index) => {return (<React.Fragment key={index}><div className="col-12 text-option"><small>{opt}</small></div></React.Fragment>)})}
                        <div className="col-12 text-danger text-titulo-option mt-2">Rank 3</div>
                        {props.rankTres?.map((opt, index) => {return (<React.Fragment key={index}><div className="col-12 text-option"><small>{opt}</small></div></React.Fragment>)})}
                        <div className="col-12 text-danger text-titulo-option mt-2">Rank 4</div>
                        {props.rankCuatro?.map((opt, index) => {return (<React.Fragment key={index}><div className="col-12 text-option"><small>{opt}</small></div></React.Fragment>)})}
                        <div className="col-12 text-danger text-titulo-option mt-2">Rank 5</div>
                        {props.rankCinco?.map((opt, index) => {return (<React.Fragment key={index}><div className="col-12 text-option"><small>{opt}</small></div></React.Fragment>)})}
                    </div>
                </div>
            </div>
        </div>
    )
}

function CardJewels(props) {
    return (
        <div className="col mb-4 card-items">
            <div className="card card-bg-sinhover h-100">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="card-title">{props.nombre}</h2>
                        </div>
                        <div className="col-12">
                            <div className="d-flex justify-content-center">
                                <img
                                    loading="lazy"
                                    src={props.imagenFirebase || require('../../assets/items/'+props.tipo+'/'+props.imagen+'.webp')}
                                    className="img-fluid"
                                    width={70}
                                    height={70}
                                    alt={props.nombre+" Mu Online"}
                                    onError={(e) => {
                                        console.error("Error loading image, please contact admin to fix it:", e);
                                }}/>
                            </div>
                        </div>
                        <div className="col-12">
                            <p className="text-descripcion">{props.descripcion}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function CardMuun(props) {
    return (
        <div className="col mb-4 card-items">
            <div className="card card-bg-sinhover h-100">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="card-title">{props.nombre}</h2>
                        </div>
                        <div className="col-12">
                        <Tabs defaultActiveKey="normal" transition={true} className="tabs-muun mb-2">
                            <Tab eventKey="normal" title="Normal" className="text-center tab-muun">
                                <img
                                    loading="lazy"
                                    src={props.imagenFirebase || require('../../assets/items/'+props.tipo+'/'+props.imagen+'.webp')}
                                    className="img-fluid"
                                    width={150}
                                    height={98}
                                    alt={props.nombre+" Mu Online"}
                                    onError={(e) => {
                                        console.error("Error loading image, please contact admin to fix it:", e);
                                }}/>
                            </Tab>
                            {
                                props.imagenUp !== "" ? (
                                    <Tab eventKey="evolved" title="Evolved" className="text-center tab-muun">
                                        <img
                                            loading="lazy"
                                            src={props.imagenUpFirebase || require('../../assets/items/'+props.tipo+'/'+props.imagenUp+'.webp')}
                                            className="img-fluid"
                                            width={150}
                                            height={98}
                                            alt={props.nombre+" Mu Online"}
                                            onError={(e) => {
                                                console.error("Error loading image, please contact admin to fix it:", e);
                                        }}/>
                                    </Tab>
                                ):(<></>)
                            }
                        </Tabs>
                        </div>
                        <div className="col-12">
                            <div className="text-center mb-2">
                                {props.tier === 1 ? (<i className="bi bi-star-fill icon-star-muun"></i>):(
                                    props.tier === 2 ? (<><i className="bi bi-star-fill icon-star-muun"></i><i className="bi bi-star-fill icon-star-muun"></i></>):(
                                        props.tier === 3 ? (<><i className="bi bi-star-fill icon-star-muun"></i><i className="bi bi-star-fill icon-star-muun"></i><i className="bi bi-star-fill icon-star-muun"></i></>):(
                                            props.tier === 4 ? (<><i className="bi bi-star-fill icon-star-muun"></i><i className="bi bi-star-fill icon-star-muun"></i><i className="bi bi-star-fill icon-star-muun"></i><i className="bi bi-star-fill icon-star-muun"></i></>):(<></>)
                                        )
                                    )
                                )}
                            </div>
                        </div>
                        <div className="col-12">
                            <p className="text-descripcion-ability-muun">{props.ability === "" ? ("Coming Soon"):(props.ability)}</p>
                        </div>
                        <div className="col-12">
                            <p className="text-specialpower-muun text-warning">{props.specialPower}</p>
                        </div>
                        <div className="col-12">
                            <p className="text-condition-specialpower-muun text-info">{props.conditionSpecialPower}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function CardArtifacts(props) {
    return (
        props.items?.map((it,index) => {return (
        <div className="col mb-4 card-items" key={index}>
            <div className="card card-bg-sinhover h-100">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="card-title">{it.nombre}</h2>
                        </div>
                        <div className="col-12">
                            <div className="d-flex justify-content-center">
                                <img
                                    loading="lazy"
                                    src={it.imagenFirebase || require('../../assets/items/artifacts/'+it.imagen+'.webp')}
                                    className="img-fluid"
                                    width={100}
                                    height={100}
                                    alt={it.nombre+" Mu Online"}
                                    onError={(e) => {
                                        console.error("Error loading image, please contact admin to fix it:", e);
                                }}/>
                            </div>
                        </div>
                        <div className="col-12 mt-2">
                            <Tabs defaultActiveKey="Min" transition={true} className="tabs-muun mb-2">
                                <Tab eventKey="Min" title="Min Value" className="text-center tab-muun p-0">
                                    <div className="row justify-content-center">
                                        {
                                            it.itemOptionsMin.map((optionsMin, index) => {return (
                                                <div className="col-12 text-option text-center" key={index}><small>{optionsMin.opcion}</small></div>
                                            )})
                                        }
                                    </div>
                                </Tab>
                                <Tab eventKey="max" title="Max Value" className="text-center tab-muun p-0">
                                    <div className="row justify-content-center">
                                        {
                                            it.itemOptionsMax.map((optionsMax, index) => {return (
                                                <div className="col-12 text-option text-center" key={index}><small>{optionsMax.opcion}</small></div>
                                            )})
                                        }
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )})
    )
}

function CardFullArtifact(props) {
    return (
        <div className="col mb-4 card-items">
            <div className="card card-bg-sinhover h-100">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="card-title">{props.nombreFull}</h2>
                        </div>
                        <div className="col-12">
                            <div className="d-flex justify-content-center">
                                <img
                                    loading="lazy"
                                    src={props.imagenFullFirebase || require('../../assets/items/artifacts/'+props.imagenFull+'.webp')}
                                    className="img-fluid"
                                    width={148}
                                    height={168}
                                    alt={props.nombreFull+" Mu Online"}
                                    onError={(e) => {
                                        console.error("Error loading image, please contact admin to fix it:", e);
                                }}/>
                            </div>
                        </div>
                        <div className="col-12 text-warning text-titulo-option mb-2">Full Set Option</div>
                        <div className="col-12 text-option"><small>{props.fullSetOption}</small></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function CardEarrings(props) {
    return (
        <div className="col mb-4 card-items">
            <div className="card card-bg-sinhover h-100">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="card-title">{props.nombre}</h2>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <BadgeMasteryTierSinFondo masteryTier={props.tipoMastery}/>
                                </div>
                                <div className="col-12 text-center m-auto">
                                    <img
                                        loading="lazy"
                                        src={props.imagenFirebase || require('../../assets/items/earrings/'+props.imagen)}
                                        className="img-fluid"
                                        width={150}
                                        height={99}
                                        alt={props.nombre+" Mu Online"}
                                        onError={(e) => {
                                            console.error("Error loading image, please contact admin to fix it:", e);
                                    }}/>
                                </div>
                                <div className="col-12 text-center m-auto">
                                    <p className="text-option m-0"><span className="text-warning">Required Level:</span> {props.reqLevel}</p>
                                </div>
                                <div className="col-12 text-center m-auto">
                                    <p className="text-option m-0"><span className="color-excellent">Elemental defense:</span> {props.elementalDef}</p>
                                </div>
                                <div className="col-12 text-center m-auto">
                                    <p className="text-option m-0"><span className="text-info">Attack / Wizardry / Curse:</span> {props.attackWizardryCurseMin} ~ {props.attackWizardryCurseMax}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6">
                            <div className="row">
                                <div className="col-12 text-warning text-titulo-option mb-2 mt-2">Options</div>
                                {
                                    props.options.map((option, index) => {return (
                                        <div key={index} className="col-12">
                                            <p className="text-option"><span>{option}</span></p>
                                        </div>
                                    )})
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <ModalEarrings nombre={props.nombre} imagen={props.imagen} imagenFirebase={props.imagenFirebase} options={props.options} setOptions={props.setOptions} tipoMastery={props.tipoMastery} reqLevel={props.reqLevel} infoStats={props.infoStats} />
                    </div>
                </div>
            </div>
        </div>
    )
}

function CardSets(props) {

    var itemSet = (parteId, index, itemsJson, separador) => {
        if (parteId !== "") {
          const item = itemsJson.find((i) => i.id === parteId);
      
          if (item) {
            return (
              <React.Fragment key={index}>
                <ModalItemSetEquipment
                    nombre={item.nombre}
                    imagen={item.imagen}
                    equipado={item.equipado}
                    redireccion={item.redireccion}
                    defnormal={item.defnormal}
                    defexcellent={item.defexcellent}
                    infoItemNormal={item.infoItemNormal}
                    infoItemExcellent={item.infoItemExcellent}
                    imagenFirebase={item.imagenFirebase}
                    addedinseason={item.addedinseason}
                    tipo={item.tipo}/>
                {separador && (<div className="col-12"><hr className="separador-item" /></div>)}
              </React.Fragment>
            );
          }
        }

        // Return null outside of the if block
        return null;
      };

    return (
        <div className="col mb-4 card-items">
            <div className="card card-bg-sinhover h-100">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="card-title">{props.nombre}</h2>
                        </div>
                        {props.infoset.map((parteId, index) => {return (
                            <React.Fragment key={index}>
                                {index === 0 && parteId !== "" ? (itemSet(parteId, index, props.helm, true)) : null}

                                {index === 1 && parteId !== "" ? (itemSet(parteId, index, props.armor, true)) : null}

                                {index === 2 && parteId !== "" ? (itemSet(parteId, index, props.gloves, true)) : null}

                                {index === 3 && parteId !== "" ? (itemSet(parteId, index, props.pants, true)) : null}

                                {index === 4 && parteId !== "" ? (itemSet(parteId, index, props.boots, false)) : null}            
                            </React.Fragment>
                        )})}
                    </div>
                </div>
                {props.addedinseason === "" ? (<></>) : (
                <div className="row">
                    <div className="col-12 text-center">
                    <BadgeAddedInSeason addedinseason={props.addedinseason} />
                    </div>
                </div>
                )}
            </div>
        </div>
    );
}

function CardMasterySets(props) {
    return (
        <div className="col mb-4 card-items">
            <div className="card card-bg-sinhover h-100">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="card-title">{props.nombre}</h2>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-5 col-xxl-6">
                            <div className="row">
                                <div className="col-12 m-auto">
                                    <div className="row">
                                        <div className="row">
                                        {
                                            props.infoset.map((parte, index) => {return (
                                            <React.Fragment key={index}>
                                                <>
                                                    {index === 0 ? (null):(<div className="col-12"><hr className="separador-item"/></div>)}
                                                    <ModalItemSetMastery nombre={parte.nombre} defense={parte.defense} infoStats={parte.infoStats} imagen={parte.imagen} imagenFirebase={parte.imagenFirebase} tipo={parte.tipo} equipado={props.equipado} redireccion={props.redireccion} equipadoevo={props.equipadoevo} dosSetsOpciones={props.dosSetsOpciones} tresSetsOpciones={props.tresSetsOpciones} cuatroSetsOpciones={props.cuatroSetsOpciones} addedinseason={props.addedinseason}/>
                                                </>
                                            </React.Fragment>
                                            )})
                                        }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 text-center m-auto">
                                    <p className="text-option m-0"><span className="text-warning">Required Level:</span> {props.reqlevel}</p>
                                </div>
                                <div className="col-12 text-center m-auto mt-1">
                                    <BadgeCharacter clase={props.equipadoevo} redireccion={'/characters/'+props.redireccion}></BadgeCharacter>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-7 col-xxl-6">
                            <div className="row">
                                <div className="col-12 text-warning text-titulo-option mb-2 mt-2">Options Info</div>
                                <div className="col-12 text-center m-auto">
                                    <p className="text-danger text-titulo-option m-2">2 Set Effect</p>
                                </div>
                                {
                                    props.dosSetsOpciones.map((dosOption, index) => {return (
                                        <div key={index} className="col-12"><p className="text-option m-0"><small>{dosOption.opcion}</small></p></div>
                                    )})
                                }
                                <div className="col-12 text-center m-auto">
                                    <p className="text-danger text-titulo-option m-2">3 Set Effect</p>
                                </div>
                                {
                                    props.tresSetsOpciones.map((tresOption, index) => {return (
                                        <div key={index} className="col-12"><p className="text-option m-0"><small>{tresOption.opcion}</small></p></div>
                                    )})
                                }  
                                <div className="col-12 text-center m-auto">
                                    <p className="text-danger text-titulo-option m-2">4 Set Effect</p>
                                </div>
                                {
                                    props.cuatroSetsOpciones.map((cuatroOption, index) => {return (
                                        <div key={index} className="col-12"><p className="text-option m-0"><small>{cuatroOption.opcion}</small></p></div>
                                    )})
                                }  
                            </div>
                        </div>
                    </div>
                </div>
                {
                    props.addedinseason && (
                        <div className="row">
                            <div className="col-12 text-center">
                                <BadgeAddedInSeason addedinseason={props.addedinseason}/>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

function CardAncientSets(props) {
    return (
        <div className="col mb-4 card-items">
            <div className="card card-bg-sinhover">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="card-title">{props.nombre}</h2>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-6">
                            <div className="row">
                                <div className="col-12 m-auto">
                                    <div className="row">
                                    {
                                        props.infoset.map((parte, index) => {return (
                                        <React.Fragment key={index}>
                                            {/** Si es parte de set */
                                                parte.tipo === "helm" || parte.tipo === "armor" || parte.tipo === "gloves" || parte.tipo === "pants" || parte.tipo === "boots" ? 
                                                (
                                                    <>
                                                        {index === 0 ? (null):(<div className="col-12"><hr className="separador-item"/></div>)}
                                                        <div className="col-6 text-center m-auto p-0">
                                                            <img
                                                                loading="lazy"
                                                                src={parte.imagenFirebase || require('../../assets/items/set-items/'+parte.tipo+'/'+parte.imagen+'.webp')}
                                                                className="img-fluid"
                                                                width={100}
                                                                height={66}
                                                                alt={parte.nombre+" Mu Online"}
                                                                onError={(e) => {
                                                                    console.error("Error loading image, please contact admin to fix it:", e);
                                                            }}/>
                                                        </div>
                                                        <div className="col-6 p-0">
                                                            <div className="d-flex h-100">
                                                                <div className="row justify-content-center align-self-center">
                                                                    <div className="col-12 p-0"><small className="text-option-sets">{parte.tipo}</small></div>
                                                                    <div className="col-12 p-0"><small className="color-normal text-option-sets">Defense</small><small className="text-option-sets">{' '+parte.defense}</small></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                ):(null)
                                            }
                                            {/** Si es weapon */
                                                parte.tipo === "staff" || parte.tipo === "mace" || parte.tipo === "sword" || parte.tipo === "stick" || parte.tipo === "claws" || parte.tipo === "scepter" || parte.tipo === "crossbow" || parte.tipo === "bow" || parte.tipo === "shield" ? 
                                                (
                                                    <>
                                                        {index === 0 ? (null):(<div className="col-12"><hr className="separador-item"/></div>)}
                                                        <div className="col-6 text-center m-auto p-0">
                                                            <img
                                                                loading="lazy"
                                                                src={parte.imagenFirebase || require('../../assets/items/weapons/'+parte.tipo+'/'+parte.imagen+'.webp')}
                                                                className="img-fluid"
                                                                width={100}
                                                                height={66}
                                                                alt={parte.nombre+" Mu Online"}
                                                                onError={(e) => {
                                                                    console.error("Error loading image, please contact admin to fix it:", e);
                                                            }}/>
                                                        </div>
                                                        <div className="col-6 p-0">
                                                            <div className="d-flex h-100">
                                                                <div className="row justify-content-center align-self-center">
                                                                    <div className="col-12 p-0"><small className="text-option-sets">{parte.nombre}</small></div>
                                                                    {
                                                                        parte.tipo === "shield" ?
                                                                        (
                                                                            <>
                                                                                <div className="col-12 p-0"><small className="color-normal text-option-sets">Defense</small><small className="text-option-sets">{' '+parte.defnormal}</small></div>
                                                                                <div className="col-12 p-0"><small className="color-normal text-option-sets">Defense Rate</small><small className="text-option-sets">{' '+parte.defrate}</small></div>
                                                                                
                                                                            </>
                                                                        ):( /* Si no es shield*/
                                                                            <>
                                                                                <div className="col-12 p-0"><small className="color-normal text-option-sets">Damage</small><small className="text-option-sets">{' '+parte.minattack} ~{' '+parte.maxattack}</small></div>
                                                                                {parte.tipo === "staff" || parte.tipo === "stick" || (parte.tipo === "sword" && parte.wizardry !== "") ? (<div className="col-12 p-0"><small className="color-normal text-option-sets">Wizardry</small><small className="text-option-sets">{' '+parte.wizardry}%</small></div>):(<></>)}
                                                                                {parte.tipo === "scepter" ? (<div className="col-12 p-0"><small className="color-normal text-option-sets">Pet Attack Power</small><small className="text-option-sets">{' '+parte.petattackpower}%</small></div>):(<></>)}
                                                                                <div className="col-12 p-0"><small className="color-normal text-option-sets">Attack Speed</small><small className="text-option-sets">{' '+parte.attackspeed}</small></div>
                                                                            </>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                ):(null)
                                            }
                                            {/** Si es accesorio */
                                                parte.tipo === "ring" || parte.tipo === "pendant" ? 
                                                (
                                                    <>
                                                        {index === 0 ? (null):(<div className="col-12"><hr className="separador-item"/></div>)}
                                                        <div className="col-6 text-center m-auto p-0">
                                                            <img
                                                                loading="lazy"
                                                                src={parte.imagenFirebase || require('../../assets/items/ringpendant/'+parte.tipo+'/'+parte.imagen+'.webp')}
                                                                className="img-fluid"
                                                                width={60}
                                                                height={60}
                                                                alt={parte.nombre+" Mu Online"}
                                                                onError={(e) => {
                                                                    console.error("Error loading image, please contact admin to fix it:", e);
                                                            }}/>
                                                        </div>
                                                        <div className="col-6 p-0">
                                                            <div className="d-flex h-100">
                                                                <div className="row justify-content-center align-self-center">
                                                                    <div className="col-12 p-0"><small className="text-option-sets">{parte.nombre}</small></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                ):(null)
                                            }
                                        </React.Fragment>
                                        )})
                                    }
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                {props.reqlevel === "" ? (<></>):(
                                    <div className="col-12 text-center m-auto">
                                        <p className="text-option m-0"><span className="text-warning">Required Level:</span> {props.reqlevel}</p>
                                    </div>)}
                                <div className="col-12 text-center m-auto mt-1">
                                    {props.equipadoevo.map((clase, index) => {return (
                                        <BadgeCharacter key={index} clase={clase} redireccion={'/characters/'+props.redireccion[index]} />
                                    )})}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-7 col-xxl-6">
                            <div className="row">
                                <div className="col-12 text-warning text-titulo-option mb-2 mt-2">Options Info</div>
                                {
                                    props.dosSetOpciones.length === 0 ? (<></>):(
                                        <>
                                            <div className="col-12 text-center m-auto"><p className="text-danger text-titulo-option m-2">2 Set Effect</p></div>
                                                {
                                                    props.dosSetOpciones.map((opt, index) => {return (
                                                        <div key={index} className="col-12"><p className="text-option m-0"><small>{opt.opcion}</small></p></div>
                                                    )})
                                                }  
                                        </>
                                    )
                                }
                                {
                                    props.tresSetOpciones.length === 0 ? (<></>):(
                                        <>
                                            <div className="col-12 text-center m-auto"><p className="text-danger text-titulo-option m-2">3 Set Effect</p></div>
                                                {
                                                    props.tresSetOpciones.map((opt, index) => {return (
                                                        <div key={index} className="col-12"><p className="text-option m-0"><small>{opt.opcion}</small></p></div>
                                                    )})
                                                }  
                                        </>
                                    )
                                }
                                {
                                    props.cuatroSetOpciones.length === 0 ? (<></>):(
                                        <>
                                            <div className="col-12 text-center m-auto"><p className="text-danger text-titulo-option m-2">4 Set Effect</p></div>
                                                {
                                                    props.cuatroSetOpciones.map((opt, index) => {return (
                                                        <div key={index} className="col-12"><p className="text-option m-0"><small>{opt.opcion}</small></p></div>
                                                    )})
                                                }  
                                        </>
                                    )
                                }
                                {
                                    props.cincoSetOpciones.length === 0 ? (<></>):(
                                        <>
                                            <div className="col-12 text-center m-auto"><p className="text-danger text-titulo-option m-2">5 Set Effect</p></div>
                                                {
                                                    props.cincoSetOpciones.map((opt, index) => {return (
                                                        <div key={index} className="col-12"><p className="text-option m-0"><small>{opt.opcion}</small></p></div>
                                                    )})
                                                }  
                                        </>
                                    )
                                }
                                {
                                    props.seisSetOpciones.length === 0 ? (<></>):(
                                        <>
                                            <div className="col-12 text-center m-auto"><p className="text-danger text-titulo-option m-2">6 Set Effect</p></div>
                                                {
                                                    props.seisSetOpciones.map((opt, index) => {return (
                                                        <div key={index} className="col-12"><p className="text-option m-0"><small>{opt.opcion}</small></p></div>
                                                    )})
                                                }  
                                        </>
                                    )
                                }
                                {
                                    props.sieteSetOpciones.length === 0 ? (<></>):(
                                        <>
                                            <div className="col-12 text-center m-auto"><p className="text-danger text-titulo-option m-2">7 Set Effect</p></div>
                                                {
                                                    props.sieteSetOpciones.map((opt, index) => {return (
                                                        <div key={index} className="col-12"><p className="text-option m-0"><small>{opt.opcion}</small></p></div>
                                                    )})
                                                }  
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-center">
                        <BadgeAddedInSeason addedinseason={props.addedinseason}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

function CardLuckySets(props) {
    return (
        <div className="col mb-4 card-items">
            <div className="card card-bg-sinhover h-100">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="card-title">{props.nombre}</h2>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-6">
                            <div className="row">
                                {
                                    props.infoset.map((parte, index) => {return (
                                    <React.Fragment key={index}>
                                        <>
                                            {index === 0 ? (null):(<div className="col-12"><hr className="separador-item"/></div>)}
                                            <ModalItemSetLucky infoStats={parte.infoStats} nombre={parte.nombre} ticket={props.ticket} defense={parte.defense} imagen={parte.imagen} imagenFirebase={parte.imagenFirebase} tipo={parte.tipo} equipado={props.equipado} redireccion={props.redireccion} dosSetOpciones={props.dosSetOpciones} tresSetOpciones={props.tresSetOpciones} cuatroSetOpciones={props.cuatroSetOpciones} cincoSetOpciones={props.cincoSetOpciones} addedinseason={props.addedinseason}/>
                                        </>
                                    </React.Fragment>
                                    )})
                                }
                            </div>
                            <div className="row mt-2">
                                {props.reqlevel === "" ? (<></>):(
                                    <div className="col-12 text-center m-auto">
                                        <p className="text-option m-0"><span className="text-warning">Required Level:</span> {props.reqlevel}</p>
                                    </div>)}
                                <div className="col-12 text-center m-auto mt-1">
                                    {props.equipadoevo.map((clase, index) => {return (
                                        <BadgeCharacter key={index} clase={clase} redireccion={'/characters/'+props.redireccion[index]} />
                                    )})}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-7 col-xxl-6">
                            <div className="row">
                                <div className="col-12 text-warning text-titulo-option mb-2 mt-2">Options Info</div>
                                {
                                    props.dosSetOpciones.length === 0 ? (<></>):(
                                        <>
                                            <div className="col-12 text-center m-auto"><p className="text-danger text-titulo-option m-2">2 Set Effect</p></div>
                                                {
                                                    props.dosSetOpciones.map((opt, index) => {return (
                                                        <div key={index} className="col-12"><p className="text-option m-0"><small>{opt.opcion}</small></p></div>
                                                    )})
                                                }  
                                        </>
                                    )
                                }
                                {
                                    props.tresSetOpciones.length === 0 ? (<></>):(
                                        <>
                                            <div className="col-12 text-center m-auto"><p className="text-danger text-titulo-option m-2">3 Set Effect</p></div>
                                                {
                                                    props.tresSetOpciones.map((opt, index) => {return (
                                                        <div key={index} className="col-12"><p className="text-option m-0"><small>{opt.opcion}</small></p></div>
                                                    )})
                                                }  
                                        </>
                                    )
                                }
                                {
                                    props.cuatroSetOpciones.length === 0 ? (<></>):(
                                        <>
                                            <div className="col-12 text-center m-auto"><p className="text-danger text-titulo-option m-2">4 Set Effect</p></div>
                                                {
                                                    props.cuatroSetOpciones.map((opt, index) => {return (
                                                        <div key={index} className="col-12"><p className="text-option m-0"><small>{opt.opcion}</small></p></div>
                                                    )})
                                                }  
                                        </>
                                    )
                                }
                                {
                                    props.cincoSetOpciones.length === 0 ? (<></>):(
                                        <>
                                            <div className="col-12 text-center m-auto"><p className="text-danger text-titulo-option m-2">5 Set Effect</p></div>
                                                {
                                                    props.cincoSetOpciones.map((opt, index) => {return (
                                                        <div key={index} className="col-12"><p className="text-option m-0"><small>{opt.opcion}</small></p></div>
                                                    )})
                                                }  
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {props.addedinseason === "" ? (<></>):(
                    <>
                    <div className="row">
                        <div className="col-12 text-center">
                            <BadgeAddedInSeason addedinseason={props.addedinseason}/>
                        </div>
                    </div>
                    </>
                )}
            </div>
        </div>
    )
}

function CardOrbScroll(props) {
    return (
        <div className="col mb-4 card-items">
            <div className="card card-bg-sinhover h-100">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="card-title">{props.nombre}</h2>
                        </div>
                        <div className="col-12">
                            <div className="d-flex justify-content-center">
                                <img
                                    loading="lazy"
                                    src={props.imagenFirebase || require('../../assets/items/'+props.tipo+'/'+props.imagen+'.webp')}
                                    className="img-fluid"
                                    width={70}
                                    height={70}
                                    alt={props.nombre+" Mu Online"}
                                    onError={(e) => {
                                        console.error("Error loading image, please contact admin to fix it:", e);
                                }}/>
                            </div>
                        </div>
                        <div className="col-12 text-center">
                            <p className="text-required-level"><span className="text-warning">Required Level:</span> {props.requirelevel}</p>
                        </div>
                        <div className="col-12">
                            <p className="text-descripcion">{props.descripcion}</p>
                        </div>
                        {
                            props.requireskill !== "" ? (
                                <div className="col-12 text-center">
                                    <p className="text-required-level"><span className="text-warning">Required Skill</span></p>
                                    <p className="text-required-level"><span>{props.requireskill}</span></p>
                                </div>
                            ):(
                            <></>)
                        }
                        {
                            props.masterskilltree !== "" ? (
                                <div className="col-12 text-center p-0">
                                    <p className="text-required-level"><span className="text-warning">Required Master Skill Tree</span></p>
                                    <p className="text-required-level"><span>{props.masterskilltree}</span></p>
                                </div>
                            ):(
                            <></>)
                        }
                        <div className="col-12 text-center">
                            <p className="text-required-level"><span className="text-warning">Required Stats</span></p>
                        </div>
                        <div className="col-12 text-center">
                            {props.requirestats.map((stats, index) => {return (
                                <p className="text-required-level" key={index}><span>{stats}</span></p>
                            )})}
                        </div>
                        <div className="col-12 text-center">
                            {props.equipado.map((clase, index) => {return (
                                <BadgeCharacter key={index} clase={clase} redireccion={'/characters/'+props.redireccion[index]} />
                            )})}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function CardSkill(props) {
    var popoverInfoTypeSkill = (
        <Popover className="popoverInfoTypeSkill">
            <Popover.Header as="h6" className='popoverInfoTypeSkill-header'>Type Skill: {props.typeSkill}</Popover.Header>
            <Popover.Body>
                {
                    props.typeSkill === "default" ? (<p className="text-infoskill">This is an skill that the character has by default when it is created.</p>):(<></>)
                }
                {
                    props.typeSkill === "acquired" ? (
                    <>
                    <p className="text-infoskill">This is a skill that can be used permanently by using skill items such as books, scrolls and orbs.</p>
                    <p className="text-infoskill">You can acquire it by right-clicking the skill item you have in your inventory.</p>
                    <p className="text-infoskill">Acquired skills can be acquired only when conditions such as available levels and stats are satisfied.</p>
                    </>):(<></>)
                }
                {
                    props.typeSkill === "weapon" ? (
                    <>
                    <p className="text-infoskill">This is a skill applied as an option to various weapons.</p>
                    <p className="text-infoskill">This skill can only be used while wearing a weapon to which the skill is applied as an option.</p>
                    </>):(<></>)
                }
                {
                    props.typeSkill === "pet" ? (
                    <>
                    <p className="text-infoskill">This is a skill applied as an option to a pet.</p>
                    <p className="text-infoskill">This skill can only be used while using the pet the skill is applied to as an option.</p>
                    </>):(<></>)
                }
                {
                    props.typeSkill === "quest" ? (<p className="text-infoskill">This skill is activated when completing a quest.</p>):(<></>)
                }
                {
                    props.typeSkill === "masterskill" ? (
                    <>
                    <p className="text-infoskill">A master skill is a skill that a character can acquire after completing the 3rd class change.</p>
                    <p className="text-infoskill">From level 400, you can acquire skills using level points that can be obtained instead of stat points.</p>
                    <p className="text-infoskill">You can check the Master Skill window by pressing the hotkey</p>
                    <p className='text-infoskill-key'>(A key)</p>
                    </>):(<></>)
                }
            </Popover.Body>
        </Popover>
    );

    return (
        <div className="col mb-4 card-items">
            <div className="card card-bg">
                <div className="card-body">
                    {
                        props.typeSkill === "default" ? (<OverlayTrigger placement="auto" overlay={popoverInfoTypeSkill}><span className="icon-skillinfo">D</span></OverlayTrigger>):(<></>)
                    }
                    {
                        props.typeSkill === "acquired" ? (<OverlayTrigger placement="auto" overlay={popoverInfoTypeSkill}><span className="icon-skillinfo">A</span></OverlayTrigger>):(<></>)
                    }
                    {
                        props.typeSkill === "weapon" ? (<OverlayTrigger placement="auto" overlay={popoverInfoTypeSkill}><span className="icon-skillinfo">W</span></OverlayTrigger>):(<></>)
                    }
                    {
                        props.typeSkill === "pet" ? (<OverlayTrigger placement="auto" overlay={popoverInfoTypeSkill}><span className="icon-skillinfo">P</span></OverlayTrigger>):(<></>)
                    }
                    {
                        props.typeSkill === "quest" ? (<OverlayTrigger placement="auto" overlay={popoverInfoTypeSkill}><span className="icon-skillinfo">Q</span></OverlayTrigger>):(<></>)
                    }
                    {
                        props.typeSkill === "masterskill" ? (<OverlayTrigger placement="auto" overlay={popoverInfoTypeSkill}><span className="icon-skillinfo">M</span></OverlayTrigger>):(<></>)
                    }
                    <div className="row">
                        <div className="col-12">
                            <h2 className="card-title">{props.nombre}</h2>
                        </div>
                        <div className="col-2">
                            <div className="d-flex justify-content-center">
                                {
                                    props.imagen !== "" ? (
                                        <img loading="lazy" src={require('../../assets/skills/'+props.imagen+'.webp')} width={35} height={50} className="img-fluid rounded" alt={props.nombre+" Mu Online"}/>
                                    ):(<></>)
                                }
                            </div>
                        </div>
                        <div className="col-10">
                            <p className="text-descripcion-skill-character">{props.descripcion}</p>
                        </div>
                        {props.castlesiege ? (<div className="col-12"><p className="text-infocastlesiege">Only becomes active for use in Castle Siege</p></div>):(<></>)}
                    </div>
                </div>
            </div>
        </div>
    )
}

function CardNews(props) {
    return (
        <div className="col mb-4 card-news">
            <Link to={'/latestnews/season'+props.season+'/'+props.redirection} className="link-news">
                <div className="card card-news-bg">
                    <img
                        loading="lazy"
                        src={props.imagenFirebase}
                        className="imagen-news"
                        alt={props.titulo}
                        onError={(e) => {
                            console.error("Error loading image, please contact admin to fix it:", e);
                    }}/>
                    <div className="contenido-news">
                        <BadgeSeason seasonpart={props.seasonpart} hot={false}/>
                        <h2 className="title-news">{props.titulo}</h2>
                        <span className="date-news">{props.fecha}</span>
                    </div>
                </div>
            </Link>
        </div>
    )
}

function CardNewsMain(props) {
    return (
        <div className="col mb-4 card-news">
            <Link to={'/latestnews/season'+props.season+'/'+props.redirection} className="link-news">
                <div className="card card-news-bg">
                    <img
                        loading="eager"
                        src={props.imagenMainFirebase}
                        className="imagen-news-main"
                        width={524}
                        height={459}
                        alt={props.titulo}
                        onError={(e) => {
                            console.error("Error loading image, please contact admin to fix it:", e);
                    }}/>
                    <div className="contenido-news-main">
                        <BadgeSeason seasonpart={props.seasonpart} hot={true}/>
                        <h2 className="title-news">{props.titulo}</h2>
                        <span className="date-news">{props.fecha}</span>
                    </div>
                </div>
            </Link>
        </div>
    )
}

function CardSkillNews(props) {
    return (
        <div className="col-md-6 col-lg-6 col-xl-4 col-xxl-4 mb-4 card-items">
            <div className="card card-bg-sinhover h-100">
                {props.imagen === "" ? <></> : (
                    props.imagen.substr(props.imagen.length - 3) === "gif" ? (
                        <img loading="lazy" src={require('../../assets/skills/skilldemo/'+props.imagen)} className="img-fluid rounded" alt={"Skill "+props.nombre+" Mu Online"}/>
                    ):(
                        <img loading="lazy" src={require('../../assets/skills/skilldemo/'+props.imagen+'.webp')} className="img-fluid rounded" alt={"Skill "+props.nombre+" Mu Online"}/>
                    )
                )}
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="card-title">{props.nombre}</h2>
                        </div>
                        {props.descripcion === "" ? <></> : <div className="col-12"><p className="text-justify">{props.descripcion}</p></div>}
                        {props.other === "" ? <></> : <div className="col-12"><p className="text-justify">{props.other}</p></div>}
                        {props.numbertargets && (
                            <div className="col-12 mb-2">
                                <p className="titulo-req-skill-new text-accent-upper">Number of Targets: <span className="text-stat-skill-new text-uppercase">{props.numbertargets}</span></p>
                            </div>
                        )}
                        {props.attackrange && (
                            <div className="col-12 mb-2">
                                <p className="titulo-req-skill-new text-accent-upper">Attack Range: <span className="text-stat-skill-new text-uppercase">{props.attackrange}</span></p>
                            </div>
                        )}
                        {props.damagecount && (
                            <div className="col-12 mb-2">
                                <p className="titulo-req-skill-new text-accent-upper">Damage Count: <span className="text-stat-skill-new text-uppercase">{props.damagecount}</span></p>
                            </div>
                        )}
                        {props.requiredconditions !== "" && (
                            <>
                                <div className="col-12">
                                <p className="titulo-req-skill-new text-accent-upper">Required Conditions:</p>
                                </div>
                                {props.requiredconditions.map((req, index) => (
                                <div className="col-12" key={index}>
                                    <p className="text-stat-skill-new text-uppercase">- {req}</p>
                                </div>
                                ))}
                            </>
                        )}
                    </div>
                </div>
                <div className="row p-2">
                    {props.mainelement === "" ? <></> : <div className="col-12"><p className="text-option"><span className='text-accent-upper'>Main Element:</span> {props.mainelement}</p></div>}
                    {props.cooldown === "" ? <></> : <div className="col-12 mt-2"><p className="text-option-sets text-info">{props.cooldown}</p></div>}
                </div>

            </div>
        </div>
    )
}

function CardWeaponNews(props) {
    
    const [imageSrc, setImageSrc] = useState(null);

    useEffect(() => {
        if (props.imagen && props.imagen !== "") {
            const loadImage = async () => {
                try {
                    const src = await import(`../../assets/items/weapons/${props.tipo}/${props.imagen}.webp`);
                    setImageSrc(src.default);
                } catch (error) {
                    console.log('Imagen no encontrada:', error.message);
                    setImageSrc(null); // No mostrar nada si la imagen no se encuentra
                }
            };

            loadImage();
        }
    }, [props.imagen, props.tipo]);

    return (
        <div className="col mb-4 card-items">
            <div className="card card-bg-sinhover h-100">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="card-title">{props.nombre}</h2>
                        </div>
                        <div className="col-12">
                            <div className="d-flex justify-content-center">
                                {imageSrc && (
                                    <img 
                                        loading="lazy" 
                                        src={imageSrc} 
                                        className="img-fluid" 
                                        width={180} 
                                        height={119} 
                                        alt={props.nombre + " Mu Online"} 
                                    />
                                )}
                            </div>
                        </div>
                        {props.tipo === "shield" && props.defense !== "" && (
                            <>
                                <div className="col-12">
                                    <p className='text-titulo-option text-accent m-0'>DEFENSE: <span className='text-option text-blanco'>{props.defense}</span></p>
                                </div>
                                <div className="col-12">
                                    <p className='text-titulo-option text-accent m-0'>DEFENSE SUCCESS RATE: <span className='text-option text-blanco'>{props.defsuccessrate}</span></p>
                                </div>
                            </>
                        )}
                        {
                            props.mindmg !== "" && props.maxdmg !== "" && props.atkspeed !== "" ? (
                                <>
                                    <div className="col-12">
                                        <p className='text-titulo-option text-accent m-0'>MIN/MAX DAMAGE: <span className='text-option text-blanco'>{props.mindmg} - {props.maxdmg}</span></p>
                                    </div>
                                    <div className="col-12">
                                        <p className='text-titulo-option text-accent m-0'>ATTACK SPEED: <span className='text-option text-blanco'>{props.atkspeed}</span></p>
                                    </div>
                                </>
                            ):(<></>)
                        }
                        {
                            props.wizardrydmg !== "" ? (<div className="col-12"><p className='text-titulo-option text-accent m-0'>WIZARDRY DAMAGE (%): <span className='text-option text-blanco'>{props.wizardrydmg}</span></p></div>):(<></>)
                        }
                        {
                            props.skill !== "" ? (<div className="col-12"><p className='text-titulo-option text-accent m-0'>SKILL: <span className='text-option text-blanco'>{props.skill}</span></p></div>):(<></>)
                        }
                        {
                            props.requiredstats === "" ? <></> : (<>
                            <div className="col-12 mt-2">
                                <p className='text-titulo-option text-warning m-0'>Required Stats</p>
                            </div>
                            <div className="col-12">
                                <div className="row justify-content-center">
                                    {
                                        props.requiredstats.map((stat, index) => {return (
                                            <React.Fragment key={index}>
                                                <div className="col-6"><p className='text-option m-0'><small>{stat}</small></p></div>
                                            </React.Fragment>
                                        )})
                                    }
                                </div>
                            </div>
                            </>)
                        }
                        {
                            props.defaultoptions && props.defaultoptions.length > 0 && (
                                <>
                                    <div className="col-12 mt-2">
                                        <p className='text-titulo-option text-warning m-0'>Default Options</p>
                                    </div>
                                    {
                                        props.defaultoptions.map((opt, index) => (
                                            <React.Fragment key={index}>
                                                <div className="col-12 text-center">
                                                    <p className='text-option m-0'><small>{opt}</small></p>
                                                </div>
                                            </React.Fragment>
                                        ))
                                    }
                                </>
                            )
                        }
                        {
                            props.excellentoptions === "" ? <></> : (<>
                            <div className="col-12 mt-2">
                                <p className='text-titulo-option text-warning m-0'>Excellent Options</p>
                            </div>
                            {
                                props.excellentoptions.map((opt, index) => {return (
                                    <React.Fragment key={index}>
                                        <div className="col-12 text-center"><p className='text-option m-0'><small>{opt}</small></p></div>
                                    </React.Fragment>
                                )})
                            }
                            </>)
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

function CardSetNormalNews(props) {
    return (
        <div className="col mb-4 card-items">
            <div className="card card-bg-sinhover h-100">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="card-title">{props.nombre}</h2>
                        </div>
                        {props.infoset.map((parte, index) => {return (
                            <React.Fragment key={index}>
                                {index === 0 ? (null):(<div className="col-12"><hr className="separador-item"/></div>)}
                                <div className="col-6 text-center m-auto p-0">
                                    {parte.imagen === "" ? 
                                    (<img loading="lazy" src={require('../../assets/items/set-items/EquipmentVacio.webp')} className="img-fluid" width={100} height={66} alt={"Mu Online"}/>)
                                    :
                                    (<img loading="lazy" src={require('../../assets/items/set-items/'+parte.tipo+'/'+parte.imagen+'.webp')} className="img-fluid" width={100} height={66} alt={parte.nombre+" Mu Online"}/>)}
                                </div>
                                <div className="col-6 p-0">
                                    <div className="d-flex h-100">
                                        <div className="row justify-content-center align-self-center">
                                            <div className="col-12 p-0"><small className="color-normal text-option-sets">Defense: </small><small className="text-option-sets">{' '+parte.defense}</small></div>
                                            <div className="col-12 p-0"><small className="text-warning text-option-sets">Required Stats:</small></div>
                                            {parte.Strength === "" ? (null):(<div className="col-12 p-0"><li><small className="text-option-sets">Strength: {parte.Strength}</small></li></div>)}
                                            {parte.Agility === "" ? (null):(<div className="col-12 p-0"><li><small className="text-option-sets">Agility: {parte.Agility}</small></li></div>)}
                                            {parte.Energy === "" ? (null):(<div className="col-12 p-0"><li><small className="text-option-sets">Energy: {parte.Energy}</small></li></div>)}
                                            {parte.Stamina === "" ? (null):(<div className="col-12 p-0"><li><small className="text-option-sets">Stamina: {parte.Stamina}</small></li></div>)}
                                            {parte.Command === "" ? (null):(<div className="col-12 p-0"><li><small className="text-option-sets">Command: {parte.Command}</small></li></div>)}
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        )})}
                        {props.reqlevel === "" ? (<></>):(<div className="col-12 mt-2"><p className="text-option-sets text-warning text-center">Required Level: <span className="text-option-sets">{props.reqlevel}</span></p></div>)}
                        <div className="col-12 text-center mt-2"><BadgeCharacter clase={props.clase} redireccion={'/characters/'+props.redireccion} /></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function CardSetAncientNews(props) {
    return (
        <div className="col mb-4 card-items">
            <div className="card card-bg-sinhover h-100">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="card-title">{props.nombre}</h2>
                        </div>
                        {props.infoset.map((parte, index) => {return (
                            <React.Fragment key={index}>
                                {index === 0 ? (null):(<div className="col-12"><hr className="separador-item"/></div>)}
                                <div className="col-6 text-center m-auto p-0">
                                    {parte.imagen === "" ? 
                                    (<img loading="lazy" src={require('../../assets/items/set-items/EquipmentVacio.webp')} className="img-fluid" width={100} height={66} alt={"Mu Online"}/>)
                                    :
                                    ( parte.tipo === "helm" || parte.tipo === "armor" || parte.tipo === "gloves" || parte.tipo === "pants" || parte.tipo === "boots" ? 
                                    <img loading="lazy" src={require('../../assets/items/set-items/'+parte.tipo+'/'+parte.imagen+'.webp')} className="img-fluid" width={100} height={66} alt={parte.nombre+" Mu Online"}/>
                                    :
                                    <img loading="lazy" src={require('../../assets/items/weapons/'+parte.tipo+'/'+parte.imagen+'.webp')} className="img-fluid" width={100} height={66} alt={parte.nombre+" Mu Online"}/>)}
                                </div>
                                <div className="col-6 p-0">
                                    <div className="d-flex h-100">
                                        <div className="row justify-content-center align-self-center">
                                            <div className="col-12 p-0"><small className="color-normal text-option-sets">Defense: </small><small className="text-option-sets">{' '+parte.defense}</small></div>
                                            <div className="col-12 p-0"><small className="text-warning text-option-sets">Required Stats:</small></div>
                                            {parte.Strength === "" ? (<></>):(<div className="col-12 p-0"><li><small className="text-option-sets">Strength: {parte.Strength}</small></li></div>)}
                                            {parte.Agility === "" ? (<></>):(<div className="col-12 p-0"><li><small className="text-option-sets">Agility: {parte.Agility}</small></li></div>)}
                                            {parte.Energy === "" ? (<></>):(<div className="col-12 p-0"><li><small className="text-option-sets">Energy: {parte.Energy}</small></li></div>)}
                                            {parte.Stamina === "" ? (<></>):(<div className="col-12 p-0"><li><small className="text-option-sets">Stamina: {parte.Stamina}</small></li></div>)}
                                            {parte.Command === "" ? (<></>):(<div className="col-12 p-0"><li><small className="text-option-sets">Command: {parte.Command}</small></li></div>)}
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        )})}
                        {props.reqlevel === "" ? (<></>):(<div className="col-12 mt-2"><p className="text-option-sets text-warning text-center">Required Level: <span className="text-option-sets">{props.reqlevel}</span></p></div>)}
                        <div className="col-12 text-center mt-2"><BadgeCharacter clase={props.clase} redireccion={'/characters/'+props.redireccion} /></div>
                        <div className="col-12"><p className="text-option-sets text-warning text-center mb-0 mt-2">Options</p></div>
                        {
                            props.ancientOptions.map((optionAncient, index) => {return (
                                <div key={index} className="col-12"><p className="text-option-sets"><small>{optionAncient.opcion}</small></p></div>
                            )})
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

function CardSetMasteryNews(props) {
    return (
        <div className="col mb-4 card-items">
            <div className="card card-bg-sinhover h-100">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="card-title">{props.nombre}</h2>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-5 col-xxl-6">
                            <div className="row">
                                <div className="col-12 m-auto">
                                    <div className="row">
                                    {
                                        props.infoset.map((parte, index) => {return (
                                        <React.Fragment key={index}>
                                            {index === 0 ? (<></>):(<div className="col-12"><hr className="separador-item"/></div>)}
                                            <div className="col-6 text-center m-auto p-0">
                                                {parte.imagen === "" ? 
                                                (<img loading="lazy" src={require('../../assets/items/set-items/EquipmentVacio.webp')} className="img-fluid" width={100} height={66} alt={"Mu Online"}/>)
                                                :
                                                (<img loading="lazy" src={require('../../assets/items/set-items/'+parte.tipo+'/'+parte.imagen+'.webp')} className="img-fluid" width={100} height={66} alt={parte.nombre+" Mu Online"}/>)}
                                            </div>
                                            <div className="col-6">
                                                <div className="d-flex h-100">
                                                    <div className="row justify-content-center align-self-center">
                                                        <div className="col-12 p-0"><small className="color-normal text-option-sets">Defense: </small><small className="text-option-sets">{' '+parte.defense}</small></div>
                                                        <div className="col-12 p-0"><small className="text-warning text-option-sets">Required Stats:</small></div>
                                                        {parte.Strength === "" ? (<></>):(<div className="col-12 p-0"><small className="text-option-sets">Strength: {parte.Strength}</small></div>)}
                                                        {parte.Agility === "" ? (<></>):(<div className="col-12 p-0"><small className="text-option-sets">Agility: {parte.Agility}</small></div>)}
                                                        {parte.Energy === "" ? (<></>):(<div className="col-12 p-0"><small className="text-option-sets">Energy: {parte.Energy}</small></div>)}
                                                        {parte.Stamina === "" ? (<></>):(<div className="col-12 p-0"><small className="text-option-sets">Stamina: {parte.Stamina}</small></div>)}
                                                        {parte.Command === "" ? (<></>):(<div className="col-12 p-0"><small className="text-option-sets">Command: {parte.Command}</small></div>)}
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                        )})
                                    }
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                {
                                    props.reqlevel === "" ? <></> : (
                                        <div className="col-12 text-center m-auto">
                                            <p className="text-option m-0"><span className="text-warning">Required Level:</span> {props.reqlevel}</p>
                                        </div>)
                                }
                                <div className="col-12 text-center m-auto mt-1">
                                    <BadgeCharacter clase={props.equipadoevo} redireccion={'/characters/'+props.redireccion}></BadgeCharacter>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-7 col-xxl-6">
                            <div className="row">
                                <div className="col-12 text-warning text-titulo-option mb-2 mt-2">Options Info</div>
                                <div className="col-12 text-center m-auto">
                                    <p className="text-danger text-titulo-option m-2">2 Set Effect</p>
                                </div>
                                {
                                    props.dosSetsOpciones.map((dosOption, index) => {return (
                                        <div key={index} className="col-12"><p className="text-option-sets">{dosOption.opcion}</p></div>
                                    )})
                                }
                                <div className="col-12 text-center m-auto">
                                    <p className="text-danger text-titulo-option m-2">3 Set Effect</p>
                                </div>
                                {
                                    props.tresSetsOpciones.map((tresOption, index) => {return (
                                        <div key={index} className="col-12"><p className="text-option-sets">{tresOption.opcion}</p></div>
                                    )})
                                }  
                                <div className="col-12 text-center m-auto">
                                    <p className="text-danger text-titulo-option m-2">4 Set Effect</p>
                                </div>
                                {
                                    props.cuatroSetsOpciones.map((cuatroOption, index) => {return (
                                        <div key={index} className="col-12"><p className="text-option-sets">{cuatroOption.opcion}</p></div>
                                    )})
                                }  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function CardMonsterNews(props) {
    return (
        <div className="col mb-4">
            <div className="card card-bg-sinhover h-100">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 text-center m-auto">
                            <img loading="lazy" src={require('../../assets/maps/monsters/'+props.imagen+'.webp')} className="img-fluid rounded" alt={props.nombre+" Mu Online"}/>
                        </div>
                        <div className="col-12 text-center">
                            <p className="descripcion-latest-news text-accent-upper fw-bold">{props.nombre}</p>
                            <p className="descripcion-latest-news text-danger">Level {props.level}</p>
                            <p className="descripcion-latest-news">{props.descripcion}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function CardWingsNews(props) {
    return (
        <div className="col mb-4 card-items">
            <div className="card card-bg-sinhover h-100">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="card-title">{props.nombre}</h2>
                        </div>
                        {
                            props.imagen !== "" ? (
                                <div className="col-12 text-center m-auto">
                                    <img
                                        loading="lazy"
                                        src={props.imagenFirebase || require('../../assets/items/wings/'+props.imagen+'.webp')}
                                        className="img-fluid"
                                        width={200}
                                        height={132}
                                        alt={props.nombre+" Mu Online"}
                                        onError={(e) => {
                                            console.error("Error loading image, please contact admin to fix it:", e);
                                    }}/>
                                </div>
                            ):(<></>)
                        }
                        {
                            props.requirelevel !== "" ? (
                                <div className="col-12 text-center">
                                    <p className="text-required-level"><span className="text-warning">Required Level:</span> {props.requirelevel}</p>
                                </div>
                            ):(<></>)
                        }
                        {
                            props.wizardry !== "" ? (
                                <div className="col-12 text-center">
                                    <p className='text-titulo-option text-accent m-0'>WIZARDRY: <span className='text-option text-blanco'>{props.wizardry}</span></p>
                                </div>
                            ):(<></>)
                        }
                        {
                            props.defense !== "" ? (
                                <div className="col-12 text-center">
                                    <p className='text-titulo-option text-accent m-0'>DEFENSE: <span className='text-option text-blanco'>{props.defense}</span></p>
                                </div>
                            ):(<></>)
                        }
                        {
                            props.dmgincrease !== "" ? (
                                <div className="col-12 text-center">
                                    <p className='text-titulo-option text-accent m-0'>DAMAGE INCREASE: <span className='text-option text-blanco'>{props.dmgincrease}</span></p>
                                </div>
                            ):(<></>)
                        }
                        {
                            props.dmgabsorption !== "" ? (
                                <div className="col-12 text-center">
                                    <p className='text-titulo-option text-accent m-0'>DAMAGE ABSORPTION: <span className='text-option text-blanco'>{props.dmgabsorption}</span></p>
                                </div>
                            ):(<></>)
                        }
                        {
                            props.options === "" || props.options === null ? <></> : (<>
                            <div className="col-12 mt-2">
                                <p className='text-titulo-option text-warning m-0'>Possible Options</p>
                            </div>
                            {
                                props.options.map((opt, index) => {return (
                                    <React.Fragment key={index}>
                                        <div className="col-12 text-center"><p className='text-option m-0'><small>{opt}</small></p></div>
                                    </React.Fragment>
                                )})
                            }
                            </>)
                        }
                        {
                            props.clase !== "" ? (
                                <div className="col-12 text-center">
                                    <BadgeCharacter clase={props.clase} redireccion={'/characters/'+props.redireccion} />
                                </div>
                            ):(<></>)
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

function CardEarringsNews(props) {
    return (
        <div className="col mb-4 card-items">
            <div className="card card-bg-sinhover h-100">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="card-title">{props.nombre}</h2>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12 text-center m-auto">
                                    <img loading="lazy" src={require('../../assets/items/earrings/'+props.imagen+'.webp')} className="img-fluid" width={200} height={132} alt={props.nombre+" Mu Online"}/>
                                </div>
                                <div className="col-12 text-center m-auto">
                                    <p className="text-option m-0"><span className="text-warning">Required Level:</span> {props.reqLevel}</p>
                                </div>
                                <div className="col-12 text-center m-auto">
                                    <p className="text-option m-0"><span className="text-info">Attack / Wizardry / Curse:</span> {props.attackWizardryCurseMin} ~ {props.attackWizardryCurseMax}</p>
                                </div>
                                <div className="col-12 text-center m-auto">
                                    <p className="text-option m-0"><span className="color-excellent">Elemental defense:</span> {props.elementalDef}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function CarditemGenerico(props) {
    return (
        <div className="col mb-4 card-items">
            <div className="card card-bg-sinhover h-100">
                <div className="card-body">
                    <div className="row">
                        {
                            props.imagen !== "" ? (
                                <div className="col-12 text-center m-auto">
                                    <img loading="lazy" src={require('../../assets/items/miscellaneous/'+props.imagen+'.webp')} className="img-fluid rounded" width={100} height={100} alt={props.nombre+" Mu Online"}/>
                                </div>
                            ):("")
                        }
                        <div className="col-12 mt-3 text-center">
                            <h2 className="card-title">{props.nombre}</h2>
                            <p className="descripcion-latest-news">{props.descripcion}</p>
                            {props.extra === "" ? <></>: <p className="descripcion-latest-news">{props.extra}</p>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function CardMonsterEvent(props) {
    return (
        <div className="col mb-4 card-items">
            <div className="card card-bg-sinhover h-100">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="card-title-monster">{props.nombre}</h2>
                        </div>
                        {
                            props.imagenFirebase !== "" ? (
                                <>
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-5 text-center">
                                        <div className="row">
                                            <div className="col-12">                                            
                                                {props.imagenFirebase && (
                                                    <img 
                                                        loading="lazy" 
                                                        src={props.imagenFirebase} 
                                                        className="img-fluid" 
                                                        width={145} 
                                                        height={96} 
                                                        alt={props.nombre + " Mu Online"}
                                                    />
                                                )}
                                            </div>
                                            <div className="col-12">
                                                <p className="card-level-monster">Level {props.level}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-7 m-auto">
                                        <div className="row">
                                            {
                                                props.hp === "" ? (<></>):(
                                                    <div className="col-12">
                                                        <p className="text-title-stats-monster">Health: <span className="text-stats-monster">{props.hp}</span></p>
                                                    </div>
                                                )
                                            }
                                            {
                                                props.minattack === "" ? (<></>):(
                                                    <div className="col-12">
                                                        <p className="text-title-stats-monster">Min/Max Attack: <span className="text-stats-monster">{props.minattack} ~ {props.maxattack}</span></p>
                                                    </div>
                                                )
                                            }
                                            {
                                                props.defense === "" ? (<></>):(
                                                    <div className="col-12">
                                                        <p className="text-title-stats-monster">Defense: <span className="text-stats-monster">{props.defense}</span></p>
                                                    </div>
                                                )
                                            }
                                            {
                                                props.defensesuccessrate === "" ? (<></>):(
                                                    <div className="col-12">
                                                        <p className="text-title-stats-monster">Defense Success Rate: <span className="text-stats-monster">{props.defensesuccessrate}</span></p>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </>
                            ):(
                                <div className="col-12 m-auto">
                                    <div className="row">
                                        <div className="col-12">
                                            <p className="card-level-monster">Level {props.level}</p>
                                        </div>
                                        {
                                            props.hp === "" ? (<></>):(
                                                <div className="col-12">
                                                    <p className="text-title-stats-monster">Health: <span className="text-stats-monster">{props.hp}</span></p>
                                                </div>
                                            )
                                        }
                                        {
                                            props.minattack === "" ? (<></>):(
                                                <div className="col-12">
                                                    <p className="text-title-stats-monster">Min/Max Attack: <span className="text-stats-monster">{props.minattack} ~ {props.maxattack}</span></p>
                                                </div>
                                            )
                                        }
                                        {
                                            props.defense === "" ? (<></>):(
                                                <div className="col-12">
                                                    <p className="text-title-stats-monster">Defense: <span className="text-stats-monster">{props.defense}</span></p>
                                                </div>
                                            )
                                        }
                                        {
                                            props.defensesuccessrate === "" ? (<></>):(
                                                <div className="col-12">
                                                    <p className="text-title-stats-monster">Defense Success Rate: <span className="text-stats-monster">{props.defensesuccessrate}</span></p>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }
                        {
                            props.descripcion !== "" ? (
                                <div className="col-12">
                                    <p className="text-stats-monster">{props.descripcion}</p>
                                </div>
                            ):(<></>)
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

function CardEvents(props) {
    return (
        <Link to={"/events/"+props.redirection} className="link-char-home">
            <div className="col mb-2 h-100">
                <div className="card card-event-home text-center">
                    <div className="card-body p-1 text-center">
                        <div className="col-12">
                            <img loading="lazy" src={require('../../assets/guides/events/'+props.imagen+'.webp')} className="img-fluid" width={40} height={40} alt={props.nombre+" Mu Online"}/>
                        </div>
                        <div className="col-12">
                            <h2 className="nombre-event-home align-middle">{props.nombre}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    )
}

function CardItemCashShop(props) {
    return (
        <div className="col mb-4 card-items">
            <div className="card card-bg-sinhover h-100">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="card-title">{props.nombre}</h2>
                        </div>
                        <div className="col-12 text-center m-auto p-0">
                            <img
                                loading="lazy"
                                src={props.imagenFirebase}
                                className="img-fluid"
                                height={70}
                                width={70}
                                alt={props.nombre+" Mu Online"}
                                onError={(e) => {
                                    console.error("Error loading image, please contact admin to fix it:", e);
                            }}/>
                        </div>
                        {
                            props.descripcion.map((desc, index) => {return (
                                <div key={index} className="col-12"><p className="text-descripcion-opcion">{desc}</p></div>
                            )})
                        }
                        {
                            props.infoextra === "" ? (<></>) :
                                (<>
                                    <div className="col-12"><p className='text-accent-upper mb-2 mt-2'>Info:</p></div>
                                    {
                                        props.infoextra.map((info, index) => {return (
                                            <div key={index} className="col-12"><p className="text-descripcion-opcion">{info}</p></div>
                                        )})
                                    }
                                </>)
                        }
                        <div className="col-12"><p className='text-accent-upper mb-2 mt-2'>Price:</p></div>
                        <div className="col-12">
                            <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2 row-cols-xxl-2">
                                {
                                    props.price.map((pri, index) => {return (
                                        <div key={index} className="col">
                                            <BadgePrice texto={pri} />
                                        </div>
                                    )})
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function CardCommunityBuild(props) {
    return (
        <div className="p-1 mb-4 card-items">
            <div className="card card-bg-sinhover h-100">
                <div className="row">
                    <div className="col-12 mb-2">
                        <div className="">
                            <iframe src={'https://www.youtube.com/embed/' + props.videoId + '?autoplay=0&controls=1&rel=0&showinfo=0'} className='w-100 rounded' height={200} title="test" allowFullScreen></iframe>
                        </div>
                    </div>
                </div>
                <div className="row row-cols-3 row-cols-sm-3 row-cols-md-3 row-cols-lg-3 row-cols-xl-3 row-cols-xxl-3 justify-content-center pe-2 ps-2 mb-2">
                    {props.season ? (
                        <div className="col text-center p-0">
                            <BadgeCommunityBuildData texto={"Season " + props.season} />
                        </div>
                    ) : (<></>)}
                    {props.levelCharacter ? (
                        <div className="col text-center p-0">
                            <BadgeCommunityBuildData texto={"Level " + props.levelCharacter} />
                        </div>
                    ) : (<></>)}
                    {props.language ? (
                        <div className="col text-center p-0">
                            <BadgeCommunityBuildData texto={props.language} />
                        </div>
                    ) : (<></>)}
                </div>
            </div>
        </div>
    )
}

function CardCommunityContact(props) {
    return (
        <div className="mb-4 card-items">
            <div className="card card-bg-sinhover h-100">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <p className="card-mapa-descripcion m-0">If you are a <span className="text-accent">GMO</span> content creator and want to appear here <span className="text-accent-upper">totally free</span>, send us your builds to add them here!<Link to="/contact" className="link-char-home"><button type="button"  className="btn community-contact-build ms-2"><i className="bi bi-envelope"></i> Contact</button></Link></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function CardContentCreatorContact(props) {
    return (
        <div className="mb-4 card-items">
            <div className="card card-bg-sinhover h-100">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <p className="card-mapa-descripcion m-0">If you are a Mu Online content creator and want to be here <span className="text-accent-upper">totally free</span>, send us your channel to add it!<Link to="/contact" className="link-char-home"><button type="button"  className="btn community-contact-build ms-2"><i className="bi bi-envelope"></i> Contact</button></Link></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function CardWingCore(props) {
    return (
        <div className="col mb-4 card-items">
            <div className="card card-bg-sinhover h-100">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="card-title">{props.nombre}</h2>
                        </div>
                        {props.imagen ? (
                            <div className="col-12">
                                <div className="d-flex justify-content-center">
                                    <img
                                        loading="lazy"
                                        src={props.imagenFirebase || require('../../assets/items/wingcore/'+props.imagen+'.webp')}
                                        className="img-fluid"
                                        width={150}
                                        height={99}
                                        alt={props.nombre+" Mu Online"}
                                        onError={(e) => {
                                            console.error("Error loading image, please contact admin to fix it:", e);
                                    }}/>
                                </div>
                            </div>
                        ) : (<></>)}
                        {
                            props.wearable === "" ? <></> : (
                                props.wearable.map((wear, index) => {return (
                                    <React.Fragment key={index}>
                                        <div className="col-12 text-option"><small>{wear}</small></div>
                                    </React.Fragment>
                                )})
                            )
                        }
                        
                    </div>
                </div>
            </div>
        </div>
    )
}


function CardContentCreator(props) {
    return (
        <div className="col mb-4 card-items d-flex align-items-center justify-content-center">
            <div className="card card-content-creator h-100">
                <div className="row">
                    <div className="col-12 text-center d-flex flex-column align-items-center">
                        <div className="container-img-creator d-flex align-items-center justify-content-center">
                            <img
                                loading="lazy"
                                src={props.imagenFirebase}
                                className="img-fluid img-content-creator"
                                alt={props.nombre}
                                onError={(e) => {
                                    console.error("Error loading image, please contact admin to fix it:", e);
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <h2 className="nombre-content-creator">{props.nombre}</h2>
                    </div>
                    <div className="col-12 social-content-creator d-flex justify-content-center align-items-center">
                        {props.youtube ? (<SocialLink tipo={"youtube"} redireccion={props.youtube} />) : (<></>)}
                        {props.twitch ? (<SocialLink tipo={"twitch"} redireccion={props.twitch} />) : (<></>)}
                        {props.facebook ? (<SocialLink tipo={"facebook"} redireccion={props.facebook} />) : (<></>)}
                    </div>
                </div>
            </div>
        </div>
    );
}

/**
 * function CardContentCreator(props) {
    return (
        <div className="col mb-2 card-items justify-content-center text-center">
            <div className="card card-bg-sinhover container-social h-100">
                <div className="row container-img-creator justify-content-center text-center">
                    <div className="col-12 p-0">
                        <img
                            loading="lazy"
                            src={props.imagenFirebase || require('../../assets/contentcreators/'+props.imagen+'.webp')}
                            className="img-fluid img-content-creator"
                            width={180}
                            alt={props.nombre+" Mu Online"}
                            onError={(e) => {
                                console.error("Error loading image, please contact admin to fix it:", e);
                        }}/>
                    </div>
                    <div className="col-12">
                        <h2 className="nombre-char-home">{props.nombre}</h2>
                    </div>
                    <div className="col-12">
                        <div className="row">
                            {props.youtube ? (
                                <div className="col-12">
                                    <SocialLink tipo={"youtube"} redireccion={props.youtube} />
                                </div>
                            ) : (<></>)}
                            {props.twitch ? (
                                <div className="col-12">
                                    <SocialLink tipo={"twitch"} redireccion={props.twitch} />
                                </div>
                            ) : (<></>)}
                            {props.facebook ? (
                                <div className="col-12">
                                    <SocialLink tipo={"facebook"} redireccion={props.facebook} />
                                </div>
                            ) : (<></>)}
                            {props.kick ? (
                                <div className="col-12">
                                    <SocialLink tipo={"kick"} redireccion={props.kick} />
                                </div>
                            ) : (<></>)}
                        </div>
                    </div>
                    </div>
                    <div className="row">   
                        <div className="col-12">
                            {
                                props.language?.map((idioma, index) => {
                                    return (
                                        <BadgeCommunityBuildData key={index} texto={idioma} />
                                    );
                                })
                            }
                        </div>
                    </div> 
                </div>
            </div>
        )
    }
    
 */

function CardAbilityExtraCard(props) {
    return (
        <div className="col mb-4 card-items">
            <div className="card card-bg-sinhover h-100">
                <div className="row">
                    <div className="col-12">
                        <h2 className="ability-card-title"><BadgeAbilityBook texto={"Extra Damage"} tipo={props.tipocard}/> {props.nombre}</h2>
                    </div>
                </div>
                <div className="card-body pt-0">
                    <div className="row">
                        {
                            props.imagen !== "" ? (
                                <div className="col-sm-12 col-md-3 text-center m-auto">
                                    <img
                                        loading="lazy"
                                        src={props.imagenFirebase || require('../../assets/charactersinformation/abilityenhancementcore/extracard/'+props.imagen+'.webp')}
                                        className="img-fluid rounded"
                                        width={58}
                                        height={72}
                                        alt={props.nombre+" Extra Card Mu Online"}
                                        onError={(e) => {
                                            console.error("Error loading image, please contact admin to fix it:", e);
                                    }}/>
                                </div>
                            ):(<></>)
                        }
                        <div className="col-sm-12 col-md-9">
                            <div className="d-flex justify-content-center align-items-center h-100">
                                <p className="text-descripcion-ability-card align-self-center">{props.description}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-12">
                            <p className="text-extrainfo-ability-card text-warning">{props.extrainfo}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function CardAbilitySkillCard(props) {
    return (
        <div className="col mb-4 card-items">
            <div className="card card-bg-sinhover h-100">
                <div className="row">
                    <div className="col-12">
                        <h2 className="ability-card-title"><BadgeAbilityBook texto={"Skill"} tipo={props.tipocard}/> {props.nombre}</h2>
                    </div>
                </div>
                <div className="card-body pt-0">
                    <div className="row">
                        {
                            props.imagen !== "" ? (
                                <div className="col-sm-3 text-center m-auto">
                                    <img
                                        loading="lazy"
                                        src={props.imagenFirebase || require('../../assets/charactersinformation/abilityenhancementcore/skillcard/'+props.imagen+'.webp')}
                                        className="img-fluid rounded"
                                        width={50}
                                        height={70}
                                        alt={props.nombre+" Skill Card Mu Online"}
                                        onError={(e) => {
                                            console.error("Error loading image, please contact admin to fix it:", e);
                                    }}/>
                                </div>
                            ):(<></>)
                        }
                        <div className="col-sm-9 mt-2">
                            <div className="row">
                            {
                                props.options.map((opt, index) => {return (
                                    <div key={index} className="col-12">
                                        <div className="d-flex justify-content-center align-items-center h-100">
                                            <p className="text-options-ability-skill-card align-self-center">{opt.nombre}</p>
                                        </div>
                                    </div>
                                )})
                            }
                            </div>
                        </div>
                        <div className="col-12 mt-2">
                            <div className="d-flex justify-content-center align-items-center h-100">
                                <p className="text-descripcion-ability-card align-self-center">{props.description}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <ModalAbilityCards nombre={props.nombre} equipado={props.equipado} imagen={props.imagen} imagenFirebase={props.imagenFirebase} description={props.description} extrainfo={props.extrainfo} options={props.options} tipocard={props.tipocard} />
                    </div>
                </div>
            </div>
        </div>
    )
}

function CardAbilityOptionCard(props) {
    return (
        <div className="col mb-4 card-items">
            <div className="card card-bg-sinhover h-100">
                <div className="row">
                    <div className="col-12">
                        <h2 className="ability-card-title"><BadgeAbilityBook texto={props.colorcard} tipo={props.tipocard}/> {props.nombre}</h2>
                    </div>
                </div>
                <div className="card-body pt-0">
                    <div className="row">
                        {
                            props.imagen !== "" ? (
                                <div className="col-sm-3 text-center m-auto">
                                    <img
                                        loading="lazy"
                                        src={props.imagenFirebase || require('../../assets/charactersinformation/abilityenhancementcore/optioncard/'+props.tipolevel+'/'+props.imagen+'.webp')}
                                        className="img-fluid rounded"
                                        width={50}
                                        height={70}
                                        alt={props.nombre+" Option Card Mu Online"}
                                        onError={(e) => {
                                            console.error("Error loading image, please contact admin to fix it:", e);
                                    }}/>
                                </div>
                            ):(<></>)
                        }
                        {
                            props.options.map((opt, index) => {return (
                                <div key={index} className="col-sm-9">
                                    <div className="d-flex justify-content-center align-items-center h-100">
                                        <p className="text-options-ability-card align-self-center">{opt.nombre}</p>
                                    </div>
                                </div>
                            )})
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <ModalAbilityCards nombre={props.nombre} equipado={props.equipado} imagen={props.imagen} imagenFirebase={props.imagenFirebase} description={props.description} extrainfo={props.extrainfo} options={props.options} tipocard={props.tipocard} colorcard={props.colorcard} />
                    </div>
                </div>
            </div>
        </div>
    )
}

function CardAbilityEliteCard(props) {
    return (
        <div className="col mb-4 card-items">
            <div className="card card-bg-sinhover h-100">
                <div className="row">
                    <div className="col-12">
                        <h2 className="ability-card-title"><BadgeAbilityBook texto={props.colorcard} tipo={props.tipocard}/> {props.nombre}</h2>
                    </div>
                </div>
                <div className="card-body pt-0">
                    <div className="row">
                        {
                            props.imagen !== "" ? (
                                <div className="col-sm-3 text-center m-auto">
                                    <img
                                        loading="lazy"
                                        src={props.imagenFirebase || require('../../assets/charactersinformation/abilityenhancementcore/elitecard/'+props.imagen+'.webp')}
                                        className="img-fluid rounded"
                                        width={50}
                                        height={70}
                                        alt={props.nombre+" Elite Card Mu Online"}
                                        onError={(e) => {
                                            console.error("Error loading image, please contact admin to fix it:", e);
                                    }}/>
                                </div>
                            ):(<></>)
                        }
                        {
                            props.options.map((opt, index) => {return (
                                <div key={index} className="col-sm-9">
                                    <div className="d-flex justify-content-center align-items-center h-100">
                                        <p className="text-options-ability-card align-self-center">{opt.nombre}</p>
                                    </div>
                                </div>
                            )})
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <ModalAbilityCards nombre={props.nombre} equipado={props.equipado} imagen={props.imagen} imagenFirebase={props.imagenFirebase} description={props.description} extrainfo={props.extrainfo} options={props.options} tipocard={props.tipocard} colorcard={props.colorcard} />
                    </div>
                </div>
            </div>
        </div>
    )
}

function CardGuardian(props) {
    return (
        <div className="col mb-4 card-items">
            <div className="card card-bg-sinhover h-100">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="card-title">{props.nombre}</h2>
                        </div>
                        <div className="col-12">
                            <div className="d-flex justify-content-center">
                                <img
                                    loading="lazy"
                                    src={props.imagenFirebase}
                                    className="img-fluid"
                                    width={207}
                                    height={136}
                                    alt={props.nombre+" Guardian Mu Online"}
                                    onError={(e) => {
                                        console.error("Error loading image, please contact admin to fix it:", e);
                                }}/>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="d-flex justify-content-center">
                                <p className="text-required-level"><span className="text-warning">Required Level:</span> {props.reqlevel}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {!props.statsOnly && (
                    <div className="col-12 mb-1">
                        <ModalGuardianGuide nombre={props.nombre} imagenFirebase={props.imagenFirebase} imagenRareFirebase={props.imagenRareFirebase} set={props.set} reqlevel={props.reqlevel} itemOptions={props.itemOptions} addedinseason={props.addedinseason} tipo={props.tipo} />
                    </div>)}
                    <div className="col-12">
                        <ModalGuardian nombre={props.nombre} imagenFirebase={props.imagenFirebase} imagenRareFirebase={props.imagenRareFirebase} set={props.set} reqlevel={props.reqlevel} itemOptions={props.itemOptions} armor={props.armor} defaultOptions={props.defaultOptions} generalOptions={props.generalOptions} eliteMonsterResistanceOptions={props.eliteMonsterResistanceOptions} addedinseason={props.addedinseason} tipo={props.tipo} />
                    </div>
                    {/*props.addedinseason && (
                        <div className="col-12 text-center">
                            <BadgeAddedInSeason addedinseason={props.addedinseason}/>
                        </div>
                    )*/}
                </div>
            </div>
        </div>
    )
}

/**
 * function CardAbility(props) {
    var popoverInfoTypeSkill = (
        <Popover className="popoverInfoTypeSkill">
            <Popover.Header as="h6" className='popoverInfoTypeSkill-header'>Type Skill: {props.typeSkill}</Popover.Header>
            <Popover.Body>
                {
                    props.typeSkill === "default" ? (<p className="text-infoskill">This is an skill that the character has by default when it is created.</p>):(<></>)
                }
                {
                    props.typeSkill === "acquired" ? (
                    <>
                    <p className="text-infoskill">This is a skill that can be used permanently by using skill items such as books, scrolls and orbs.</p>
                    <p className="text-infoskill">You can acquire it by right-clicking the skill item you have in your inventory.</p>
                    <p className="text-infoskill">Acquired skills can be acquired only when conditions such as available levels and stats are satisfied.</p>
                    </>):(<></>)
                }
                {
                    props.typeSkill === "weapon" ? (
                    <>
                    <p className="text-infoskill">This is a skill applied as an option to various weapons.</p>
                    <p className="text-infoskill">This skill can only be used while wearing a weapon to which the skill is applied as an option.</p>
                    </>):(<></>)
                }
                {
                    props.typeSkill === "pet" ? (
                    <>
                    <p className="text-infoskill">This is a skill applied as an option to a pet.</p>
                    <p className="text-infoskill">This skill can only be used while using the pet the skill is applied to as an option.</p>
                    </>):(<></>)
                }
                {
                    props.typeSkill === "quest" ? (<p className="text-infoskill">This skill is activated when completing a quest.</p>):(<></>)
                }
                {
                    props.typeSkill === "masterskill" ? (
                    <>
                    <p className="text-infoskill">A master skill is a skill that a character can acquire after completing the 3rd class change.</p>
                    <p className="text-infoskill">From level 400, you can acquire skills using level points that can be obtained instead of stat points.</p>
                    <p className="text-infoskill">You can check the Master Skill window by pressing the hotkey</p>
                    <p className='text-infoskill-key'>(A key)</p>
                    </>):(<></>)
                }
            </Popover.Body>
        </Popover>
    );

return (
    <div className="col mb-4 card-items">
        <div className="card card-bg">
            <div className="card-body">
                {
                    props.typeSkill === "default" ? (<OverlayTrigger placement="auto" overlay={popoverInfoTypeSkill}><span className="icon-skillinfo">D</span></OverlayTrigger>):(<></>)
                }
                {
                    props.typeSkill === "acquired" ? (<OverlayTrigger placement="auto" overlay={popoverInfoTypeSkill}><span className="icon-skillinfo">A</span></OverlayTrigger>):(<></>)
                }
                {
                    props.typeSkill === "weapon" ? (<OverlayTrigger placement="auto" overlay={popoverInfoTypeSkill}><span className="icon-skillinfo">W</span></OverlayTrigger>):(<></>)
                }
                {
                    props.typeSkill === "pet" ? (<OverlayTrigger placement="auto" overlay={popoverInfoTypeSkill}><span className="icon-skillinfo">P</span></OverlayTrigger>):(<></>)
                }
                {
                    props.typeSkill === "quest" ? (<OverlayTrigger placement="auto" overlay={popoverInfoTypeSkill}><span className="icon-skillinfo">Q</span></OverlayTrigger>):(<></>)
                }
                {
                    props.typeSkill === "masterskill" ? (<OverlayTrigger placement="auto" overlay={popoverInfoTypeSkill}><span className="icon-skillinfo">M</span></OverlayTrigger>):(<></>)
                }
                <div className="row">
                    <div className="col-12">
                        <h2 className="card-title">{props.nombre}</h2>
                    </div>
                    <div className="col-2">
                        <div className="d-flex justify-content-center">
                            {
                                props.imagen !== "" ? (
                                    <img loading="lazy" src={require('../../assets/skills/'+props.imagen+'.webp')} className="img-fluid rounded" alt={props.nombre+" Mu Online"}/>
                                ):(<></>)
                            }
                        </div>
                    </div>
                    <div className="col-10">
                        <p className="text-descripcion">{props.descripcion}</p>
                    </div>
                    {props.castlesiege ? (<div className="col-12"><p className="text-infocastlesiege">Only becomes active for use in Castle Siege</p></div>):(<></>)}
                </div>
            </div>
        </div>
    </div>
)
}
 */

export { CardMuHome, CardContacto, CardShortcuts, CardCharactersHome, CardMap, CardSubMap, CardMapEx, CardMonster, CardMonsterSpots, CardSetItem, CardWeapons, CardWings, CardRingPendant, CardPentagram, CardErrtels, CardJewels, CardMuun, CardArtifacts, CardFullArtifact, CardEarrings, CardSets, CardMasterySets, CardAncientSets, CardLuckySets, CardOrbScroll, CardSkill, CardNews, CardNewsMain, CardSkillNews, CardWeaponNews, CardSetNormalNews, CardSetAncientNews, CardSetMasteryNews, CardMonsterNews, CardWingsNews, CardEarringsNews, CarditemGenerico, CardMonsterEvent, CardEvents, CardItemCashShop, CardCommunityBuild, CardCommunityContact, CardWingCore, CardContentCreator, CardContentCreatorContact, CardAbilityExtraCard, CardAbilitySkillCard, CardAbilityOptionCard, CardAbilityEliteCard, CardGuardian}