import { useEffect } from "react";
import './bloquesGoogleAd.css'

function BloqueGoogleAdDisplay() {

    useEffect(() => {

        try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        }
        catch (e) {
        }
    },[]);

    return (
        <div className="row justify-content-center align-content-center">
            <div className="col-12 text-center">
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3848395985601669" crossOrigin="anonymous"></script>
                {/*<!-- AdHorizontal -->*/}
                <ins className="adsbygoogle ann-google-responsive"
                    style={{display: 'block'}}
                    data-ad-client="ca-pub-3848395985601669"
                    data-ad-slot="6402631942"
                    data-ad-format="auto"
                    data-full-width-responsive="true"
                    title="Google advertising"></ins>
            </div>
        </div>
        /*<div className="row justify-content-center align-content-center">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 text-center">
                <>
                    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3848395985601669" crossOrigin="anonymous"></script>
                    <!-- AdHorizontal -->
                    <ins className="adsbygoogle ann-google-responsive"
                        style={{display: 'block'}}
                        data-ad-client="ca-pub-3848395985601669"
                        data-ad-slot="6402631942"
                        data-ad-format="auto"
                        data-full-width-responsive="true"></ins>
                </>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 text-center">
                <>
                    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3848395985601669" crossOrigin="anonymous"></script>
                    <!-- AdHorizontal2 -->
                    <ins className="adsbygoogle ann-google-responsive"
                        style={{display: 'block'}}
                        data-ad-client="ca-pub-3848395985601669"
                        data-ad-slot="6874239386"
                        data-ad-format="auto"
                        data-full-width-responsive="true"></ins>
                </>
            </div>
        </div>*/
    )
}
export default BloqueGoogleAdDisplay