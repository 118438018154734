import React, { useState } from 'react';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import './modal.css';
import { BadgeMasteryTierSinFondo } from '../../elements/badges/badges';

function ModalEarrings({nombre, imagen, imagenFirebase, options, setOptions, tipoMastery, reqLevel, infoStats}) {

    const [show, setShow] = useState(false);

    function handleShow(breakpoint) {
        setShow(true);
    }

    var crearTabla = (infoTabla) => {
        return  <div className="table-responsive-sm text-center">
                    <table className="table table-bordered table-dark table-hover">
                        <thead>
                            <tr>
                                <th scope="col"><small className="text-uppercase">Level</small></th>
                                <th scope="col"><small className="text-uppercase">Elemental Defense</small></th>
                                <th scope="col"><small className="text-uppercase">Attack/Wizardry/Curse</small></th>
                            </tr>
                        </thead>
                        <tbody>
                            {infoTabla?.map((info, index) => {return(
                                <tr key={index}>
                                    <td className="align-middle p-1"><small>{info.Level}</small></td>
                                    <td className="align-middle p-1"><small>{info.ElementalDefense}</small></td>
                                    <td className="align-middle p-1"><small>{info.AttackWizardryCurse}</small></td>
                                </tr>
                            )})}
                        </tbody>
                    </table>
                </div>
    }
    return (
        <>
            <button type="button" className="btn btn-more-info" onClick={() => handleShow()}>More Info</button>
            <Modal size="xl" show={show} onHide={() => setShow(false)} className="modal-general">
                <Modal.Header closeVariant='white' closeButton>
                    <Modal.Title>{nombre}</Modal.Title>
                </Modal.Header>
                <Modal.Body scrollable="true">
                    <div className="row">
                        <div className="col-lg-4 col-xl-3 col-xxl-3 mb-2">
                            <div className="card card-bg-sinhover h-100">
                                <div className="row h-100">
                                    <div className="col-12 d-flex align-items-center justify-content-center m-auto h-100">
                                        <img
                                            loading="lazy"
                                            src={imagenFirebase || require('../../assets/items/earrings/'+imagen)}
                                            className="img-fluid"
                                            width={200}
                                            height={132}
                                            alt={nombre+" Mu Online"}
                                            onError={(e) => {
                                                console.error("Error loading image, please contact admin to fix it:", e);
                                        }}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-xl-9 col-xxl-9">
                            <div className="row">
                                <div className="col-12 mb-2">
                                    <div className="card card-bg-sinhover">
                                        <BadgeMasteryTierSinFondo masteryTier={tipoMastery}/>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xl-6 col-xxl-6 mb-2">
                                    <div className="card card-bg-sinhover h-100">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-12">
                                                    <p className="text-warning text-titulo-option-modal mb-2 mt-2">Options</p>
                                                </div>
                                                <div className="col-12 text-center">
                                                    {
                                                        options.map((option, index) => {return (
                                                            <div key={index} className="col-12">
                                                                <p className="text-option-modal"><span>{option}</span></p>
                                                            </div>
                                                        )})
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xl-6 col-xxl-6">
                                    <div className="row">
                                        <div className="col-12 mb-2">
                                            <div className="card card-bg-sinhover h-100">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <p className="text-option-modal"><span className="text-warning">Required Level:</span> {reqLevel}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 mb-2">
                                            <div className="card card-bg-sinhover h-100">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <p className="text-warning text-titulo-option-modal mb-2 mt-2">Bonus Options</p>
                                                        </div>
                                                        <div className="col-12 text-center">
                                                            {
                                                                setOptions.map((option, index) => {return (
                                                                    <div key={index} className="col-12">
                                                                        <p className="text-option-modal"><span>{option}</span></p>
                                                                    </div>
                                                                )})
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Tabs defaultActiveKey="stats" transition={true} className="mb-3 justify-content-center tabs-page">
                                {
                                    infoStats?.length === 0 ? <></> : (
                                        <Tab eventKey={"stats"} title={"Stats"} className="tab-page">
                                            <div className="row">
                                                <div className="col-12">{crearTabla(infoStats)}</div>
                                            </div>
                                        </Tab>)
                                }
                            </Tabs>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalEarrings