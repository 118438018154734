import { Link } from 'react-router-dom';
import './botones.css';

function BotonGoToHome(props) {
  return (
    <Link to="/">
        <button type="button" className="btn boton-go-to"><i className="bi bi-house-door ms-2"></i> Home</button>
    </Link>
  )
}

function BotonGoTo(props) {
  return (
    <Link to={props.redireccion}>
        <button type="button" className="btn boton-go-to">{props.nombre}<i className="bi bi-box-arrow-up-right ms-2"></i></button>
    </Link>
  )
}

function BotonTotalMuun(props) {
  return (
    <button type="button" className="btn boton-total-muun"><i className="bi bi-info-lg icono-accent me-2"></i>Total Muun: {props.length}</button>
  )
}

function BotonResetearCards(props) {
  return (
    <button type="button" className="btn boton-resetear-card" onClick={props.resetearCards}><i className="bi bi-repeat me-2"></i>Reset Cards</button>
  )
}

function BotonGoToInPage(props) {
  return (
    <a href={props.redireccion}>
      <button type="button" className={`btn boton-go-to-inpage p-1 ${props.inhabilitado ? 'deshabilitado' : ''}`} disabled={props.inhabilitado}>{props.nombre}</button>
    </a>
  )
}

function BotonGoToQuest(props) {
  return (
    <>
      {
        props.redireccion === "" ? (<button type="button" className="btn boton-go-to-quest">{props.nombre}</button>
        ):(
          <>
            <Link to={props.redireccion}>
              <button type="button" className="btn boton-go-to-quest">{props.nombre} <i className="bi bi-box-arrow-up-right icono-accent ms-2"></i></button>
            </Link>
          </>)
      }
    </>
  )
}

function LinkGoToInLine(props) {
  return (
    <Link to={props.redireccion} className="boton-go-to-in-line">{props.nombre} <i className="bi bi-box-arrow-up-right ms-2"></i></Link>
  )
}

function SocialLink(props) {
  return (
    <>
      {
        props.tipo === "youtube" ? (
          <Link to={props.redireccion} className="d-flex align-items-center justify-content-center icon-youtube h-100 w-100" target="_blank" rel="noreferrer"><i className="bi bi-youtube d-flex align-items-center justify-content-center"></i></Link>
        ):(<></>)
      }
      {
        props.tipo === "twitch" ? (
          <Link to={props.redireccion} className="d-flex align-items-center justify-content-center icon-twitch h-100 w-100" target="_blank" rel="noreferrer"><i className="bi bi-twitch d-flex align-items-center justify-content-center"></i></Link>
        ):(<></>)
      }
      {
        props.tipo === "facebook" ? (
          <Link to={props.redireccion} className="d-flex align-items-center justify-content-center icon-facebook h-100 w-100" target="_blank" rel="noreferrer"><i className="bi bi-facebook d-flex align-items-center justify-content-center"></i></Link>
          ):(<></>)
      }
      {
        props.tipo === "kick" ? (
          <Link to={props.redireccion} className="d-flex align-items-center justify-content-center icon-kick h-100 w-100" target="_blank" rel="noreferrer">
            <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-kick">
              <g transform="translate(2 1)"> {/* Ajusta las coordenadas de traslación para centrar */}
                <path fillRule="evenodd" clipRule="evenodd" d="M1 0H9.76711V5.79452H12.589V2.82193H15.5616V0H24.1781V8.61645H21.3561V11.589H18.3836V14.411H21.3561V17.3836H24.1781V26H15.5616V23.1781H12.589V20.2055H9.76711V26H1V0Z" fill="#09D3AC"/>
              </g>
            </svg>
          </Link>
        ):(<></>)
      }
    </>
  )
}

export { BotonGoToHome, BotonGoTo, BotonTotalMuun, BotonResetearCards, BotonGoToInPage, BotonGoToQuest, LinkGoToInLine, SocialLink }