import {TituloPage} from '../elements/titulos/Titulos';
import { Helmet } from 'react-helmet';
import news from '../data/news.json';
import { CardCharactersHome, CardEvents, CardMuHome, CardNews, CardNewsMain } from '../elements/cards/cards';
import BloqueGoogleAdDisplay from '../components/AdsGoogle/BloqueGoogleAdDisplay';
import { Tab, Tabs } from 'react-bootstrap';
import characters from '../data/characters.json';
import events from '../data/events.json';

function Home() {
  const newsMain = news.filter((noticia) => noticia.slotmainpage === 1 );
  const newsMainSlotDos = news.filter((noticia) => noticia.slotmainpage === 2 );
  const newsMainSlotTres = news.filter((noticia) => noticia.slotmainpage === 3 );
  const newsMainSlotCuatro = news.filter((noticia) => noticia.slotmainpage === 4 );
  const newsMainSlotCinco = news.filter((noticia) => noticia.slotmainpage === 5 );

  const imageUrls = newsMain.map((item) => item.imagenMainFirebase).filter(Boolean);

  
  return (
    <>
      <Helmet>
        <title>Mu Online Guide</title>
        <meta 
          name='description'
          content='Mu Online Guide. We are already in season 18 with a new character! "Illusion Jacquard". In season 17 we had 2 new characters, "Kundun Mephis" and "Lemuria". All about the latest update of Mu Online'/>
        <meta
          name='keywords'
          content='illusion knight mu online, new character mu online, news mu online, mu korea, mu online korea, mu online korea news, illusion knight, illusion knight jacquard, jacquard, knight, stats mu, updates mu, mu online updates, mu online weapons, mu online sets, sets mu online, weapons mu online, mu online 2021, mu online 2022, mu online season 17, mu online season 18, mu dk, new mu online, kundun mu online, illusion mu online, server mu season 18, mu online, mu season 16, mu online season 16, mu online season 6 ep 3, mu online korea, mu online guide, mu online swords, muonline guide, mu online wings, mu online alas, mu online season 17, mu season 17 part 2, mu guide, mu season 17, mu season 17 guide, mu online guides, lemuria mu online' />
        {imageUrls.map((url, index) => (
          <link key={index} rel="preload" as="image" href={url} />
        ))}
        <meta
          name='robots'
          content='index, follow' />
      </Helmet>
      <TituloPage titulo={"Guide Mu Online"} />
      <div className="row justify-content-center">
        <div className="col-12">
          <Tabs defaultActiveKey="char-info" transition={false} className="mb-3 tabs-page-separador"><Tab eventKey="char-info" title="Latest News" className="tab-page-separador"></Tab></Tabs>
        </div>
        <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-6 col-xxl-5 mb-2">
          <div className="row">
            {newsMain.map((item, index) => {return ( <CardNewsMain key={index} titulo={item.titulo} imagenMainFirebase={item.imagenMainFirebase} fecha={item.fecha} redirection={item.redirection} seasonpart={item.seasonpart} season={item.season} part={item.part}/>)})}
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-12 col-xxl-12 mb-2">
              <div className="row">
                {newsMainSlotDos.map((item, index) => {return ( <CardNews key={index} titulo={item.titulo} imagenFirebase={item.imagenFirebase} fecha={item.fecha} redirection={item.redirection} seasonpart={item.seasonpart} season={item.season} part={item.part}/>)})}
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-12 col-xxl-12 mb-2">
              <div className="row">
                {newsMainSlotTres.map((item, index) => {return ( <CardNews key={index} titulo={item.titulo} imagenFirebase={item.imagenFirebase} fecha={item.fecha} redirection={item.redirection} seasonpart={item.seasonpart} season={item.season} part={item.part}/>)})}
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 mb-2">
          <div className="row">
            {newsMainSlotCuatro.map((item, index) => {return ( <CardNews key={index} titulo={item.titulo} imagenFirebase={item.imagenFirebase} fecha={item.fecha} redirection={item.redirection} seasonpart={item.seasonpart} season={item.season} part={item.part}/>)})}
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 mb-2">
          <div className="row">
            {newsMainSlotCinco.map((item, index) => {return ( <CardNews key={index} titulo={item.titulo} imagenFirebase={item.imagenFirebase} fecha={item.fecha} redirection={item.redirection} seasonpart={item.seasonpart} season={item.season} part={item.part}/>)})}
          </div>
        </div>
      </div>
      <div className="mt-4 mb-4">
        <BloqueGoogleAdDisplay />
      </div>
      <div className="row mb-4">
        <div className="col-12">
          <Tabs defaultActiveKey="char-info" transition={false} className="mb-3 tabs-page-separador"><Tab eventKey="char-info" title="Events" className="tab-page-separador"></Tab></Tabs>
        </div>
        <div className="col-12">
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-4">
            {
              events.map((evnt, index) => {return (
                  <CardEvents key={index} nombre={evnt.nombre} imagen={evnt.imagen} imagenFirebase={evnt.imagenFirebase} redirection={evnt.redirection} />
              )})
            }
        </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Tabs defaultActiveKey="char-info" transition={false} className="mb-3 tabs-page-separador"><Tab eventKey="char-info" title="Characters" className="tab-page-separador"></Tab></Tabs>
        </div>
      </div>
      <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 row-cols-xxl-6 justify-content-center">
        {
          characters.map((char, index) => {return (
              <CardCharactersHome key={index} nombre={char.nombre} imagen={char.imagen} imagenSmallFirebase={char.imagenSmallFirebase} redirection={char.redirection} />
          )})
        }
      </div>
      <div className="row mt-4">
          <CardMuHome />
      </div>
      <div className="mt-4 mb-4">
        <BloqueGoogleAdDisplay />
      </div>
    </>
  )
}

export default Home