import React, { useContext, useEffect, useState } from "react";
import { auth } from '../firebase/firebaseConfig';

//Contexto Global
const AuthContext = React.createContext();

//Hook para acceder al contexto
const useAuth = () => {
    return useContext(AuthContext);
}

const AuthProvider = ({children}) => {

    const [user, setUser] = useState();
    const [cargando, setCargando] = useState(true);

    useEffect(() => {
        //Comprobar si hay usuario activo
        const cancelarSuscripcion = auth.onAuthStateChanged((user) => {
            setUser(user);
            setCargando(false);
        });

        return cancelarSuscripcion;
    }, []);

    return (
        <AuthContext.Provider value={{user: user}}>
            {!cargando && children}
        </AuthContext.Provider>
    )
}

export {AuthProvider, AuthContext, useAuth};