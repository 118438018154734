import { Link } from "react-router-dom"
import './banners.css';

function TopLargeBannerActivo(props) {
  return (
    <div className="col-12 mb-2">
        <div className="card m-auto background-bann">
            <Link to={props.urlWeb} target="_blank" rel="noreferrer">
                <img src={props.imagenUrl} className="img-fluid slot-imagen imagen-top-large" width={1300} height={140} alt={props.nombre+" Mu Online"}/>
                <img src={props.imagenUrlSmall} className="img-fluid slot-imagen imagen-top-small w-100" width={516} height={144} alt={props.nombre+" Mu Online"}/>
            </Link>
        </div>
    </div>
  )
}

function TopLargeBannerInactivo(props) {
    return (
        <div className="col-12 mb-2">
            <Link to="/contact" className="link-bann">
                <div className="card m-auto background-bann">
                    {
                        props.reservado ? (<p className="slot-text-unavailable">Slot Currently Unavailable</p>
                        ):(
                        <>
                            <p className="slot-text-available">Banner Slot Available</p>
                            <p className="slot-contact-text"><i className="bi bi-envelope"></i> Contact</p>
                        </>)
                    }
                </div>
            </Link>
        </div>
    )
}

function TopBannerActivo(props) {
    return (
      <div className="col mb-2">
        <Link to={props.urlWeb} target="_blank" rel="noreferrer">
            <div className="card background-bann">
                <img src={props.imagenUrl} className="img-fluid slot-imagen" width={516} height={144} alt={props.nombre+" Mu Online"}/>
            </div>
        </Link>
      </div>
    )
  }

function TopBannerInactivo(props) {
    return (
        <div className="col mb-2">
            <Link to="/contact" className="link-bann m-auto">
                <div className="card m-auto background-bann h-100">
                    {
                        props.reservado ? (<p className="slot-text-unavailable">Slot Currently Unavailable</p>
                        ):(
                        <>
                            <div className="row d-flex align-items-center justify-content-center h-100">
                                <div className="col-12"><p className="slot-text-available">Banner Slot Available</p></div>
                                <div className="col-12"><p className="slot-contact-text"><i className="bi bi-envelope"></i> Contact</p></div>
                            </div>
                        </>)
                    }
                </div>
            </Link>
        </div>
    )
}

export {TopLargeBannerActivo, TopLargeBannerInactivo, TopBannerActivo, TopBannerInactivo}