import React from 'react';
import useObtenerBanners from '../hooks/useObtenerBanners';
import {TopLargeBannerActivo, TopLargeBannerInactivo, TopBannerActivo, TopBannerInactivo} from '../elements/banners/banners';

function Banners() {
    const [banners] = useObtenerBanners();

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let fechaActual = year+"/"+month+"/"+date;

    var comprobarFecha = (fechaFin) => {
        if(Date.parse(fechaFin) > Date.parse(fechaActual)){
            return true;
         } else if (Date.parse(fechaFin) === Date.parse(fechaActual)){
            return true;
         } else {
            return false;
        }
    }
    
    //Ordenar array por Slot
    banners.sort((a,b) => a.slot - b.slot);
    return (
        <>
            <div className="row">
                {banners.map((banner, index) => {return (
                    <React.Fragment key={index}>
                        {
                            banner.visible && banner.slot <= 50 ? (
                                <>
                                    {banner.enUso && comprobarFecha(banner.fechaFin) ? (
                                        <TopLargeBannerActivo enUso={banner.enUso} urlWeb={banner.urlWeb} imagenUrl={banner.imagenUrl} nombre={banner.nombre} imagenUrlSmall={banner.imagenUrlSmall} reservado={banner.reservado}/>
                                    ):(
                                        <TopLargeBannerInactivo reservado={banner.reservado}/>
                                    )}
                                </>
                            ):(<></>)
                        }
                    </React.Fragment>
                )})}
            </div>
            <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-3 row-cols-xxl-3 justify-content-center mb-4">
                {banners.map((banner, index) => {return (
                    <React.Fragment key={index}>
                        {
                            banner.visible && banner.slot > 50 ? (
                                <>
                                    {banner.enUso && comprobarFecha(banner.fechaFin) ? (
                                        <TopBannerActivo enUso={banner.enUso} urlWeb={banner.urlWeb} imagenUrl={banner.imagenUrl} nombre={banner.nombre} reservado={banner.reservado}/>
                                    ):(
                                        <TopBannerInactivo reservado={banner.reservado}/>
                                    )}
                                </>
                            ):(<></>)
                        }
                    </React.Fragment>
                )})}
            </div>
        </>
    )
}

export default Banners;