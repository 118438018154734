import React, { useState } from 'react';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import { BadgeAbilityBook } from '../../elements/badges/badges';
import './modal.css';

function ModalAbilityCards({nombre, equipado, imagen, imagenFirebase, description, extrainfo, options, tipocard, colorcard}) {

    const [show, setShow] = useState(false);

    function handleShow(breakpoint) {
        setShow(true);
    }

    const crearTabla = (options) => {
        const niveles = options.reduce((acc, option) => {
          return [...acc, ...option.leveloption.map((level, index) => index)];
        }, []).filter((value, index, self) => self.indexOf(value) === index);
      
        return (
          <div className="table-responsive-sm text-center">
            <table className="table table-bordered table-dark table-hover">
              <thead>
                <tr>
                  <th scope="col"><small className="text-uppercase">Level</small></th>
                  {options?.map((info, index) => (
                    <th key={index} scope="col"><small className="text-uppercase">{info.nombre}</small></th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {niveles.map((level) => (
                  <tr key={level}>
                    <td className="align-middle p-1"><small>{level}</small></td>
                    {options?.map((info, index) => (
                      <td key={index} className="align-middle p-1">
                        <small>{info.leveloption[level] || '-'}</small>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
    };

    return (
        <>
            <button type="button" className="btn btn-more-info" onClick={() => handleShow()}>More Info</button>
            <Modal size="lg" show={show} onHide={() => setShow(false)} className="modal-general">
                <Modal.Header closeVariant='white' closeButton>
                    <Modal.Title>
                        {tipocard === "extra" ? (<BadgeAbilityBook texto={"Extra Damage"} tipo={tipocard}/>): null}
                        {tipocard === "skill" ? (<BadgeAbilityBook texto={"Skill"} tipo={tipocard}/>): null}
                        {tipocard === "option" || tipocard === "elite" ? (<BadgeAbilityBook texto={colorcard} tipo={tipocard}/>): null}
                    
                    {nombre}</Modal.Title>
                </Modal.Header>
                <Modal.Body scrollable="true">
                    <div className="row">
                        <div className="col-lg-4 col-xl-3 col-xxl-3 mb-2">
                            <div className="card card-bg-sinhover h-100">
                                <div className="row h-100">
                                    <div className="col-12 d-flex align-items-center justify-content-center m-auto h-100">
                                        <img
                                            loading="lazy"
                                            src={imagenFirebase || require('../../assets/charactersinformation/abilityenhancementcore/skillcard/'+imagen+'.webp')}
                                            className="img-fluid rounded"
                                            width={50}
                                            height={70}
                                            alt={nombre+" Skill Card Mu Online"}
                                            onError={(e) => {
                                                console.error("Error loading image, please contact admin to fix it:", e);
                                        }}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {description && (
                            <div className="col-lg-8 col-xl-9 col-xxl-9 mb-2">
                                <div className="card card-bg-sinhover h-100">
                                    <div className="card-body">
                                        <p className="text-descripcion-ability-card-modal">{description}</p>
                                    </div>
                                </div>
                            </div>
                        )}
                        {extrainfo && (
                            <div className="col-12 mb-2">
                                <div className="card card-bg-sinhover h-100">
                                    <div className="card-body">
                                        <p className="text-extrainfo-ability-card-modal">{extrainfo}</p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Tabs defaultActiveKey="stats" transition={true} className="mb-3 justify-content-center tabs-page">
                                {
                                    options?.length === 2 ? <></> : (
                                        <Tab eventKey={"stats"} title={"Options & Stats"} className="tab-page">
                                            <div className="row">
                                                <div className="col-12">{crearTabla(options)}</div>
                                            </div>
                                        </Tab>)
                                }
                            </Tabs>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalAbilityCards