import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import NotFound from '../pages/NotFound';
import ProtectedRoute from "./ProtectedRoute";

const Login = lazy(() => import('../pages/login/Login'));
const DashboardAdmin = lazy(() => import('../pages/dashboard/DashboardAdmin'));
/** NEWS */
const News = lazy(() => import('../pages/news/News'));
/** CONTENT CREATORS */
const ContentCreators = lazy(() => import('../pages/contentcreators/ContentCreators'));
/** GAME SYSTEM */
const BasicInfo  = lazy(() => import('../pages/gamesystem/BasicInfo'));
const TradePersonalStore  = lazy(() => import('../pages/gamesystem/TradePersonalStore'));
const Cashshop = lazy(() => import('../pages/gamesystem/Cashshop'));
/** MAPS */
const Maps = lazy(() => import('../pages/Maps'));
/** GUIDES */
const QuestSebinaThePriest = lazy(() => import('../pages/guides/questclasschange/QuestSebinaThePriest'));
const QuestMarlon = lazy(() => import('../pages/guides/questclasschange/QuestMarlon'));
const QuestApostleDevin = lazy(() => import('../pages/guides/questclasschange/QuestApostleDevin'));
const QuestSent = lazy(() => import('../pages/guides/questclasschange/QuestSent'));
const QuestEunice = lazy(() => import('../pages/guides/questclasschange/QuestEunice'));
const CreateWingsLevelOne = lazy(() => import('../pages/guides/howtocreatewings/CreateWingsLevelOne'));
const CreateWingsLevelTwo = lazy(() => import('../pages/guides/howtocreatewings/CreateWingsLevelTwo'));
const CreateWingsLevelMonster = lazy(() => import('../pages/guides/howtocreatewings/CreateWingsLevelMonster'));
const CreateWingsLevelThree = lazy(() => import('../pages/guides/howtocreatewings/CreateWingsLevelThree'));
const CreateWingsLevelFour = lazy(() => import('../pages/guides/howtocreatewings/CreateWingsLevelFour'));
const ElementalSystem = lazy(() => import('../pages/guides/elementalsystem/ElementalSystem'));
const PentagramsGuide = lazy(() => import('../pages/guides/elementalsystem/PentagramsGuide'));
const ErrtelsGuide = lazy(() => import('../pages/guides/elementalsystem/ErrtelsGuide'));
const MasteryPentagramsGuide = lazy(() => import('../pages/guides/elementalsystem/MasteryPentagramsGuide'));
const MasteryErrtelsGuide = lazy(() => import('../pages/guides/elementalsystem/MasteryErrtelsGuide'));
const PandorasJewelsSystem = lazy(() => import('../pages/guides/pandorasjewelssystem/PandorasJewelsSystem'));
const GuardianSystem = lazy(() => import('../pages/guides/guardiansystem/GuardianSystem'));
const ArtifactSystem = lazy(() => import('../pages/guides/artifactsystem/ArtifactSystem'));
/** CHARACTERS */
const DarkWizard = lazy(() => import('../pages/characters/DarkWizard'));
const DarkKnight = lazy(() => import('../pages/characters/DarkKnight'));
const FairyElf = lazy(() => import('../pages/characters/FairyElf'));
const MagicGladiator = lazy(() => import('../pages/characters/MagicGladiator'));
const DarkLord = lazy(() => import('../pages/characters/DarkLord'));
const Summoner = lazy(() => import('../pages/characters/Summoner'));
const RageFighter = lazy(() => import('../pages/characters/RageFighter'));
const GrowLancer = lazy(() => import('../pages/characters/GrowLancer'));
const RuneWizard = lazy(() => import('../pages/characters/RuneWizard'));
const Slayer = lazy(() => import('../pages/characters/Slayer'));
const GunCrusher = lazy(() => import('../pages/characters/GunCrusher'));
const KundunMephis = lazy(() => import('../pages/characters/KundunMephis'));
const Lemuria = lazy(() => import('../pages/characters/Lemuria'));
const IllusionKnight = lazy(() => import('../pages/characters/IllusionKnight'));
/** EVENTS */
const BloodCastle = lazy(() => import('../pages/events/bloodcastle/BloodCastle'));
const DevilSquare = lazy(() => import('../pages/events/devilsquare/DevilSquare'));
const ChaosCastle = lazy(() => import('../pages/events/chaoscastle/ChaosCastle'));
const IllusionTemple = lazy(() => import('../pages/events/illusiontemple/IllusionTemple'));
const Doppelganger = lazy(() => import('../pages/events/doppelganger/Doppelganger'));
const FortressImperialGuardVarka = lazy(() => import('../pages/events/fortressimperialguardvarka/FortressImperialGuardVarka'));
const MazeofDimensions = lazy(() => import('../pages/events/mazeofdimensions/MazeofDimensions'));
const BossBattleTogether = lazy(() => import('../pages/events/bossbattletogether/BossBattleTogether'));
const ArkaWar = lazy(() => import('../pages/events/arkawar/Arkawar'));
/** ITEMS */
const Helm = lazy(() => import('../pages/items/set-items/Helm'));
const Armor = lazy(() => import('../pages/items/set-items/Armor'));
const Gloves = lazy(() => import('../pages/items/set-items/Gloves'));
const Pants = lazy(() => import('../pages/items/set-items/Pants'));
const Boots = lazy(() => import('../pages/items/set-items/Boots'));
const Axe = lazy(() => import('../pages/items/weapons/Axe'));
const Blade = lazy(() => import('../pages/items/weapons/Blade'));
const BookOfTheOtherWorld = lazy(() => import('../pages/items/weapons/BookOfTheOtherWorld'));
const Bow = lazy(() => import('../pages/items/weapons/Bow'));
const Claws = lazy(() => import('../pages/items/weapons/Claws'));
const Crossbow = lazy(() => import('../pages/items/weapons/Crossbow'));
const Lance = lazy(() => import('../pages/items/weapons/Lance'));
const Mace = lazy(() => import('../pages/items/weapons/Mace'));
const MagicBook = lazy(() => import('../pages/items/weapons/MagicBook'));
const MagicGun = lazy(() => import('../pages/items/weapons/MagicGun'));
const Orb = lazy(() => import('../pages/items/weapons/Orb'));
const Quiver = lazy(() => import('../pages/items/weapons/Quiver'));
const RuneMace = lazy(() => import('../pages/items/weapons/RuneMace'));
const Scepter = lazy(() => import('../pages/items/weapons/Scepter'));
const Shield = lazy(() => import('../pages/items/weapons/Shield'));
const ShortSword = lazy(() => import('../pages/items/weapons/ShortSword'));
const Spear = lazy(() => import('../pages/items/weapons/Spear'));
const Staff = lazy(() => import('../pages/items/weapons/Staff'));
const Stick = lazy(() => import('../pages/items/weapons/Stick'));
const Sword = lazy(() => import('../pages/items/weapons/Sword'));
const Wings = lazy(() => import('../pages/items/Wings'));
const Pentagram = lazy(() => import('../pages/items/Pentagram'));
const Errtels = lazy(() => import('../pages/items/Errtels'));
const RingPendant = lazy(() => import('../pages/items/RingPendant'));
const Jewels = lazy(() => import('../pages/items/Jewels'));
const Muun = lazy(() => import('../pages/items/Muun'));
const Artifacts = lazy(() => import('../pages/items/Artifacts'));
const Earring = lazy(() => import('../pages/items/Earring'));
const AncientSet = lazy(() => import('../pages/items/AncientSet'));
const LuckySet = lazy(() => import('../pages/items/LuckySet'));
const MasterySet = lazy(() => import('../pages/items/MasterySet'));
const OrbScroll = lazy(() => import('../pages/items/OrbScroll'));
/** TOOLS */
const MuRoomyHelper = lazy(() => import('../pages/herramientas/muRoomyHelper/MuRoomyHelper'));
const MazeofDimensionsHelperSeason16 = lazy(() => import('../pages/herramientas/mazeofdimensionshelper/versions/version3/MazeofDimensionsHelperSeason16'));
const CalculadoraExperiencia = lazy(() => import('../pages/herramientas/calculadoraexperiencia/CalculadoraExperiencia'));
/** CONTACT */
const Contact = lazy(() => import('../pages/Contact'));
/** LEGAL */
const CookiePolicy = lazy(() => import('../pages/legal/CookiePolicy'));
const PrivacyPolicy = lazy(() => import('../pages/legal/PrivacyPolicy'));
/* Season 12 */
const TresMasteryArmor = lazy(() => import('../pages/news/season12/TresMasteryArmor'));
const DosMasteryWeapon = lazy(() => import('../pages/news/season12/DosMasteryWeapon'));
const NewMapNixiesLake = lazy(() => import('../pages/news/season12/NewMapNixiesLake'));
const NixiesLakeBossZone = lazy(() => import('../pages/news/season12/NixiesLakeBossZone'));
const MazeOfDimensions = lazy(() => import('../pages/news/season12/MazeOfDimensions'));
const NewItemsAndChangesS12 = lazy(() => import('../pages/news/season12/NewItemsAndChangesS12'));
const NewMasterSkillTree4thClass = lazy(() => import('../pages/news/season12/NewMasterSkillTree4thClass'));
const MasterSkillTreeRenewal = lazy(() => import('../pages/news/season12/MasterSkillTreeRenewal'));
const FourthClassChangeQuest = lazy(() => import('../pages/news/season12/FourthClassChangeQuest'));
const PlayImprovements12part2 = lazy(() => import('../pages/news/season12/PlayImprovements12part2'));
const NewMapDeepDungeon = lazy(() => import('../pages/news/season12/NewMapDeepDungeon'));
const NewQuestSystemGuideQuestAdded = lazy(() => import('../pages/news/season12/NewQuestSystemGuideQuestAdded'));
const NewMiniGameJewelBingo = lazy(() => import('../pages/news/season12/NewMiniGameJewelBingo'));
/* Season 13 */
const PlayImprovementNewWingCraftingMethod = lazy(() => import('../pages/news/season13/PlayImprovementNewWingCraftingMethod'));
const SkillEnhancementTree2ndPatch = lazy(() => import('../pages/news/season13/SkillEnhancementTree2ndPatch'));
const NewSkillAndChangesInSkills = lazy(() => import('../pages/news/season13/NewSkillAndChangesInSkills'));
const TresMasteryWeaponCuatroMasteryArmor = lazy(() => import('../pages/news/season13/TresMasteryWeaponCuatroMasteryArmor'));
const NewMapSwampofDarkness = lazy(() => import('../pages/news/season13/NewMapSwampofDarkness'));
const NewWingsLevel4 = lazy(() => import('../pages/news/season13/NewWingsLevel4'));
const SwampofDarknessBossZone = lazy(() => import('../pages/news/season13/SwampofDarknessBossZone'));
const MazeofDimensionsRenewal = lazy(() => import('../pages/news/season13/MazeofDimensionsRenewal'));
const PlayImprovementAndMaximumLevelExpansion13part21 = lazy(() => import('../pages/news/season13/PlayImprovementAndMaximumLevelExpansion13part21'));
const NewAccesoryFirstMasteryEarrings = lazy(() => import('../pages/news/season13/NewAccesoryFirstMasteryEarrings'));
const AddedAndImprovedShortTermBuffItems = lazy(() => import('../pages/news/season13/AddedAndImprovedShortTermBuffItems'));
const RageFighterAbsoluteWeaponAdded = lazy(() => import('../pages/news/season13/RageFighterAbsoluteWeaponAdded'));
/* Season 14 */
const NewCharacterRuneWizard = lazy(() => import('../pages/news/season14/NewCharacterRuneWizard'));
const NewCharacterRuneWizardBuffSkill = lazy(() => import('../pages/news/season14/NewCharacterRuneWizardBuffSkill'));
const PlayImprovementSeason14Part11 = lazy(() => import('../pages/news/season14/PlayImprovementSeason14Part11'));
const RuneWizard4thClassSkillEnhancementTree = lazy(() => import('../pages/news/season14/RuneWizard4thClassSkillEnhancementTree'));
const RuneWizard4thWingAnd3rdMasteryItems = lazy(() => import('../pages/news/season14/RuneWizard4thWingAnd3rdMasteryItems'));
const CuatroMasteryWeaponCincoMasteryArmor = lazy(() => import('../pages/news/season14/CuatroMasteryWeaponCincoMasteryArmor'));
const NewMapCuberaMine = lazy(() => import('../pages/news/season14/NewMapCuberaMine'));
const GrowLancerArchangelAbsoluteWeapon = lazy(() => import('../pages/news/season14/GrowLancerArchangelAbsoluteWeapon'));
const MonsterSoulConversion = lazy(() => import('../pages/news/season14/MonsterSoulConversion'));
const RuneWizardMasteryItemAdded = lazy(() => import('../pages/news/season14/RuneWizardMasteryItemAdded'));
const NewMapAtlansAbbys = lazy(() => import('../pages/news/season14/NewMapAtlansAbbys'));
const MaximumLevelExpansionSeasonRewardsS1421 = lazy(() => import('../pages/news/season14/MaximumLevelExpansionSeasonRewardsS1421'));
const SecondMasteryEarrings = lazy(() => import('../pages/news/season14/SecondMasteryEarrings'));
const SummonerRenewal = lazy(() => import('../pages/news/season14/SummonerRenewal'));
const GuardianSystemFirstGuardianAtlansAbbysEliteZone = lazy(() => import('../pages/news/season14/GuardianSystemFirstGuardianAtlansAbbysEliteZone'));
/* Season 15 */
const NewCharacterSlayer = lazy(() => import('../pages/news/season15/NewCharacterSlayer'));
const NewsItems5masteryweapons6masteryarmor = lazy(() => import('../pages/news/season15/NewsItems5masteryweapons6masteryarmor'));
const NewMapScorchedCanyon = lazy(() => import('../pages/news/season15/NewMapScorchedCanyon'));
const PlayImprovementAndMaximumLevelExpansion = lazy(() => import('../pages/news/season15/PlayImprovementAndMaximumLevelExpansion'));
const AddedSlayerEquipmentAndSkill = lazy(() => import('../pages/news/season15/AddedSlayerEquipmentAndSkill'));
const SlayerSkillEnhancementTree = lazy(() => import('../pages/news/season15/SlayerSkillEnhancementTree'));
const SkillEnhancementTreeImprovement = lazy(() => import('../pages/news/season15/SkillEnhancementTreeImprovement'));
const ScorchedCanyonEliteZone = lazy(() => import('../pages/news/season15/ScorchedCanyonEliteZone'));
const ChangesInAdmissionToEvents = lazy(() => import('../pages/news/season15/ChangesInAdmissionToEvents'));
const Slayer4thWingsAndEquipmentAdded = lazy(() => import('../pages/news/season15/Slayer4thWingsAndEquipmentAdded'));
const ExcellentSocketNecklace = lazy(() => import('../pages/news/season15/ExcellentSocketNecklace'));
const PlayImprovementAndMaximumLevelExpansionSeason15PartDosUno = lazy(() => import('../pages/news/season15/PlayImprovementAndMaximumLevelExpansionSeason15PartDosUno'));
const NewMapCrimsonIcarus = lazy(() => import('../pages/news/season15/NewMapCrimsonIcarus'));
const ThirdMasteryEarrings = lazy(() => import('../pages/news/season15/ThirdMasteryEarrings'));
const SecondGuardianIceDragonCrimsonIcarusEliteZone = lazy(() => import('../pages/news/season15/SecondGuardianIceDragonCrimsonIcarusEliteZone'));
const ImprovementsLifeIcons = lazy(() => import('../pages/news/season15/ImprovementsLifeIcons'));
const PersonalStoreRenewal = lazy(() => import('../pages/news/season15/PersonalStoreRenewal'));
const HelperPlusPartyFunctionImprovement = lazy(() => import('../pages/news/season15/HelperPlusPartyFunctionImprovement'));
const DragonKnightRenewal = lazy(() => import('../pages/news/season15/DragonKnightRenewal'));
const Slayer6thMasteryArmorAdded = lazy(() => import('../pages/news/season15/Slayer6thMasteryArmorAdded'));
/* Season 16 */
const NewCharacterGunCrusher = lazy(() => import('../pages/news/season16/NewCharacterGunCrusher'));
const MaximumLevelExpansionSeasonRewardsS1612 = lazy(() => import('../pages/news/season16/MaximumLevelExpansionSeasonRewardsS1612'));
const NewMapTempleOfArnil = lazy(() => import('../pages/news/season16/NewMapTempleOfArnil'));
const MasteryTalismanOfChaosAssemblyBound = lazy(() => import('../pages/news/season16/MasteryTalismanOfChaosAssemblyBound'));
const NewsItems6masteryweapons7masteryarmor = lazy(() => import('../pages/news/season16/NewsItems6masteryweapons7masteryarmor'));
const GunCrusherSkillEnhancementTreeItemAdded = lazy(() => import('../pages/news/season16/GunCrusherSkillEnhancementTreeItemAdded'));
const TempleOfArnilEliteZone = lazy(() => import('../pages/news/season16/TempleOfArnilEliteZone'));
const AddedGunCrusherEquipment = lazy(() => import('../pages/news/season16/AddedGunCrusherEquipment'));
const ElementalSystemUpdate = lazy(() => import('../pages/news/season16/ElementalSystemUpdate'));
const MazeofDimensionsImprovements = lazy(() => import('../pages/news/season16/MazeofDimensionsImprovements'));
const JewelofHarmonyRenewal = lazy(() => import('../pages/news/season16/JewelofHarmonyRenewal'));
const PlayImprovementUnoTres = lazy(() => import('../pages/news/season16/PlayImprovementUnoTres'));
const MaximumLevelExpansionSeasonRewardsS1621 = lazy(() => import('../pages/news/season16/MaximumLevelExpansionSeasonRewardsS1621'));
const NewMapGrayAida = lazy(() => import('../pages/news/season16/NewMapGrayAida'));
const FourthMasteryEarrings = lazy(() => import('../pages/news/season16/FourthMasteryEarrings'));
const SeasonDieciseisMagicGladiatorRenewal = lazy(() => import('../pages/news/season16/SeasonDieciseisMagicGladiatorRenewal'));
const GunCrusherNewBuffSkill = lazy(() => import('../pages/news/season16/GunCrusherNewBuffSkill'));
const ThirdGuardianFierceLionGrayAidaEliteZone = lazy(() => import('../pages/news/season16/ThirdGuardianFierceLionGrayAidaEliteZone'));
const MountItemsAndDisassemblyFunctionImprovements = lazy(() => import('../pages/news/season16/MountItemsAndDisassemblyFunctionImprovements'));
/* Season 17 */
const NewCharactersKundunMephisLemuria = lazy(() => import('../pages/news/season17/NewCharactersKundunMephisLemuria'));
const PlayImprovement = lazy(() => import('../pages/news/season17/PlayImprovement'));
const SietehMasteryWeaponOchohMasteryArmor = lazy(() => import('../pages/news/season17/SietehMasteryWeaponOchohMasteryArmor'));
const NewCharactersKundunLemuriaItemAdded = lazy(() => import('../pages/news/season17/NewCharactersKundunLemuriaItemAdded'));
const NewMapBurningKethotumOldKethotum = lazy(() => import('../pages/news/season17/NewMapBurningKethotumOldKethotum'));
const CharactersMaxLevelExpansionAndSeasonReward = lazy(() => import('../pages/news/season17/CharactersMaxLevelExpansionAndSeasonReward'));
const AddedNewMapsAndRebalancingSomeHuntingGrounds = lazy(() => import('../pages/news/season17/AddedNewMapsAndRebalancingSomeHuntingGrounds'));
const NewCharactersFourthWingsEquipmenyItems = lazy(() => import('../pages/news/season17/NewCharactersFourthWingsEquipmenyItems'));
const NewMapKantruUnderground = lazy(() => import('../pages/news/season17/NewMapKantruUnderground'));
const FifthMasteryEarrings = lazy(() => import('../pages/news/season17/FifthMasteryEarrings'));
const MaximumLevelExpansionSeasonRewardsParteDosUno = lazy(() => import('../pages/news/season17/MaximumLevelExpansionSeasonRewardsParteDosUno'));
const PlayImprovementParteDosDos = lazy(() => import('../pages/news/season17/PlayImprovementParteDosDos'));
const FourthGuardianShiningTailKantruElite = lazy(() => import('../pages/news/season17/FourthGuardianShiningTailKantruElite'));
const FairyElfRenewal = lazy(() => import('../pages/news/season17/FairyElfRenewal'));
/* Season 18 */
const NewCharacterIllusionKnightPreUpdate = lazy(() => import('../pages/news/season18/NewCharacterIllusionKnightPreUpdate'));
const NewCharacterIllusionKnight = lazy(() => import('../pages/news/season18/NewCharacterIllusionKnight'));
const CharactersMaxLevelExpansionAndSeasonRewardParteUnoDos = lazy(() => import('../pages/news/season18/CharactersMaxLevelExpansionAndSeasonRewardParteUnoDos'));
const NewMapIgnisVolcano = lazy(() => import('../pages/news/season18/NewMapIgnisVolcano'));
const AddedIllusionKnightEquipment = lazy(() => import('../pages/news/season18/AddedIllusionKnightEquipment'));
const OchoMasteryWeaponNueveMasteryArmor = lazy(() => import('../pages/news/season18/OchoMasteryWeaponNueveMasteryArmor'));
const IgnisVolcanoEliteZone = lazy(() => import('../pages/news/season18/IgnisVolcanoEliteZone'));
const FourthCloakAndEquipmentItems = lazy(() => import('../pages/news/season18/FourthCloakAndEquipmentItems'));
const AllTogetherBossBattle = lazy(() => import('../pages/news/season18/AllTogetherBossBattle'));
const VarkaEventMapRenewal = lazy(() => import('../pages/news/season18/VarkaEventMapRenewal'));
const NewEquipmentArtifacts = lazy(() => import('../pages/news/season18/NewEquipmentArtifacts'));
const CharactersMaxLevelExpansionAndSeasonRewardSeason18ParteDosUno = lazy(() => import('../pages/news/season18/CharactersMaxLevelExpansionAndSeasonRewardSeason18ParteDosUno'));
const NewMapBloodyTarkan = lazy(() => import('../pages/news/season18/NewMapBloodyTarkan'));
const SixthMasteryEarrings = lazy(() => import('../pages/news/season18/SixthMasteryEarrings'));
const SeasonPassEvents = lazy(() => import('../pages/news/season18/SeasonPassEvents'));
const TempestMuun = lazy(() => import('../pages/news/season18/TempestMuun'));
const WingCombinationImprovement = lazy(() => import('../pages/news/season18/WingCombinationImprovement'));
const CharacterRenewalSeason18 = lazy(() => import('../pages/news/season18/CharacterRenewalSeason18'));
const BloodyTarkanEliteZone = lazy(() => import('../pages/news/season18/BloodyTarkanEliteZone'));
const FifthGuardianUr = lazy(() => import('../pages/news/season18/FifthGuardianUr'));
/* Season 19 */
const GrowLancerRenewalSeason19 = lazy(() => import('../pages/news/season19/GrowLancerRenewalSeason19'));
const NewMapTormentaIsland = lazy(() => import('../pages/news/season19/NewMapTormentaIsland'));
const CharactersMaxLevelExpansionAndSeasonRewardSeason19ParteUnoDos = lazy(() => import('../pages/news/season19/CharactersMaxLevelExpansionAndSeasonRewardSeason19ParteUnoDos'));
const NueveMasteryWeaponDiezMasteryArmor = lazy(() => import('../pages/news/season19/NueveMasteryWeaponDiezMasteryArmor'));
const TormentaIslandEliteZone = lazy(() => import('../pages/news/season19/TormentaIslandEliteZone'));
const FifthWingsAdded = lazy(() => import('../pages/news/season19/FifthWingsAdded'));
const FifthClassChangeAdded = lazy(() => import('../pages/news/season19/FifthClassChangeAdded'));
const PlayImprovementsParteUnoTres = lazy(() => import('../pages/news/season19/PlayImprovementsParteUnoTres'));
const AbilityEnhancementTreeAdded = lazy(() => import('../pages/news/season19/AbilityEnhancementTreeAdded'));
const DarkLordRenewalSeason19 = lazy(() => import('../pages/news/season19/DarkLordRenewalSeason19'));
const SeventhMasteryEarrings = lazy(() => import('../pages/news/season19/SeventhMasteryEarrings'));
const NewMapTwistedKarutan = lazy(() => import('../pages/news/season19/NewMapTwistedKarutan'));
const CharactersMaxLevelExpansionAndSeasonRewardSeason19ParteDosDos = lazy(() => import('../pages/news/season19/CharactersMaxLevelExpansionAndSeasonRewardSeason19ParteDosDos'));
const ImprovementAbilityEnhancementCoreSkillCardConversionCombination = lazy(() => import('../pages/news/season19/ImprovementAbilityEnhancementCoreSkillCardConversionCombination'));
const SixthGuardianLeviathan = lazy(() => import('../pages/news/season19/SixthGuardianLeviathan'));
const TwistedKarutanEliteZone = lazy(() => import('../pages/news/season19/TwistedKarutanEliteZone'));
const SkillEnhancementTreeImproved4thStatOption = lazy(() => import('../pages/news/season19/SkillEnhancementTreeImproved4thStatOption'));
const EventMapAndBossBattleIntegratedUI = lazy(() => import('../pages/news/season19/EventMapAndBossBattleIntegratedUI'));
const ArtifactGradeUpgrade = lazy(() => import('../pages/news/season19/ArtifactGradeUpgrade'));
const DiezMasteryWeapon = lazy(() => import('../pages/news/season19/DiezMasteryWeapon'));

function ContenidoRutas() {
  return (
    <>
      <Suspense fallback={
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>}>
        <Routes>
          <Route path="/" element={<Home />}/>
          <Route path='/news' element={<News />}/>
          <Route path='/content-creators' element={<ContentCreators />}/>
          <Route path='/contact' element={<Contact />}/>
          <Route path="/maps" element={<Navigate to="/maps/lorencia" />}/>
          <Route path="/maps/:mapName" element={<Maps />}/>

          <Route path="/game-system/basic-info" element={<BasicInfo/>}/>
          <Route path="/game-system/trade-and-personal-store" element={<TradePersonalStore/>}/>
          <Route path="/game-system/cashshop" element={<Cashshop/>}/>

          <Route path="/guides/class-change/quest-2nd-class-sebina-the-priest" element={<QuestSebinaThePriest/>}/>
          <Route path="/guides/class-change/quest-marlon" element={<QuestMarlon/>}/>
          <Route path="/guides/class-change/quest-3rd-class-apostle-devin" element={<QuestApostleDevin/>}/>
          <Route path="/guides/class-change/quest-4th-class-sent" element={<QuestSent/>}/>
          <Route path="/guides/class-change/quest-5th-class-eunice" element={<QuestEunice/>}/>
                   
          <Route path="/guides/how-to-create-wings/first-wings" element={<CreateWingsLevelOne/>}/>
          <Route path="/guides/how-to-create-wings/second-wings" element={<CreateWingsLevelTwo/>}/>
          <Route path="/guides/how-to-create-wings/monster-wings" element={<CreateWingsLevelMonster/>}/>
          <Route path="/guides/how-to-create-wings/third-wings" element={<CreateWingsLevelThree/>}/>
          <Route path="/guides/how-to-create-wings/fourth-wings" element={<CreateWingsLevelFour/>}/>

          <Route path="/guides/elemental-system/elemental-system" element={<ElementalSystem/>}/>
          <Route path="/guides/elemental-system/pentagrams-guide" element={<PentagramsGuide/>}/>
          <Route path="/guides/elemental-system/errtels-guide" element={<ErrtelsGuide/>}/>
          <Route path="/guides/elemental-system/mastery-pentagrams-guide" element={<MasteryPentagramsGuide />}/>
          <Route path="/guides/elemental-system/mastery-errtels-guide" element={<MasteryErrtelsGuide />}/>

          <Route path="/guides/pandoras-jewels-system" element={<PandorasJewelsSystem />}/>
          <Route path="/guides/guardian-system" element={<GuardianSystem />}/>
          <Route path="/guides/artifact-system" element={<ArtifactSystem />}/>

          <Route path="/characters/dark-wizard" element={<DarkWizard/>}/>
          <Route path="/characters/dark-knight" element={<DarkKnight/>}/>
          <Route path="/characters/fairy-elf" element={<FairyElf/>}/>
          <Route path="/characters/magic-gladiator" element={<MagicGladiator/>}/>
          <Route path="/characters/dark-lord" element={<DarkLord/>}/>
          <Route path="/characters/summoner" element={<Summoner/>}/>
          <Route path="/characters/rage-fighter" element={<RageFighter/>}/>
          <Route path="/characters/grow-lancer" element={<GrowLancer/>}/>
          <Route path="/characters/rune-wizard" element={<RuneWizard/>}/>
          <Route path="/characters/slayer" element={<Slayer />}/>
          <Route path="/characters/gun-crusher" element={<GunCrusher />}/>
          <Route path="/characters/kundun-mephis" element={<KundunMephis />}/>
          <Route path="/characters/lemuria" element={<Lemuria />}/>
          <Route path="/characters/illusion-knight" element={<IllusionKnight />}/>

          <Route path="/events/blood-castle" element={<BloodCastle />}/>
          <Route path="/events/devil-square" element={<DevilSquare />}/>
          <Route path="/events/chaos-castle" element={<ChaosCastle />}/>
          <Route path="/events/illusion-temple" element={<IllusionTemple />}/>
          <Route path="/events/doppelganger" element={<Doppelganger />}/>
          <Route path="/events/fortress-of-the-imperial-guard-varka" element={<FortressImperialGuardVarka />}/>
          <Route path="/events/maze-of-dimensions" element={<MazeofDimensions />}/>
          <Route path="/events/boss-battle-together" element={<BossBattleTogether />}/>
          <Route path="/events/arca-war" element={<ArkaWar />}/>

          <Route path="/items/set-items/helm" element={<Helm />}/>
          <Route path="/items/set-items/armor" element={<Armor />}/>
          <Route path="/items/set-items/gloves" element={<Gloves />}/>
          <Route path="/items/set-items/pants" element={<Pants />}/>
          <Route path="/items/set-items/boots" element={<Boots />}/>
          <Route path="/items/weapons/axe" element={<Axe />}/>
          <Route path="/items/weapons/blade" element={<Blade />}/>
          <Route path="/items/weapons/book-of-the-other-world" element={<BookOfTheOtherWorld />}/>
          <Route path="/items/weapons/bow" element={<Bow />}/>
          <Route path="/items/weapons/claws" element={<Claws />}/>
          <Route path="/items/weapons/crossbow" element={<Crossbow />}/>
          <Route path="/items/weapons/lance" element={<Lance />}/>
          <Route path="/items/weapons/mace" element={<Mace />}/>
          <Route path="/items/weapons/magic-book" element={<MagicBook />}/>
          <Route path="/items/weapons/magic-gun" element={<MagicGun />}/>
          <Route path="/items/weapons/orb" element={<Orb />}/>
          <Route path="/items/weapons/quiver" element={<Quiver />}/>
          <Route path="/items/weapons/rune-mace" element={<RuneMace />}/>
          <Route path="/items/weapons/scepter" element={<Scepter />}/>
          <Route path="/items/weapons/shield" element={<Shield />}/>
          <Route path="/items/weapons/short-sword" element={<ShortSword />}/>
          <Route path="/items/weapons/spear" element={<Spear />}/>
          <Route path="/items/weapons/staff" element={<Staff />}/>
          <Route path="/items/weapons/stick" element={<Stick />}/>
          <Route path="/items/weapons/sword" element={<Sword />}/>
          <Route path="/items/wings" element={<Wings />}/>
          <Route path="/items/pentagrams" element={<Pentagram />}/>
          <Route path="/items/errtels" element={<Errtels />}/>
          <Route path="/items/artifacts" element={<Artifacts />}/>
          <Route path="/items/rings-pendants" element={<RingPendant />}/>
          <Route path="/items/ancient-sets" element={<AncientSet />}/>
          <Route path="/items/lucky-sets" element={<LuckySet />}/>
          <Route path="/items/mastery-sets" element={<MasterySet />}/>
          <Route path="/items/mastery-earrings" element={<Earring />}/>
          <Route path="/items/muun" element={<Muun />}/>
          <Route path="/items/orb-scroll" element={<OrbScroll />}/>
          <Route path="/items/jewels" element={<Jewels />}/>

          <Route path="/tools/mu-roomy-helper" element={<MuRoomyHelper />}/>
          <Route path="/tools/maze-of-dimensions-helper-v3" element={<MazeofDimensionsHelperSeason16 />}/>
          <Route path="/tools/experience-calculator" element={<CalculadoraExperiencia />}/>

          <Route path="/legal/cookie-policy" element={<CookiePolicy />}/>
          <Route path="/legal/privacy-policy" element={<PrivacyPolicy />}/>

          <Route path="/latestnews/season12/3rd-mastery-armor-added" element={<TresMasteryArmor />}/>
          <Route path="/latestnews/season12/2nd-mastery-weapon-added" element={<DosMasteryWeapon />}/>
          <Route path="/latestnews/season12/new-map-nixies-lake" element={<NewMapNixiesLake />}/>
          <Route path="/latestnews/season12/nixies-lake-boss-zone" element={<NixiesLakeBossZone />}/>
          <Route path="/latestnews/season12/maze-of-dimension-added" element={<MazeOfDimensions />}/>
          <Route path="/latestnews/season12/new-items-and-changes-season12-part-1-2" element={<NewItemsAndChangesS12 />}/>
          <Route path="/latestnews/season12/new-master-skill-tree-4th-class" element={<NewMasterSkillTree4thClass />}/>
          <Route path="/latestnews/season12/master-skill-tree-renewal" element={<MasterSkillTreeRenewal />}/>
          <Route path="/latestnews/season12/4th-class-change-quest-added" element={<FourthClassChangeQuest />}/>
          <Route path="/latestnews/season12/play-improvements-season12-part-2" element={<PlayImprovements12part2 />}/>
          <Route path="/latestnews/season12/new-map-deep-dungeon" element={<NewMapDeepDungeon />}/>
          <Route path="/latestnews/season12/new-quest-system-guide-quest-added" element={<NewQuestSystemGuideQuestAdded />}/>
          <Route path="/latestnews/season12/new-mini-game-jewel-bingo-added" element={<NewMiniGameJewelBingo />}/>

          <Route path="/latestnews/season13/play-improvement-and-new-wing-crafting-method" element={<PlayImprovementNewWingCraftingMethod />}/>
          <Route path="/latestnews/season13/skill-enhancement-tree-2nd-patch" element={<SkillEnhancementTree2ndPatch />}/>
          <Route path="/latestnews/season13/new-skill-and-changes-in-skills" element={<NewSkillAndChangesInSkills />}/>
          <Route path="/latestnews/season13/3rd-mastery-weapon-4th-mastery-armor" element={<TresMasteryWeaponCuatroMasteryArmor />}/>
          <Route path="/latestnews/season13/new-map-swamp-of-darkness" element={<NewMapSwampofDarkness />}/>
          <Route path="/latestnews/season13/new-wings-level-4" element={<NewWingsLevel4 />}/>
          <Route path="/latestnews/season13/swamp-of-darkness-boss-zone" element={<SwampofDarknessBossZone />}/>
          <Route path="/latestnews/season13/maze-of-dimensions-renewal" element={<MazeofDimensionsRenewal />}/>
          <Route path="/latestnews/season13/play-improvement-and-level-expansion-season13-part-2-1" element={<PlayImprovementAndMaximumLevelExpansion13part21 />}/>
          <Route path="/latestnews/season13/new-accesory-first-mastery-earrings" element={<NewAccesoryFirstMasteryEarrings />}/>
          <Route path="/latestnews/season13/added-and-improved-short-term-buff-items" element={<AddedAndImprovedShortTermBuffItems />}/>
          <Route path="/latestnews/season13/rage-fighter-absolute-weapon-added" element={<RageFighterAbsoluteWeaponAdded />}/>

          <Route path="/latestnews/season14/new-character-rune-wizard" element={<NewCharacterRuneWizard />}/>
          <Route path="/latestnews/season14/new-character-rune-wizard-buff-skill" element={<NewCharacterRuneWizardBuffSkill />}/>
          <Route path="/latestnews/season14/play-improvement-season14-part-1-1" element={<PlayImprovementSeason14Part11 />}/>
          <Route path="/latestnews/season14/rune-wizard-4th-class-change-and-skill-enhancement-tree" element={<RuneWizard4thClassSkillEnhancementTree />}/>
          <Route path="/latestnews/season14/rune-wizard-4th-wing-and-3rd-mastery-items" element={<RuneWizard4thWingAnd3rdMasteryItems />}/>
          <Route path="/latestnews/season14/4th-mastery-weapon-5th-mastery-armor" element={<CuatroMasteryWeaponCincoMasteryArmor />}/>
          <Route path="/latestnews/season14/new-map-cubera-mine" element={<NewMapCuberaMine />}/>
          <Route path="/latestnews/season14/grow-lancer-archangel-absolute-weapon-added" element={<GrowLancerArchangelAbsoluteWeapon />}/>
          <Route path="/latestnews/season14/monster-soul-conversion" element={<MonsterSoulConversion />}/>
          <Route path="/latestnews/season14/rune-wizard-mastery-item-added" element={<RuneWizardMasteryItemAdded />}/>
          <Route path="/latestnews/season14/new-map-atlans-abbys" element={<NewMapAtlansAbbys />}/>
          <Route path="/latestnews/season14/maximum-level-expansion-season-rewards-season14-part-2-1" element={<MaximumLevelExpansionSeasonRewardsS1421 />}/>
          <Route path="/latestnews/season14/2nd-mastery-earrings" element={<SecondMasteryEarrings />}/>
          <Route path="/latestnews/season14/summoner-renewal" element={<SummonerRenewal />}/>
          <Route path="/latestnews/season14/guardian-system-first-guardian-and-atlans-abbys-elite-zone" element={<GuardianSystemFirstGuardianAtlansAbbysEliteZone />}/>

          <Route path="/latestnews/season15/new-character-slayer" element={<NewCharacterSlayer />}/>
          <Route path="/latestnews/season15/5th-mastery-weapon-6th-mastery-armor" element={<NewsItems5masteryweapons6masteryarmor />}/>
          <Route path="/latestnews/season15/new-map-scorched-canyon" element={<NewMapScorchedCanyon />}/>
          <Route path="/latestnews/season15/play-improvement-and-level-expansion-season15-part-1-2" element={<PlayImprovementAndMaximumLevelExpansion />}/>
          <Route path="/latestnews/season15/added-slayer-equipment-and-skill" element={<AddedSlayerEquipmentAndSkill />}/>
          <Route path="/latestnews/season15/slayer-skill-enhancement-tree" element={<SlayerSkillEnhancementTree />}/>
          <Route path="/latestnews/season15/skill-enhancement-tree-improvement" element={<SkillEnhancementTreeImprovement />}/>
          <Route path="/latestnews/season15/scorched-canyon-elite-zone" element={<ScorchedCanyonEliteZone />}/>
          <Route path="/latestnews/season15/changes-in-admission-to-events" element={<ChangesInAdmissionToEvents />}/>
          <Route path="/latestnews/season15/4th-wings-and-equipment-added" element={<Slayer4thWingsAndEquipmentAdded />}/>
          <Route path="/latestnews/season15/excellent-socket-necklace" element={<ExcellentSocketNecklace />}/>
          <Route path="/latestnews/season15/play-improvement-and-level-expansion-season15-part-2-1" element={<PlayImprovementAndMaximumLevelExpansionSeason15PartDosUno />}/>
          <Route path="/latestnews/season15/new-map-crimson-icarus" element={<NewMapCrimsonIcarus />}/>
          <Route path="/latestnews/season15/3rd-mastery-earrings" element={<ThirdMasteryEarrings />}/>
          <Route path="/latestnews/season15/2nd-guardian-ice-dragon-crimson-icarus-elite-zone" element={<SecondGuardianIceDragonCrimsonIcarusEliteZone />}/>
          <Route path="/latestnews/season15/improvements-season15-part-2-2" element={<ImprovementsLifeIcons />}/>
          <Route path="/latestnews/season15/personal-store-renewal" element={<PersonalStoreRenewal />}/>
          <Route path="/latestnews/season15/helper-plus-and-party-function-improvement" element={<HelperPlusPartyFunctionImprovement />}/>
          <Route path="/latestnews/season15/dragon-knight-renewal" element={<DragonKnightRenewal />}/>
          <Route path="/latestnews/season15/slayer-6th-mastery-armor-added" element={<Slayer6thMasteryArmorAdded />}/>

          <Route path="/latestnews/season16/new-character-gun-crusher" element={<NewCharacterGunCrusher />}/>
          <Route path="/latestnews/season16/maximum-level-expansion-season-rewards-season16-part-1-2" element={<MaximumLevelExpansionSeasonRewardsS1612 />}/>
          <Route path="/latestnews/season16/new-map-temple-of-arnil" element={<NewMapTempleOfArnil />}/>
          <Route path="/latestnews/season16/mastery-talisman-of-chaos-assembly-bound" element={<MasteryTalismanOfChaosAssemblyBound />}/>
          <Route path="/latestnews/season16/6th-mastery-weapon-7th-mastery-armor" element={<NewsItems6masteryweapons7masteryarmor />}/>
          <Route path="/latestnews/season16/gun-crusher-skill-enhancement-tree-item-added" element={<GunCrusherSkillEnhancementTreeItemAdded />}/>
          <Route path="/latestnews/season16/temple-of-arnil-elite-zone" element={<TempleOfArnilEliteZone />}/>
          <Route path="/latestnews/season16/added-gun-crusher-equipment" element={<AddedGunCrusherEquipment />}/>
          <Route path="/latestnews/season16/elemental-system-update" element={<ElementalSystemUpdate />}/>
          <Route path="/latestnews/season16/maze-of-dimensions-improvements" element={<MazeofDimensionsImprovements />}/>
          <Route path="/latestnews/season16/jewel-of-harmony-renewal" element={<JewelofHarmonyRenewal />}/>
          <Route path="/latestnews/season16/moss-treasure-box-improvement-and-item-bundle-function-added" element={<PlayImprovementUnoTres />}/>
          <Route path="/latestnews/season16/maximum-level-expansion-season-rewards" element={<MaximumLevelExpansionSeasonRewardsS1621 />}/>
          <Route path="/latestnews/season16/new-map-gray-aida" element={<NewMapGrayAida />}/>
          <Route path="/latestnews/season16/4th-mastery-earrings" element={<FourthMasteryEarrings />}/>
          <Route path="/latestnews/season16/magic-gladiator-renewal" element={<SeasonDieciseisMagicGladiatorRenewal />}/>
          <Route path="/latestnews/season16/gun-crusher-new-buff-skill" element={<GunCrusherNewBuffSkill />}/>
          <Route path="/latestnews/season16/3rd-guardian-fierce-lion-gray-aida-elite-zone" element={<ThirdGuardianFierceLionGrayAidaEliteZone />}/>
          <Route path="/latestnews/season16/mount-items-and-disassembly-function-improvements" element={<MountItemsAndDisassemblyFunctionImprovements />}/>

          <Route path="/latestnews/season17/new-characters-kundun-mephis-lemuria" element={<NewCharactersKundunMephisLemuria />}/>
          <Route path="/latestnews/season17/play-improvement" element={<PlayImprovement />}/>
          <Route path="/latestnews/season17/7th-mastery-weapon-8th-mastery-armor" element={<SietehMasteryWeaponOchohMasteryArmor />}/>
          <Route path="/latestnews/season17/new-characters-kundun-lemuria-item-added" element={<NewCharactersKundunLemuriaItemAdded />}/>
          <Route path="/latestnews/season17/new-map-burning-kethotum-old-kethotum" element={<NewMapBurningKethotumOldKethotum />}/>
          <Route path="/latestnews/season17/character-max-level-expansion-and-season-rewards" element={<CharactersMaxLevelExpansionAndSeasonReward />}/>
          <Route path="/latestnews/season17/added-new-maps-and-rebalancing-some-hunting-grounds" element={<AddedNewMapsAndRebalancingSomeHuntingGrounds />}/>
          <Route path="/latestnews/season17/new-characters-4th-wings-equipment-items" element={<NewCharactersFourthWingsEquipmenyItems />}/>
          <Route path="/latestnews/season17/new-map-kantru-underground" element={<NewMapKantruUnderground />}/>
          <Route path="/latestnews/season17/5th-mastery-earrings" element={<FifthMasteryEarrings />}/>
          <Route path="/latestnews/season17/maximum-level-expansion-season-rewards" element={<MaximumLevelExpansionSeasonRewardsParteDosUno />}/>
          <Route path="/latestnews/season17/play-improvement-season17-part-2-2" element={<PlayImprovementParteDosDos />}/>
          <Route path="/latestnews/season17/4th-guardian-kantru-underground-elite-zone" element={<FourthGuardianShiningTailKantruElite />}/>
          <Route path="/latestnews/season17/fairy-elf-renewal-season17-part-2-2" element={<FairyElfRenewal />}/>

          <Route path="/latestnews/season18/new-character-illusion-knight-pre-update" element={<NewCharacterIllusionKnightPreUpdate />}/>
          <Route path="/latestnews/season18/new-character-illusion-knight" element={<NewCharacterIllusionKnight />}/>
          <Route path="/latestnews/season18/maximum-level-expansion-season-rewards" element={<CharactersMaxLevelExpansionAndSeasonRewardParteUnoDos />}/>
          <Route path="/latestnews/season18/new-map-ignis-volcano" element={<NewMapIgnisVolcano />}/>
          <Route path="/latestnews/season18/added-illusion-knight-equipment" element={<AddedIllusionKnightEquipment />}/>
          <Route path="/latestnews/season18/8th-mastery-weapon-9th-mastery-armor" element={<OchoMasteryWeaponNueveMasteryArmor />}/>
          <Route path="/latestnews/season18/ignis-volcano-elite-zone" element={<IgnisVolcanoEliteZone />}/> 
          <Route path="/latestnews/season18/4th-cloak-equipment-items" element={<FourthCloakAndEquipmentItems />}/>
          <Route path="/latestnews/season18/new-event-all-together-boss-battle" element={<AllTogetherBossBattle />}/>
          <Route path="/latestnews/season18/fortress-of-imperial-guardian-varka-event-map-renewal" element={<VarkaEventMapRenewal />}/>
          <Route path="/latestnews/season18/new-equipment-artifacts" element={<NewEquipmentArtifacts />}/>
          <Route path="/latestnews/season18/maximum-level-expansion-season-rewards-season18-part-2-1" element={<CharactersMaxLevelExpansionAndSeasonRewardSeason18ParteDosUno />}/>
          <Route path="/latestnews/season18/new-map-bloody-tarkan" element={<NewMapBloodyTarkan />}/>
          <Route path="/latestnews/season18/6th-mastery-earrings" element={<SixthMasteryEarrings />}/>
          <Route path="/latestnews/season18/tempest-muun" element={<TempestMuun />}/>
          <Route path="/latestnews/season18/season-pass-events" element={<SeasonPassEvents />}/>
          <Route path="/latestnews/season18/wing-combination-improvement" element={<WingCombinationImprovement />}/>
          <Route path="/latestnews/season18/5th-guardian-ur" element={<FifthGuardianUr />}/>
          <Route path="/latestnews/season18/bloody-tarkan-elite-zone" element={<BloodyTarkanEliteZone />}/>
          <Route path="/latestnews/season18/character-renewal-season18-part-2-2" element={<CharacterRenewalSeason18 />}/>

          <Route path="/latestnews/season19/grow-lancer-renewal" element={<GrowLancerRenewalSeason19 />}/>
          <Route path="/latestnews/season19/new-map-tormenta-island" element={<NewMapTormentaIsland />}/>
          <Route path="/latestnews/season19/9th-mastery-weapon-10th-mastery-armor" element={<NueveMasteryWeaponDiezMasteryArmor />}/>
          <Route path="/latestnews/season19/maximum-level-expansion-season-rewards-season19-part-1-2" element={<CharactersMaxLevelExpansionAndSeasonRewardSeason19ParteUnoDos />}/>
          <Route path="/latestnews/season19/tormenta-island-elite-zone" element={<TormentaIslandEliteZone />}/>
          <Route path="/latestnews/season19/5th-wings-added" element={<FifthWingsAdded />}/>
          <Route path="/latestnews/season19/5th-class-change-added" element={<FifthClassChangeAdded />}/>
          <Route path="/latestnews/season19/play-improvement-season19-part-1-3" element={<PlayImprovementsParteUnoTres />}/>
          <Route path="/latestnews/season19/ability-enhancement-tree-added" element={<AbilityEnhancementTreeAdded />}/>
          <Route path="/latestnews/season19/dark-lord-renewal" element={<DarkLordRenewalSeason19 />}/>
          <Route path="/latestnews/season19/7th-mastery-earrings" element={<SeventhMasteryEarrings />}/>
          <Route path="/latestnews/season19/new-map-twisted-karutan" element={<NewMapTwistedKarutan />}/>
          <Route path="/latestnews/season19/maximum-level-expansion-season-rewards-season19-part-2-2" element={<CharactersMaxLevelExpansionAndSeasonRewardSeason19ParteDosDos />}/>
          <Route path="/latestnews/season19/improvement-of-ability-enhancement-core-and-skill-card-conversion-combination" element={<ImprovementAbilityEnhancementCoreSkillCardConversionCombination />}/>
          <Route path="/latestnews/season19/6th-guardian-leviathan" element={<SixthGuardianLeviathan />}/>
          <Route path="/latestnews/season19/twisted-karutan-elite-zone" element={<TwistedKarutanEliteZone />}/>
          <Route path="/latestnews/season19/skill-enhancement-tree-improved-4th-stat-option" element={<SkillEnhancementTreeImproved4thStatOption />}/>
          <Route path="/latestnews/season19/event-map-and-boss-battle-integrated-ui" element={<EventMapAndBossBattleIntegratedUI />}/>
          <Route path="/latestnews/season19/artifact-grade-upgrade" element={<ArtifactGradeUpgrade />}/>
          <Route path="/latestnews/season19/10th-mastery-weapon" element={<DiezMasteryWeapon />}/>

          <Route path='/login' element={<Login />}/>
          <Route path='/dashboard-admin' element={
            <ProtectedRoute>
              <DashboardAdmin />
            </ProtectedRoute>
          }/>
          <Route path='*' element={<NotFound />}/>
        </Routes>
      </Suspense>
    </>
  )
}

export default ContenidoRutas