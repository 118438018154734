import React, { useState } from 'react';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import { BadgeCharacter } from '../../elements/badges/badges';
import './modal.css';

function ModalItemSetLucky({infoStats, defense, nombre, imagen, imagenFirebase, tipo, equipado, redireccion, ticket, dosSetOpciones, tresSetOpciones, cuatroSetOpciones, cincoSetOpciones, addedinseason}) {

    const [show, setShow] = useState(false);

    function handleShow(breakpoint) {
        setShow(true);
    }

    var crearTabla = (infoTabla) => {
        if (tipo === "helm" || tipo === "armor" || tipo === "gloves" || tipo === "pants" || tipo === "boots") {
            return  <div className="table-responsive-sm text-center">
                        <table className="table table-bordered table-dark table-hover">
                            <thead>
                                <tr>
                                    <th scope="col" colSpan={2}><small className="text-uppercase">Item Stats</small></th>
                                    <th scope="col" colSpan={5}><small className="text-uppercase">Required Stats</small></th>
                                </tr>
                                <tr>
                                    <th scope="col"><small className="text-uppercase">Level</small></th>
                                    <th scope="col"><small className="text-uppercase">Defense</small></th>
                                    <th scope="col"><small className="text-uppercase">Strength</small></th>
                                    <th scope="col"><small className="text-uppercase">Agility</small></th>
                                    <th scope="col"><small className="text-uppercase">Stamina</small></th>
                                    <th scope="col"><small className="text-uppercase">Energy</small></th>
                                    <th scope="col"><small className="text-uppercase">Command</small></th>
                                </tr>
                            </thead>
                            <tbody>
                                { infoTabla?.map((info, index) => {return(
                                    <tr key={index}>
                                        <td className="align-middle p-1"><small>{info.Level}</small></td>
                                        <td className="align-middle p-1"><small>{info.Defense}</small></td>
                                        <td className="align-middle p-1"><small>{info.Strength}</small></td>
                                        <td className="align-middle p-1"><small>{info.Agility}</small></td>
                                        <td className="align-middle p-1"><small>{info.Stamina}</small></td>
                                        <td className="align-middle p-1"><small>{info.Energy}</small></td>
                                        <td className="align-middle p-1"><small>{info.Command}</small></td>
                                    </tr>
                                )})}
                            </tbody>
                        </table>
                    </div>
        }
    }

    return (
        <>
            <button type="button" className="btn-more-info-img-stats rounded" onClick={() => handleShow()}>
                <div className="col-12">
                    <div className="row">
                        <div className="col-6 p-0">
                            <div className="m-auto">
                                <img
                                    loading="lazy"
                                    src={imagenFirebase || require('../../assets/items/set-items/'+tipo+'/'+imagen+'.webp')}
                                    className="img-fluid"
                                    width={100}
                                    height={66}
                                    alt={nombre+" Mu Online"}
                                    onError={(e) => {
                                        console.error("Error loading image, please contact admin to fix it:", e);
                                }}/>
                            </div>
                        </div>
                        <div className="col-6 p-0">
                            <div className="d-flex h-100">
                                <div className="row justify-content-center align-self-center">
                                    <div className="col-12 p-0"><small className="text-option-sets-modal">{tipo}</small></div>
                                    <div className="col-12 p-0"><small className="color-normal text-option-sets-modal">Defense</small><small className="text-option-sets-modal">{' '+defense}</small></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </button>
            <Modal size="xl" show={show} onHide={() => setShow(false)} className="modal-general">
                <Modal.Header closeVariant='white' closeButton>
                    <Modal.Title>{nombre}</Modal.Title>
                </Modal.Header>
                <Modal.Body scrollable="true">
                    <div className="row">
                        <div className="col-md-6 col-lg-4 col-xl-3 col-xxl-3 mb-4">
                            <div className="card card-bg-sinhover h-100">
                                <div className="row m-auto">
                                    <div className="col-12 d-flex align-items-center justify-content-center">
                                        <img
                                            loading="lazy"
                                            src={imagenFirebase || require('../../assets/items/set-items/'+tipo+'/'+imagen+'.webp')}
                                            className="img-fluid"
                                            width={150}
                                            height={99}
                                            alt={nombre+" Mu Online"}
                                            onError={(e) => {
                                                console.error("Error loading image, please contact admin to fix it:", e);
                                        }}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3 col-xxl-3 mb-4">
                            <div className="card card-bg-sinhover h-100">
                                {ticket === 1 ? 
                                (<div className="row m-auto">
                                    <div className="col-12 d-flex align-items-center justify-content-center">
                                        <img loading="lazy" src={"https://firebasestorage.googleapis.com/v0/b/trade-mu.appspot.com/o/assets%2Fgamesystem%2Fcashshop%2FLuckyCardGreen.webp?alt=media&token=9aa535c5-98b2-424d-941d-44421772d151"} className="img-fluid" width={60} height={60} alt={"1st Lucky card Item Mu Online"}/>
                                    </div>
                                    <div className="col-12 d-flex align-items-center justify-content-center">
                                        <p className="descripcion-modal text-accent-upper">1st Lucky card</p>
                                    </div>
                                </div>)
                                :
                                (<div className="row m-auto">
                                    <div className="col-12 d-flex align-items-center justify-content-center">
                                        <img loading="lazy" src={"https://firebasestorage.googleapis.com/v0/b/trade-mu.appspot.com/o/assets%2Fgamesystem%2Fcashshop%2FLuckyCardRed.webp?alt=media&token=a0937de5-4406-43b2-8713-481c22385ba4"} className="img-fluid" width={60} height={60} alt={"2nd Lucky card Item Mu Online"}/>
                                    </div>
                                    <div className="col-12 d-flex align-items-center justify-content-center">
                                        <p className="descripcion-modal text-accent-upper">2nd Lucky card</p>
                                    </div>
                                </div>)}
                            </div>
                        </div>
                        <div className="col-lg-4 col-xl-6 col-xxl-6 mb-4">
                            <div className="card card-bg-sinhover h-100">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <p className="descripcion-modal text-uppercase text-blanco">Can be Equipped By:</p>
                                        </div>
                                        <div className="col-12 text-center mt-2">
                                            {equipado.map((clase, index) => {return (
                                                <BadgeCharacter key={index} redireccion={'/characters/'+redireccion[index]} clase={clase} />
                                            )})}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mb-4">
                        <div className="accordion accordion-flush accordionOptionsInfo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOptionsInfo" aria-expanded="true" aria-controls="collapseOptionsInfo"><i className="bi bi-card-list text-accent me-2"></i>Options Info</button>
                            <div id="collapseOptionsInfo" className="accordion-collapse collapse" aria-labelledby="headingOptionsInfo" data-bs-parent="#collapseOptionsInfo">
                                <div className="accordion-body">
                                    <div className="row mb-4">
                                            {dosSetOpciones?.length === 0 ? (<></>):(
                                                <>
                                                    <div className="col-12 text-center m-auto"><p className="text-danger text-titulo-option-modal m-2">2 Set Effect</p></div>
                                                        {
                                                            dosSetOpciones.map((opt, index) => {return (
                                                                <div key={index} className="col-12"><p className="text-option-modal m-0">{opt.opcion}</p></div>
                                                            )})
                                                        }  
                                                </>)}
                                            {tresSetOpciones?.length === 0 ? (<></>):(
                                                <>
                                                    <div className="col-12 text-center m-auto"><p className="text-danger text-titulo-option-modal m-2">3 Set Effect</p></div>
                                                        {
                                                            tresSetOpciones.map((opt, index) => {return (
                                                                <div key={index} className="col-12"><p className="text-option-modal m-0">{opt.opcion}</p></div>
                                                            )})
                                                        }  
                                                </>)}
                                            {cuatroSetOpciones?.length === 0 ? (<></>):(
                                                <>
                                                    <div className="col-12 text-center m-auto"><p className="text-danger text-titulo-option-modal m-2">4 Set Effect</p></div>
                                                        {
                                                            cuatroSetOpciones.map((opt, index) => {return (
                                                                <div key={index} className="col-12"><p className="text-option-modal m-0">{opt.opcion}</p></div>
                                                            )})
                                                        }  
                                                </>)}
                                            {cincoSetOpciones?.length === 0 ? (<></>):(
                                                <>
                                                    <div className="col-12 text-center m-auto"><p className="text-danger text-titulo-option-modal m-2">5 Set Effect</p></div>
                                                        {
                                                            cincoSetOpciones.map((opt, index) => {return (
                                                                <div key={index} className="col-12"><p className="text-option-modal m-0">{opt.opcion}</p></div>
                                                            )})
                                                        }  
                                                </>)}
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Tabs defaultActiveKey="lucky" transition={true} className="mb-3 justify-content-center tabs-page">
                                {infoStats?.length === 0 ? <></> : <Tab eventKey={"lucky"} title={"Lucky"} className="tab-page"><div className="row"><div className="col-12">{crearTabla(infoStats)}</div></div></Tab>}
                            </Tabs>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalItemSetLucky