import { OverlayTrigger, Popover } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./badges.css";

function BadgeCharacter(props) {
  return (
    <>
      {props.clase === "All Classes" ? (
        <button type="button" className="btn badge-character">
          {props.clase}
        </button>
      ) : (
        <Link to={props.redireccion} className="badge-character-link">
          <button type="button" className="btn badge-character">
            {props.clase}
          </button>
        </Link>
      )}
    </>
  );
}

function BadgeTier(props) {
  return (
    <button type="button" className="btn badge-tier">{props.tier}</button>
  );
}

function BadgeMasteryTier(props) {
  return (
    <button type="button" className="btn badge-tier">{props.masteryTier} Mastery Earring</button>
  );
}

function BadgeMasteryTierSinFondo(props) {
  return (
    <p className="badge-tier-sinfondo text-center">{props.masteryTier} Mastery Earring</p>
  );
}

function BadgeSeason(props) {
  return (
    <>
      {props.hot === true ? 
      (<button type="button" className="btn badge-seasonpart"><i className="bi bi-fire icon-fire-news-main"></i>{props.seasonpart}</button>)
      :
      (<button type="button" className="btn badge-seasonpart">{props.seasonpart}</button>)}
    </>
  );
}

function BadgeAddedInSeason(props) {
  const popoverInfoSetAdded = (
    <Popover className="popover-set-added">
      <Popover.Body className="popover-set-added-body">
        <p className="text-info-addedinseason"><i className="bi bi-info-square icono-info me-2"></i>Added in season {props.addedinseason}</p>
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger trigger={['hover', 'focus']} placement="auto" overlay={popoverInfoSetAdded}>
      <button type="button" className="btn badge-addedinseason">Season {props.addedinseason}</button>
    </OverlayTrigger>
  );
}

function BadgeGeneral(props) {
  return (
    <button type="button" className="btn badge-character">{props.texto}</button>
  );
}

function BadgePrice(props) {
  return (
    <button type="button" className="btn badge-price">{props.texto}</button>
  );
}

function BadgeMenu(props) {
  return (
    <span className="btn badge-menu">{props.texto}</span>
  );
}

function BadgeCommunityBuildData(props) {
  return (
    <span className="badge-communitybuild">{props.texto}</span>
  );
}

function BadgeAbilityBook(props) {
  return (
    <>
      {props.tipo === "extra" ? (<span className="btn badge-abilitybook-extradamage">{props.texto}</span>) : null}
      {props.tipo === "skill" ? (<span className="btn badge-abilitybook-skill">{props.texto}</span>) : null}
      {props.tipo === "option" ? (<span className={`btn badge-abilitybook-${props.texto.toLowerCase()}`}>{props.texto}</span>) : null}
      {props.tipo === "elite" ? (<span className={`btn badge-abilitybook-${props.texto.toLowerCase()}`}>{props.texto}</span>) : null}
    </>
  );
}


export { BadgeCharacter, BadgeTier, BadgeMasteryTierSinFondo, BadgeMasteryTier, BadgeSeason, BadgeAddedInSeason, BadgeGeneral, BadgePrice, BadgeMenu, BadgeCommunityBuildData, BadgeAbilityBook};
