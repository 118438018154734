import { useState, useEffect } from "react";
import { collection, onSnapshot} from 'firebase/firestore';
import db from '../firebase/firebaseConfig';


function useObtenerBanners() {
    const [banners, cambiarBanners] = useState([]);
    //const bannersCollectionRef = collection(db, "banners");

    useEffect(() => {
        const unsuscribe = onSnapshot(collection(db, "banners"), (snapshot) => {
            cambiarBanners( snapshot.docs.map((banner) => {
                return {...banner.data()}
            }))
        });
        return unsuscribe;
    }, []);

  return ([banners])
}

export default useObtenerBanners;