import {Helmet} from 'react-helmet';
import Failure from '../assets/Fail404.webp';
import { BotonGoToHome } from '../elements/botones/Botones';

function NotFound() {
  
    return (
        <>
            <Helmet>
                <title>Mu Online Guide</title>
                <meta name='robots' content='noindex, nofollow' />
            </Helmet>
            <div className="row">
                <div className="col-12 d-flex align-items-center justify-content-center">
                    <img loading="lazy" src={Failure} className="img-fluid" height="178" width="672" alt="Page not found"/>
                </div>
                <div className="col-12 text-center">
                    <p className="page-not-found">Page Not Found</p>
                </div>
            </div>
            <div className="row row-cols-1 row-cols-sm-1 row-cols-md-3 row-cols-lg-4 row-cols-xl-4 row-cols-xxl-5 justify-content-center">
                <div className="col">
                    <BotonGoToHome />
                </div>
            </div>
        </>
    )
  }
  
  export default NotFound