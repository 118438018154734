import {Navigate} from 'react-router-dom';
import { useAuth } from '../hooks/AuthContext';

const ProtectedRoute = ({children}) => {
    //Para saber si hay un 'user' activo
    const { user } = useAuth();

    if (!user) {
        return <Navigate to='/'/>
    }

    return children
}

export default ProtectedRoute