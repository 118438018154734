import React, { useState } from 'react';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import './modal.css';


function ModalGuardian({nombre, imagenFirebase, imagenRareFirebase, armor, defaultOptions, generalOptions, eliteMonsterResistanceOptions, reqlevel, tipo}) {

    const [show, setShow] = useState(false);

    function handleShow(breakpoint) {
        setShow(true);
    }
    const crearTabla = (options) => {
        if (!options || !Array.isArray(options) || options.length === 0) {
          return <p>No hay datos disponibles.</p>;
        }
      
        const niveles = options.reduce((acc, group) => {
          return [
            ...acc,
            ...(group.levelsValue?.map((_, index) => index) || []),
            ...(group.options?.reduce((levelAcc, option) => {
              return [...levelAcc, ...option.levelsValue.map((_, index) => index)];
            }, []) || [])
          ];
        }, []).filter((value, index, self) => self.indexOf(value) === index);
      
        const hasElements = options.some(group => group.element);
      
        return (
          <div className="table-responsive-sm text-center">
            <table className="table table-bordered table-dark table-hover">
              <thead>
                {/* Nueva fila para los elementos en Elite Resistance */}
                {hasElements && (
                  <tr>
                    <th colSpan="1"></th>
                    {options.map((group, groupIndex) => (
                      group.element && (
                        <th key={`element-${groupIndex}`} colSpan={group.options?.length || 1}>
                          <small className="text-uppercase">{group.element}</small>
                        </th>
                      )
                    ))}
                  </tr>
                )}
                <tr>
                  <th scope="col"><small className="text-uppercase">Level</small></th>
                  {options.map((group, groupIndex) => (
                    (group.levelsValue || group.options) && (
                      group.levelsValue ? (
                        <th key={groupIndex} scope="col">
                          <small className="text-uppercase">
                            {group.option || (hasElements && group.element)} {/* Verificación de si es general o eliteMonsterResistance */}
                          </small>
                        </th>
                      ) : (
                        group.options.map((option, optionIndex) => (
                          <th key={`${groupIndex}-${optionIndex}`} colSpan="1">
                            <small className="text-uppercase">
                              {option.option}
                            </small>
                          </th>
                        ))
                      )
                    )
                  ))}
                </tr>
              </thead>
              <tbody>
                {niveles.map((level) => (
                  <tr key={level}>
                    <td className="align-middle p-1"><small>{level}</small></td>
                    {options.map((group, groupIndex) => (
                      (group.levelsValue || group.options) && (
                        group.levelsValue ? (
                          <td key={groupIndex} className="align-middle p-1">
                            <small>{group.levelsValue[level] || '-'}</small>
                          </td>
                        ) : (
                          group.options.map((option, optionIndex) => (
                            <td key={`${groupIndex}-${optionIndex}`} className="align-middle p-1">
                              <small>{option.levelsValue[level] || '-'}</small>
                            </td>
                          ))
                        )
                      )
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
    };
    
    return (
        <>
            <button type="button" className="btn btn-more-info" onClick={() => handleShow()}>Stats</button>
            <Modal size="xl" show={show} onHide={() => setShow(false)} className="modal-general">
                <Modal.Header closeVariant='white' closeButton>
                    <Modal.Title>{nombre}</Modal.Title>
                </Modal.Header>
                <Modal.Body scrollable="true">
                    <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-4 justify-content-center">
                        <div className="col mb-4">
                            <div className="card card-bg-sinhover h-100">
                                <div className="row">
                                    <div className="col-12 d-flex align-items-center justify-content-center">
                                        <img
                                            loading="lazy"
                                            src={imagenFirebase}
                                            className="img-fluid"
                                            width={207}
                                            height={136}
                                            alt={nombre+" Mu Online"}
                                            onError={(e) => {
                                                console.error("Error loading image, please contact admin to fix it:", e);
                                        }}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col mb-4">
                            <div className="card card-bg-sinhover h-100">
                                <div className="row">
                                    <div className="col-12 d-flex align-items-center justify-content-center">
                                        <img
                                            loading="lazy"
                                            src={imagenRareFirebase}
                                            className="img-fluid"
                                            width={207}
                                            height={136}
                                            alt={nombre+" Rare Mu Online"}
                                            onError={(e) => {
                                                console.error("Error loading image, please contact admin to fix it:", e);
                                        }}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-8 col-xl-9 col-xxl-9 mb-4">{/*
                            <div className="card card-bg-sinhover h-100">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <p className="descripcion-modal text-uppercase text-blanco">Can be Equipped By:</p>
                                        </div>
                                        <div className="col-12 text-center">
                                            {equipado.map((clase, index) => {return (
                                                <BadgeCharacter key={index} redireccion={'/characters/'+redireccion[index]} clase={clase} />
                                            )})}
                                        </div>
                                    </div>
                                </div>
                            </div>*/}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Tabs defaultActiveKey="generaloptions" transition={true} className="mb-3 justify-content-center tabs-page">
                                {
                                    generalOptions?.length === 0 ? <></> : (
                                        <Tab eventKey={"generaloptions"} title={"General Options"} className="tab-page">
                                            <div className="row">
                                                <div className="col-12">{crearTabla(generalOptions)}</div>
                                            </div>
                                        </Tab>)
                                }
                                {
                                    eliteMonsterResistanceOptions?.length === 0 ? <></> : (
                                        <Tab eventKey={"elitemonsterresistanceoptions"} title={"Elite Monster Resistance Options"} className="tab-page">
                                            <div className="row">
                                                <div className="col-12">{crearTabla(eliteMonsterResistanceOptions)}</div>
                                            </div>
                                        </Tab>)
                                }
                            </Tabs>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalGuardian