import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './sidebar.css';
import { BadgeMenu } from '../badges/badges';

function Sidebar() {
    const [toggleAccordion, setToggleAccordion] = useState("sinhijo");

    function ToggleAccordion(v) {
        if (v === "character") {
            setToggleAccordion("character")
        } else if(v === "guides") {
            setToggleAccordion("guides")
        } else if(v === "events") {
            setToggleAccordion("events")
        } else if(v === "gamesystem") {
            setToggleAccordion("gamesystem")
        } else if(v === "guideschangeclass") {
            setToggleAccordion("guideschangeclass")
        } else if(v === "guideselemental") {
            setToggleAccordion("guideselemental")
        } else if(v === "guideshowtocreatewings") {
            setToggleAccordion("guideshowtocreatewings")
        } else if(v === "items") {
            setToggleAccordion("items")
        } else if(v === "setitems") {
            setToggleAccordion("setitems")
        } else if(v === "weapons") {
            setToggleAccordion("weapons")
        } else {
            setToggleAccordion("sinhijo")
        }
    }
  return (
    <div className='conten-sidebar sticky-top'>
        <div className="head-sidebar flex-column">
            <div className="card background-sidebar-contact">
                <NavLink className={({isActive}) => isActive ? 'active link-sidebar-contact' : 'link-sidebar-contact'} to='/contact' onClick={() => ToggleAccordion("sinhijo")}><i className="bi bi-envelope"></i> Contact</NavLink>
            </div>
        </div>
        <hr className="separador mt-0 mb-0" />
        <div className="sidebar nav flex-column">
            <ul>
                <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to='/' onClick={() => ToggleAccordion("sinhijo")}><i className="bi bi-house-door me-2"></i>Home</NavLink></li>
                <li>
                    <div className="accordion accordion-flush accordionSidebar">
                        <ul>
                            <li>
                                <button className={toggleAccordion === "gamesystem" ? 'accordion-button active' : 'accordion-button collapsed'} type="button" data-bs-toggle="collapse" data-bs-target="#collapseGameSystem" aria-expanded="true" aria-controls="collapseGameSystem"><i className="bi bi-controller me-2"></i>Game System</button>
                            </li>
                        </ul>
                        <div id="collapseGameSystem" className="accordion-collapse collapse" aria-labelledby="headingGameSystem" data-bs-parent="#collapseGameSystem">
                            <div className="accordion-body">
                                <ul>
                                    <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/game-system/basic-info" onClick={() => ToggleAccordion("gamesystem")}>Basic Info</NavLink></li>
                                    <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/game-system/trade-and-personal-store" onClick={() => ToggleAccordion("gamesystem")}>Trade & Personal Store</NavLink></li>
                                    <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/game-system/cashshop" onClick={() => ToggleAccordion("gamesystem")}>Cash Shop</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to='/news' onClick={() => ToggleAccordion("sinhijo")}><i className="bi bi-newspaper me-2"></i>News</NavLink></li>
                <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to='/maps/lorencia' onClick={() => ToggleAccordion("sinhijo")}><i className="bi bi-globe-americas me-2"></i>Maps & Spots</NavLink></li>
                <li>
                    <div className="accordion accordion-flush accordionSidebar">
                        <ul>
                            <li>
                                <button className={toggleAccordion === "character" ? 'accordion-button active' : 'accordion-button collapsed'} type="button" data-bs-toggle="collapse" data-bs-target="#collapseCharacters" aria-expanded="true" aria-controls="collapseCharacters"><i className="bi bi-person-square me-2"></i>Characters</button>
                            </li>
                        </ul>
                        <div id="collapseCharacters" className="accordion-collapse collapse" aria-labelledby="headingCharacters" data-bs-parent="#collapseCharacters">
                            <div className="accordion-body">
                                <ul>
                                    <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/characters/dark-wizard" onClick={() => ToggleAccordion("character")}>Dark Wizard</NavLink></li>
                                    <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/characters/dark-knight" onClick={() => ToggleAccordion("character")}>Dark Knight</NavLink></li>
                                    <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/characters/fairy-elf" onClick={() => ToggleAccordion("character")}>Fairy Elf</NavLink></li>
                                    <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/characters/magic-gladiator" onClick={() => ToggleAccordion("character")}>Magic Gladiator</NavLink></li>
                                    <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/characters/dark-lord" onClick={() => ToggleAccordion("character")}>Dark Lord</NavLink></li>
                                    <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/characters/summoner" onClick={() => ToggleAccordion("character")}>Summoner</NavLink></li>
                                    <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/characters/rage-fighter" onClick={() => ToggleAccordion("character")}>Rage Fighter</NavLink></li>
                                    <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/characters/grow-lancer" onClick={() => ToggleAccordion("character")}>Grow Lancer</NavLink></li>
                                    <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/characters/rune-wizard" onClick={() => ToggleAccordion("character")}>Rune Wizard</NavLink></li>
                                    <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/characters/slayer" onClick={() => ToggleAccordion("character")}>Slayer</NavLink></li>
                                    <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/characters/gun-crusher" onClick={() => ToggleAccordion("character")}>Gun Crusher</NavLink></li>
                                    <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/characters/kundun-mephis" onClick={() => ToggleAccordion("character")}>White Wizard: Kundun Mephis</NavLink></li>
                                    <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/characters/lemuria" onClick={() => ToggleAccordion("character")}>Meiji: Lemuria</NavLink></li>
                                    <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/characters/illusion-knight" onClick={() => ToggleAccordion("character")}>Illusion Knight: Jacquard</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="accordion accordion-flush accordionSidebar">
                        <ul>
                            <li>
                                <button className={toggleAccordion === "events" ? 'accordion-button active' : 'accordion-button collapsed'} type="button" data-bs-toggle="collapse" data-bs-target="#collapseEvents" aria-expanded="true" aria-controls="collapseEvents"><i className="bi bi-calendar-week me-2"></i>Events</button>
                            </li>
                        </ul>
                        <div id="collapseEvents" className="accordion-collapse collapse" aria-labelledby="headingEvents" data-bs-parent="#collapseEvents">
                            <div className="accordion-body">
                                <ul>
                                    <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/events/blood-castle" onClick={() => ToggleAccordion("events")}>Blood Castle</NavLink></li>
                                    <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/events/devil-square" onClick={() => ToggleAccordion("events")}>Devil Square</NavLink></li>
                                    <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/events/chaos-castle" onClick={() => ToggleAccordion("events")}>Chaos Castle</NavLink></li>
                                    <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/events/illusion-temple" onClick={() => ToggleAccordion("events")}>Illusion Temple</NavLink></li>
                                    <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/events/doppelganger" onClick={() => ToggleAccordion("events")}>Doppelganger</NavLink></li>
                                    <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/events/fortress-of-the-imperial-guard-varka" onClick={() => ToggleAccordion("events")}>Fortress of the Imperial Guard: Varka</NavLink></li>
                                    <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/events/maze-of-dimensions" onClick={() => ToggleAccordion("events")}>Maze of Dimensions</NavLink></li>
                                    <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/events/boss-battle-together" onClick={() => ToggleAccordion("events")}>Boss Battle Together</NavLink></li>
                                    <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/events/arca-war" onClick={() => ToggleAccordion("events")}>Arca War</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="accordion accordion-flush accordionSidebar">
                        <ul>
                            <li>
                                <button className={toggleAccordion === "guides" || toggleAccordion === "guideschangeclass" || toggleAccordion === "guideselemental" ? 'accordion-button active' : 'accordion-button collapsed'} type="button" data-bs-toggle="collapse" data-bs-target="#collapseGuides" aria-expanded="true" aria-controls="collapseGuides"><i className="bi bi-book me-2"></i>Guides</button>
                            </li>
                        </ul>
                        <div id="collapseGuides" className="accordion-collapse collapse" aria-labelledby="headingGuides" data-bs-parent="#collapseGuides">
                            <div className="accordion-body">
                                <ul>
                                    <li>
                                        <div className="accordion accordion-flush accordionSidebar">
                                            <ul>
                                                <li>
                                                    <button className={toggleAccordion === "guideschangeclass" ? 'accordion-button active' : 'accordion-button collapsed'} type="button" data-bs-toggle="collapse" data-bs-target="#collapseQuestClassChange" aria-expanded="true" aria-controls="collapseQuestClassChange"><BadgeMenu texto={"Quest"}/> Class Change</button>
                                                </li>
                                            </ul>
                                            <div id="collapseQuestClassChange" className="accordion-collapse collapse" aria-labelledby="headingQuestClassChange" data-bs-parent="#collapseQuestClassChange">
                                                <div className="accordion-body">
                                                    <ul>
                                                        <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/guides/class-change/quest-2nd-class-sebina-the-priest" onClick={() => ToggleAccordion("guideschangeclass")}><BadgeMenu texto={"2nd Class"}/> Sebina The Priest</NavLink></li>
                                                        <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/guides/class-change/quest-marlon" onClick={() => ToggleAccordion("guideschangeclass")}><BadgeMenu texto={"2nd Class"}/> Marlon</NavLink></li>
                                                        <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/guides/class-change/quest-3rd-class-apostle-devin" onClick={() => ToggleAccordion("guideschangeclass")}><BadgeMenu texto={"3rd Class"}/> Apostle Devin</NavLink></li>
                                                        <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/guides/class-change/quest-4th-class-sent" onClick={() => ToggleAccordion("guideschangeclass")}><BadgeMenu texto={"4th Class"}/> Sent</NavLink></li>
                                                        <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/guides/class-change/quest-5th-class-eunice" onClick={() => ToggleAccordion("guideschangeclass")}><BadgeMenu texto={"5th Class"}/> Eunice</NavLink></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="accordion accordion-flush accordionSidebar">
                                            <ul>
                                                <li>
                                                    <button className={toggleAccordion === "guideshowtocreatewings" ? 'accordion-button active' : 'accordion-button collapsed'} type="button" data-bs-toggle="collapse" data-bs-target="#collapseGuidesHowToCreateWings" aria-expanded="true" aria-controls="collapseGuidesHowToCreateWings"><BadgeMenu texto={"Guide"}/> How To Create Wings</button>
                                                </li>
                                            </ul>
                                            <div id="collapseGuidesHowToCreateWings" className="accordion-collapse collapse" aria-labelledby="headingGuidesHowToCreateWings" data-bs-parent="#collapseGuidesHowToCreateWings">
                                                <div className="accordion-body">
                                                    <ul>
                                                        <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/guides/how-to-create-wings/first-wings" onClick={() => ToggleAccordion("guideshowtocreatewings")}>1st Wings</NavLink></li>
                                                        <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/guides/how-to-create-wings/second-wings" onClick={() => ToggleAccordion("guideshowtocreatewings")}>2nd Wings</NavLink></li>
                                                        <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/guides/how-to-create-wings/monster-wings" onClick={() => ToggleAccordion("guideshowtocreatewings")}>2.5 Monster Wings</NavLink></li>
                                                        <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/guides/how-to-create-wings/third-wings" onClick={() => ToggleAccordion("guideshowtocreatewings")}>3rd Wings</NavLink></li>
                                                        <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/guides/how-to-create-wings/fourth-wings" onClick={() => ToggleAccordion("guideshowtocreatewings")}>4th Wings</NavLink></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="accordion accordion-flush accordionSidebar">
                                            <ul>
                                                <li>
                                                    <button className={toggleAccordion === "guideselemental" ? 'accordion-button active' : 'accordion-button collapsed'} type="button" data-bs-toggle="collapse" data-bs-target="#collapseGuideElementalSystem" aria-expanded="true" aria-controls="collapseGuideElementalSystem"><BadgeMenu texto={"Guide"}/> Elemental System</button>
                                                </li>
                                            </ul>
                                            <div id="collapseGuideElementalSystem" className="accordion-collapse collapse" aria-labelledby="headingGuideElementalSystem" data-bs-parent="#collapseGuideElementalSystem">
                                                <div className="accordion-body">
                                                    <ul>
                                                        <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/guides/elemental-system/elemental-system" onClick={() => ToggleAccordion("guideselemental")}>Elemental System</NavLink></li>
                                                        <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/guides/elemental-system/pentagrams-guide" onClick={() => ToggleAccordion("guideselemental")}>Pentagrams</NavLink></li>
                                                        <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/guides/elemental-system/errtels-guide" onClick={() => ToggleAccordion("guideselemental")}>Errtels</NavLink></li>
                                                        <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/guides/elemental-system/mastery-pentagrams-guide" onClick={() => ToggleAccordion("guideselemental")}>Mastery Pentagrams</NavLink></li>
                                                        <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/guides/elemental-system/mastery-errtels-guide" onClick={() => ToggleAccordion("guideselemental")}>Mastery Errtels</NavLink></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/guides/pandoras-jewels-system" onClick={() => ToggleAccordion("guides")}><BadgeMenu texto={"Guide"}/> Pandora's Jewels System</NavLink></li>
                                    <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/guides/guardian-system" onClick={() => ToggleAccordion("guides")}><BadgeMenu texto={"Guide"}/> Guardian System</NavLink></li>
                                    <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/guides/artifact-system" onClick={() => ToggleAccordion("guides")}><BadgeMenu texto={"Guide"}/> Artifact System</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="accordion accordion-flush accordionSidebar">
                        <ul>
                            <li>
                                <button className={toggleAccordion === "items" || toggleAccordion === "setitems" || toggleAccordion === "weapons" ? 'accordion-button active' : 'accordion-button collapsed'} type="button" data-bs-toggle="collapse" data-bs-target="#collapseItems" aria-expanded="true" aria-controls="collapseItems"><i className="bi bi-boxes me-2"></i>Items</button>
                            </li>
                        </ul>
                        <div id="collapseItems" className="accordion-collapse collapse" aria-labelledby="headingitems" data-bs-parent="#collapseItems">
                            <div className="accordion-body">
                                <ul>
                                    <li>
                                        <div className="accordion accordion-flush accordionSidebar">
                                            <ul>
                                                <li>
                                                    <button className={toggleAccordion === "setitems" ? 'accordion-button active' : 'accordion-button collapsed'} type="button" data-bs-toggle="collapse" data-bs-target="#collapseSetItems" aria-expanded="true" aria-controls="collapseSetItems">Set Items</button>
                                                </li>
                                            </ul>
                                            <div id="collapseSetItems" className="accordion-collapse collapse" aria-labelledby="headingSetItems" data-bs-parent="#collapseSetItems">
                                                <div className="accordion-body">
                                                    <ul>
                                                        <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/items/set-items/helm" onClick={() => ToggleAccordion("setitems")}>Helm</NavLink></li>
                                                        <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/items/set-items/armor" onClick={() => ToggleAccordion("setitems")}>Armor</NavLink></li>
                                                        <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/items/set-items/gloves" onClick={() => ToggleAccordion("setitems")}>Gloves</NavLink></li>
                                                        <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/items/set-items/pants" onClick={() => ToggleAccordion("setitems")}>Pants</NavLink></li>
                                                        <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/items/set-items/boots" onClick={() => ToggleAccordion("setitems")}>Boots</NavLink></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="accordion accordion-flush accordionSidebar">
                                            <ul>
                                                <li>
                                                    <button className={toggleAccordion === "weapons" ? 'accordion-button active' : 'accordion-button collapsed'} type="button" data-bs-toggle="collapse" data-bs-target="#collapseWeapons" aria-expanded="true" aria-controls="collapseWeapons">Weapons</button>
                                                </li>
                                            </ul>
                                            <div id="collapseWeapons" className="accordion-collapse collapse" aria-labelledby="headingWeapons" data-bs-parent="#collapseWeapons">
                                                <div className="accordion-body">
                                                    <ul>
                                                        <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/items/weapons/axe" onClick={() => ToggleAccordion("weapons")}>Axe</NavLink></li>
                                                        <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/items/weapons/blade" onClick={() => ToggleAccordion("weapons")}>Blade</NavLink></li>
                                                        <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/items/weapons/book-of-the-other-world" onClick={() => ToggleAccordion("weapons")}>Book of the other world</NavLink></li>
                                                        <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/items/weapons/bow" onClick={() => ToggleAccordion("weapons")}>Bow</NavLink></li>
                                                        <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/items/weapons/claws" onClick={() => ToggleAccordion("weapons")}>Claws</NavLink></li>
                                                        <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/items/weapons/crossbow" onClick={() => ToggleAccordion("weapons")}>Crossbow</NavLink></li>
                                                        <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/items/weapons/lance" onClick={() => ToggleAccordion("weapons")}>Lance</NavLink></li>
                                                        <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/items/weapons/mace" onClick={() => ToggleAccordion("weapons")}>Mace</NavLink></li>
                                                        <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/items/weapons/magic-book" onClick={() => ToggleAccordion("weapons")}>Magic Book</NavLink></li>
                                                        <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/items/weapons/magic-gun" onClick={() => ToggleAccordion("weapons")}>Magic Gun</NavLink></li>
                                                        <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/items/weapons/orb" onClick={() => ToggleAccordion("weapons")}>Orb</NavLink></li>
                                                        <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/items/weapons/quiver" onClick={() => ToggleAccordion("weapons")}>Quiver</NavLink></li>
                                                        <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/items/weapons/rune-mace" onClick={() => ToggleAccordion("weapons")}>Rune Mace</NavLink></li>
                                                        <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/items/weapons/scepter" onClick={() => ToggleAccordion("weapons")}>Scepter</NavLink></li>
                                                        <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/items/weapons/shield" onClick={() => ToggleAccordion("weapons")}>Shield</NavLink></li>
                                                        <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/items/weapons/short-sword" onClick={() => ToggleAccordion("weapons")}>Short Sword</NavLink></li>
                                                        <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/items/weapons/spear" onClick={() => ToggleAccordion("weapons")}>Spear</NavLink></li>
                                                        <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/items/weapons/staff" onClick={() => ToggleAccordion("weapons")}>Staff</NavLink></li>
                                                        <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/items/weapons/stick" onClick={() => ToggleAccordion("weapons")}>Stick</NavLink></li>
                                                        <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/items/weapons/sword" onClick={() => ToggleAccordion("weapons")}>Sword</NavLink></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/items/ancient-sets" onClick={() => ToggleAccordion("items")}>Ancient Sets</NavLink></li>
                                    <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/items/lucky-sets" onClick={() => ToggleAccordion("items")}>Lucky Sets</NavLink></li>
                                    <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/items/mastery-sets" onClick={() => ToggleAccordion("items")}>Mastery Sets</NavLink></li>
                                    <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/items/mastery-earrings" onClick={() => ToggleAccordion("items")}>Mastery Earrings</NavLink></li>
                                    <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/items/wings" onClick={() => ToggleAccordion("items")}>Wings</NavLink></li>
                                    <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/items/pentagrams" onClick={() => ToggleAccordion("items")}>Pentagrams</NavLink></li>
                                    <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/items/errtels" onClick={() => ToggleAccordion("items")}>Errtels</NavLink></li>
                                    <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/items/artifacts" onClick={() => ToggleAccordion("items")}>Artifacts</NavLink></li>
                                    <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/items/rings-pendants" onClick={() => ToggleAccordion("items")}>Rings & Pendants</NavLink></li>
                                    <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/items/muun" onClick={() => ToggleAccordion("items")}>Muun</NavLink></li>
                                    <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/items/orb-scroll" onClick={() => ToggleAccordion("items")}>Orb/Scroll</NavLink></li>
                                    <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/items/jewels" onClick={() => ToggleAccordion("items")}>Jewels</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/tools/mu-roomy-helper" onClick={() => ToggleAccordion("sinhijo")}><i className="bi bi-tools me-2"></i>MU Roomy Helper</NavLink></li>
                <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/tools/maze-of-dimensions-helper-v3" onClick={() => ToggleAccordion("sinhijo")}><i className="bi bi-tools me-2"></i>Maze of Dimensions Helper</NavLink></li>
                <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to="/tools/experience-calculator" onClick={() => ToggleAccordion("sinhijo")}><i className="bi bi-tools me-2"></i>Experience Calculator</NavLink></li>
                <li><NavLink className={({isActive}) => isActive ? 'active' : ''} to='/content-creators' onClick={() => ToggleAccordion("sinhijo")}><i className="bi bi-collection-play me-2"></i>Content Creators</NavLink></li>
            </ul>
        </div>
        <hr className="separador mt-0 mb-0" />
    </div>
  )
}

export default Sidebar;