import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Banners from './components/Banners';
/*import ConsentimientoCookies from './components/ConsentimientoCookies';*/
import ContenidoRutas from './components/ContenidoRutas';
import Footer from './elements/footer/Footer';
import Navbar from './elements/navbar/Navbar';
import Sidebar from './elements/sidebar/Sidebar';
import { AuthProvider } from "./hooks/AuthContext";

function App() {
  const [toggle, setToggle] = useState(window.matchMedia("(min-width: 992px)").matches);
  const Toggle = () => {
    setToggle(!toggle);
  }

  useEffect(() => {
    window
    .matchMedia("(min-width: 992px)")
    .addEventListener('change', e => setToggle( e.matches ));
  }, []);

  const injectGA = () => {
    if (typeof window == 'undefined') {
      return;
    }
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-9VSHK149QD');
  };

  return (
    <>
      <AuthProvider>
        <BrowserRouter>
          <Helmet>
            {/* Global site tag (gtag.js) - Google Analytics */}
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-9VSHK149QD"></script>
            <script>{injectGA()}</script>
          </Helmet>
          {/*<ConsentimientoCookies />*/}
          <div className="container-fluid contenido">
            <div className="row">
              <div className="col-12 p-0"><Navbar Toggle={Toggle}/></div>
              {toggle && <div className="col-sm-4 col-md-1 p-0 sidebar-column"> {/**col-4 col-md-1 p-0 sidebar-column */}
                <Sidebar />
              </div>}
              <div className="col p-2 contenido-column">
                  <div className="container">
                    <div className="contenido-panc">
                      <Banners />
                    </div>
                    <ContenidoRutas />
                  </div>
              </div>
              <div className="col-12 p-0">
                <Footer />
              </div>
            </div>
          </div>
        </BrowserRouter>
      </AuthProvider>
    </>
  );
}

export default App;
